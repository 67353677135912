import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  FormControlLabel,
  Typography,
  Box,
  TablePagination,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { Download } from "lucide-react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

// API URL from environment variables
const apiUrl = process.env.REACT_APP_API_URL;

// Extract user ID from the JWT access token
function getUserIdFromAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) return null;

  try {
    const decodedToken = parseJwt(accessToken);
    return decodedToken.user_id;
  } catch (error) {
    console.error("Failed to decode access token", error);
    return null;
  }
}

// Helper function to decode JWT
function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  return JSON.parse(jsonPayload);
}

const CombinedTablesView = () => {
  const [showScraped, setShowScraped] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: "25px",
    padding: theme.spacing(1.5, 3),
    fontWeight: 600,
    textTransform: "none",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
    },
  }));
  const recruiterId = getUserIdFromAccessToken();

  const handleExportMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };
  const exportToExcel = () => {
    const exportData = data.map((row) => {
      const rowData = {};
      columns.forEach((column) => {
        if (column.id === "score_matching") {
          rowData[column.label] = `${row[column.id]}%`;
        } else if (column.id === "date") {
          rowData[column.label] = formatDate(row[column.id]);
        } else if (column.id !== "cv") {
          // Exclude CV download button
          rowData[column.label] = row[column.id] || "N/A";
        }
      });
      return rowData;
    });
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Applicants");
    XLSX.writeFile(
      wb,
      `${showScraped ? "Scraped" : "Regular"}_Applicants.xlsx`
    );
    handleExportMenuClose();
  };
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns
      .filter((column) => column.id !== "cv") // Exclude CV download button
      .map((column) => column.label);

    const tableRows = data.map((row) => {
      return columns
        .filter((column) => column.id !== "cv")
        .map((column) => {
          if (column.id === "score_matching") {
            return `${row[column.id]}%`;
          } else if (column.id === "date") {
            return formatDate(row[column.id]);
          }
          return row[column.id] || "N/A";
        });
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8, cellPadding: 1 },
      margin: { top: 20 },
    });

    doc.text(`${showScraped ? "Scraped" : "Regular"} Applicants`, 14, 15);
    doc.save(`${showScraped ? "Scraped" : "Regular"}_Applicants.pdf`);
    handleExportMenuClose();
  };
  // const handleDownloadCV = async (reference) => {
  //   if (!reference) {
  //     console.log("Resume path is not available");
  //     return;
  //   }

  //   const normalizedResumePath = reference.replace(/\\/g, "/");
  //   const fileURL = `${apiUrl}media/${normalizedResumePath}`;

  //   try {
  //     const response = await fetch(`${apiUrl}ai/search_resume/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ resume_url: fileURL }),
  //     });

  //     const contentType = response.headers.get("content-type");

  //     if (response.ok && contentType?.includes("application/pdf")) {
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       window.open(url, "_blank");
  //     } else {
  //       console.log("Unexpected response from server");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
const handleResumeClick = async (resume_path) => {
  console.log(resume_path);
  if (!resume_path) {
    console.log("Resume path is not available");
    return;
  }

  // Ensure the resume_path uses forward slashes
  const normalizedResumePath = resume_path.replace(/\\/g, "/");
  const fileURL = `${apiUrl}media/${normalizedResumePath}`;

  try {
    const response = await fetch(`${apiUrl}ai/search_resume/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resume_url: fileURL }),
    });

    const contentType = response.headers.get("content-type");

    if (response.ok) {
      if (contentType && contentType.includes("application/pdf")) {
        // Create a blob from the PDF content
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        // Open the PDF in a new tab
        window.open(url, "_blank");
      } else {
        console.log("Unexpected response from server");
      }
    } else {
      if (contentType && contentType.includes("text/html")) {
        // Handle HTML response (404 page)
        const htmlContent = await response.text();
        // Create a new window and write the HTML content to it
        const newWindow = window.open();
        newWindow.document.write(htmlContent);
        newWindow.document.close();
      } else {
        const data = await response.json();
        console.log(data.message);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  // Helper function to format score
  function formatScore(score) {
    return `${score}%`;
  }

  const regularColumns = [
    { id: "first_name", label: "First Name" },
    { id: "last_name", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "type", label: "Type" },
    // { id: "status", label: "Status" },
    { id: "step", label: "Step" },
    {
      id: "score_matching",
      label: "Score",
      renderCell: (row) => formatScore(row.score_matching),
    },
    {
      id: "date",
      label: "Date",
      renderCell: (row) => formatDate(row.date),
    },
    {
      id: "cv",
      label: "CV",
      renderCell: (row) => (
        <>
          {row.cv !== "N/A" ? (
            <ActionButton onClick={() => handleResumeClick(row.cv)}>
              Download
            </ActionButton>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
  ];

  const scrapedColumns = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "type", label: "Type" },
    {
      id: "linked_in",
      label: "LinkedIn",
      renderCell: (row) => row.linked_in || "N/A",
    },

    // { id: "status", label: "Status" },
    // { id: "step", label: "Step" },
    {
      id: "score_matching",
      label: "Score",
      renderCell: (row) => formatScore(row.score_matching),
    },
    {
      id: "date",
      label: "Date",
      renderCell: (row) => formatDate(row.date),
    },
    {
      id: "cv",
      label: "Resume",
      renderCell: (row) =>
        row.cv !== "N/A" ? (
          <ActionButton onClick={() => handleResumeClick(row.cv)}>
            Download
          </ActionButton>
        ) : (
          "N/A"
        ),
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const endpoint = showScraped
        ? "scraped-applicants"
        : "regular-applicants";

      const response = await axios.get(
        `${apiUrl}candidates/api/${endpoint}/${recruiterId}/?page=${
          page + 1
        }&page_size=${rowsPerPage}`
      );
      setData(response.data.results);
      setTotalCount(response.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (recruiterId) fetchData();
  }, [showScraped, page, rowsPerPage, recruiterId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    console.log("Rows per page changed to:", newRowsPerPage);
  };

  useEffect(() => {
    if (recruiterId) fetchData();
  }, [showScraped, page, rowsPerPage, recruiterId]);

  const handleSwitchChange = () => {
    setShowScraped(!showScraped);
    setPage(0);
  };

  const columns = showScraped ? scrapedColumns : regularColumns;

  return (
    <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" component="div">
          {showScraped ? "Scraped Applicants" : "Regular Applicants"}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <ActionButton
            onClick={exportToExcel}
            startIcon={<Download />}
            variant="outlined"
            color="primary"
          >
            Export to Excel
          </ActionButton>
          <ActionButton
            onClick={exportToPDF}
            startIcon={<Download />}
            variant="outlined"
            color="primary"
          >
            Export to PDF
          </ActionButton>

          <FormControlLabel
            control={
              <Switch
                checked={showScraped}
                onChange={handleSwitchChange}
                color="primary"
              />
            }
            label="Show Scraped Applications"
          />
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-label="applications table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.renderCell
                          ? column.renderCell(row)
                          : row[column.id] ?? "N/A"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </>
      )}
    </Paper>
  );
};

export default CombinedTablesView;
