import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PerfectScrollbar from "perfect-scrollbar";
import { COLORS } from "colors/color";
import useSignalingStore from "../../views/signalingStore";
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
const SidebarContainer = styled.div`
  width: 220px;
  background: #ffffff;
  height: 95vh;
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 20px;
`;

const Logo = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #4a4aff;
`;

const NavContainer = styled.nav`
  padding: 10px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  padding: 12px 20px;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin-bottom: 5px;
  gap: 10px;
  font-size: 15px;
  &:hover {
    background-color: #f5f5ff;
  }

  &.active {
    background-color: #e6f2ff;
    color: #020060;
    font-weight: 600;
    svg {
      fill: #020060;
    }
  }

  svg {
    font-size: 15px;
    fill: #888;
    transition: fill 0.3s ease;
  }

  p {
    margin: 0;
    font-size: 13px;
  }
`;

const NotificationBadge = styled.span`
  background-color: #ff3b30;
  color: ${COLORS.white};
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 10px;
  margin-left: auto;
`;

const LogoutButton = styled.button`
  width: 60%;
  margin: 10px auto;
  padding: 10px;
  background-color: #f0f0ff;
  color: #020060;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;

  svg {
    font-size: 20px;
    fill: #020060;
  }

  &:hover {
    color: #020060;
    background-color: #e6f2ff;
    svg {
      fill: #020060;
    }
  }
`;

function Sidebar({ routes }) {
  const location = useLocation();
  const sidebar = useRef();
  const navigate = useNavigate();
  const [totalNotifications, setTotalNotifications] = useState(0);
  const {
    notificationCounts,
    getTotalNotifications,
    setInitialNotificationCounts,
    updateNotificationCount,
    addMessageNotification,
  } = useSignalingStore();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

  useEffect(() => {
    setTotalNotifications(getTotalNotifications());
  }, [notificationCounts, getTotalNotifications]);

  useEffect(() => {
    fetchUnreadMessageCounts();
    const cleanupSocket = connectToSocket();

    return () => {
      if (cleanupSocket) cleanupSocket();
    };
  }, []);

  const fetchUnreadMessageCounts = async () => {
    try {
      const response = await fetch(
        `${apiUrl}messaging/api/unread-message-counts/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread message counts");
      }
      const data = await response.json();
      setInitialNotificationCounts(data);
    } catch (error) {
      console.error("Error fetching unread message counts:", error);
    }
  };

  const connectToSocket = () => {
    const senderId = getUserIdFromAccessToken();
    if (senderId) {
      const signalingServerUrl = `${apiUrlsoket}ws/chat/${senderId}/0/`;
      const socket = new WebSocket(signalingServerUrl);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "chat_message") {
          console.log("testoo:", data);
          updateNotificationCount(data.sender_id);
          addMessageNotification({
            id: Date.now(),
            message: data.message,
          });
        }
      };

      return () => {
        socket.close();
      };
    }
  };

  const getUserIdFromAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  };

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const handleLogout = () => {
    // Add any logout logic here (e.g., clearing local storage, resetting state)
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  return (
    <SidebarContainer>
      <Logo>
        <img
          src="https://res.cloudinary.com/dwckvxlca/image/upload/v1727797145/smaart1-removebg-preview_ngwypf.png"
          alt="."
          style={{ width: "80%" }}
        />
      </Logo>
      <NavContainer ref={sidebar}>
        {routes.map((route, index) => (
          <NavItem
            key={index}
            to={route.layout + route.path}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <i className={route.icon} />
            <p>{route.name}</p>
            {route.name === "Chat" && totalNotifications > 0 && (
              <NotificationBadge>{totalNotifications}</NotificationBadge>
            )}
          </NavItem>
        ))}
      </NavContainer>
      <LogoutButton onClick={handleLogout}>
      <ExitToAppRoundedIcon />Log out
      </LogoutButton>
    </SidebarContainer>
  );
}

export default Sidebar;
