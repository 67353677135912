import React, { useEffect, useState, useRef } from "react";
import "./login.css";
import {
  FaGoogle,
  FaFacebook,
  FaLinkedin,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa"; // Import Google and Facebook icons
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Toast } from "primereact/toast";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { gapi } from "gapi-script";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import { COLORS } from "../../colors/color";
import GoogleLogin from "react-google-login";
const LoginForDialog = (props) => {
  const [authMode, setAuthMode] = useState("signin");
  // const theme = useTheme();
  const [login, setLogin] = useState("");
  const [role, setRole] = useState("c");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [selectedGender, setSelectedGender] = useState("m");
  const [showPassword, setShowPassword] = useState(false);
  const toast = useRef(null);

  const gender = [{ name: "Male" }, { name: "Female" }];

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setType("candidate");
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (window.location.href.includes("code=")) {
      console.log("The URL contains the code.");
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const mapGenderToValue = (selectedOption) => {
    console.log("option : ", selectedOption);
    switch (selectedOption.name) {
      case "Male":
        return "m";
      case "Female":
        return "f";
      default:
        return null;
    }
  };

  const connexion = async (event) => {
    console.log(login, password);
    try {
      const response = await axios.post(`${apiUrl}authentication/login/`, {
        email: login,
        password: password,
      });
      console.log(response.data);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);

      const userId = getUserIdFromAccessToken();
      if (userId) {
        console.log("User ID:", userId);
        props.setUserId(userId); // Store userId in Redux
      } else {
        console.log("User ID not found or failed to decode token");
      }
      // try {
      //   const response = await axios.get(`${apiUrl}authentication/login/`);

      if (response.data.user.role === "r") {
        redirectToDashboard();

      }
      if (response.data.user.role === "a") {
        redirectToAdminA();
      } else {
        //   redirectToHome();
        window.location.reload();
      }
      setLogin("");
      setPassword("");
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Logged in",
        life: 3000,
      });
    } catch (err) {
      alert("Post Registration Failed!");
    }
  };

  const clientId =
    "1065255482933-3pgtuvdlckvr8s4uo1ecko09gm2blo9s.apps.googleusercontent.com";

  // useEffect(() => {
  //   console.log('clientId is : ',clientId)
  //   function start() {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     },
  //   console.log('initialized')
  //   );
  //   }
  //   // Load Google API client and call start function to initialize
  //   gapi.load("client:auth2", start);
  // },[clientId]);

  useEffect(() => {
    const loadGoogleAPI = () => {
      window.gapi.load("client", () => {
        console.log("Google API Client Loaded");
        // You can now use the gapi object to access Google APIs
      });
    };

    if (!window.gapi) {
      // Handle case where Google API client library failed to load
      console.error("Failed to load Google API client library");
      return;
    }

    loadGoogleAPI();
  }, []);

  const fetchGoogleData = async (res) => {
    try {
      const response = await axios.get(
        `${apiUrl}authentication/google/userinfo/`,
        { withCredentials: true, params: res.profileObj }
      );
      // const response = await fetch(`${apiUrl}authentication/google/userinfo/`, {
      //   headers: {
      //     "Content-type": "application/json",
      //     // Authorization: "Bearer " + localStorage.getItem("accessToken"), // Add a space after 'Bearer'
      //     params : res.profileObj
      //   },
      // });
      console.log("this is the res data  : ", response.data);
      console.log("userRole : ", response.data.user.role);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        console.log("User ID:", userId);
        props.setUserId(userId); // Store userId in Redux
      } else {
        console.log("User ID not found or failed to decode token");
      }
      if (response.data.user.role === "r") {
        redirectToDashboard();

      }
      if (response.data.user.role === "a") {
        redirectToAdminA();
      } else {
        // redirectToHome();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };
  const onSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    fetchGoogleData(res);
  };

  const onFailure = (res) => {
    console.error("Login failed: res:", res);
  };

  const linkedinAuth = async () => {
    console.log("LinkedInAuth");
    try {
      console.log(apiUrl);
      window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86o1ech9y4in4m&redirect_uri=${window.location.origin}/login&state=1234&scope=openid%20email%20profile`;
    } catch (error) {
      console.error("Error redirecting to LinkedIn authentication:", error);
    }
  };

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const params = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: `${apiUrl}login`,
        client_id: "86o1ech9y4in4m",
        client_secret: "WPL_AP0.jW3gypWTWCnun6rz.MTIxMTM2NzA2NA==",
      };
      const response = await axios.get(
        `${apiUrl}authentication/linkedin-callback/`,
        { params: params }
      );
      console.log("params : ", params);
      const result = response.data;
      console.log(result);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        console.log("User ID:", userId);
        props.setUserId(userId); // Store userId in Redux
      } else {
        console.log("User ID not found or failed to decode token");
      }
      // redirectToDashboard();
      if (response.data.user.role === "r") {
        redirectToDashboard();
 
      }
      if (response.data.user.role === "a") {
        redirectToAdminA();
      } else {
        // redirectToHome();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const redirectToDashboard = () => {
    navigate("/talent/dashboard");
  };
  const redirectToHome = () => {
    navigate("/home");
  };
  const redirectToAdminA = () => {
    navigate("/manager/createRecruiter");
  };

  const navigate = useNavigate();

  // const registration = async (event) => {
  //   console.log(name, type, email, password2, role, genderValue, company);
  //   try {
  //     await axios.post(`${apiUrl}authentication/signup/`, {
  //       type: type,
  //       username: name,
  //       email: email,
  //       password: password2,
  //       role: role,
  //       gender: genderValue,
  //       company: company,
  //     });

  //     setName("");
  //     setPassword2("");
  //     setEmail("");
  //     setCompany("");
  //     setSelectedGender("Male");
  //     toast.current.show({
  //       severity: "secondary",
  //       summary: "Secondary",
  //       detail: "User created",
  //       life: 3000,
  //     });
  //   } catch (err) {
  //     console.error("Registration failed:", err);
  //     alert("post Registration Failed");
  //   }
  // };

  const genderValue = mapGenderToValue(selectedGender);
  const StyledBox = styled(Box)({
    maxWidth: "400px",
    margin: "auto",
    padding: "32px",
    borderRadius: "8px",
    boxShadow: "0 8px 40px rgba(0,0,0,0.12)",
    backgroundColor: "#ffffff",
  });

  const StyledButton = styled(Button)({
    borderRadius: "50px",
    padding: "12px 24px",
    textTransform: "none",
    fontWeight: 600,
    backgroundColor: COLORS.white,
    color: COLORS.signIn,
    border: `1px solid ${COLORS.signIn}`,
    "&:hover": {
    backgroundColor: COLORS.Button_OnHold,
    color: COLORS.signIn,
    border: `1px solid ${COLORS.signIn}`,
  },
  });
  return (
    <StyledBox>
      <Toast ref={toast} />
      <Typography variant="h4" align="center" gutterBottom>
        {authMode === "signin" ? "Welcome Back" : "Create Account"}
      </Typography>
      <Typography
        variant="body2"
        align="center"
        color="textSecondary"
        paragraph
      >
        {authMode === "signin"
          ? "Sign in to continue"
          : "Sign up to get started"}
      </Typography>

      <Box component="form" noValidate sx={{ mt: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email or Username"
          id="login"
          value={login}
          onChange={(event) => setLogin(event.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          variant="outlined"
          type={showPassword ? "text" : "password"}
          label="Password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </IconButton>
            ),
          }}
        />

        <StyledButton
          fullWidth
          variant="contained"
          onClick={connexion}
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: COLORS.signIn,
            color: COLORS.white,
          }}
        >
          {authMode === "signin" ? "Sign In" : "Sign Up"}
        </StyledButton>

        <Divider sx={{ my: 3 }}>Or continue with</Divider>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <GoogleLogin
            clientId={clientId}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            isSignedIn={false}
            render={({ onClick }) => (
              <StyledButton
                variant="outlined"
                onClick={onClick}
                startIcon={<FaGoogle />}
                sx={{ width: "48%" }}
              >
                Google
              </StyledButton>
            )}
          />
          <StyledButton
            variant="outlined"
            onClick={linkedinAuth}
            startIcon={<FaLinkedin />}
            sx={{ width: "48%" }}
          >
            LinkedIn
          </StyledButton>
        </Box>
      </Box>

      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        {authMode === "signin"
          ? "Don't have an account? "
          : "Already have an account? "}
        <Typography
          component="span"
          color="primary"
          sx={{ cursor: "pointer", fontWeight: 600 }}
          onClick={() =>
            setAuthMode(authMode === "signin" ? "signup" : "signin")
          }
        >
          {authMode === "signin" ? "Sign Up" : "Sign In"}
        </Typography>
      </Typography>
    </StyledBox>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForDialog);
