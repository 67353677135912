import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Avatar,
  IconButton,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import { COLORS } from "colors/color";

const PageContainer = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(3),
  // backgroundColor: "#f5f5f0",
  minHeight: "100vh",
}));

const WelcomeSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 4px 11px rgba(0.1, 0.1, 0.1, 0.2)",
}));

const ProfileSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}));

const CompanyInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3),
}));

const CompanyLogoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const CompanyLogo = styled(Box)(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: "50%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "2px solid #fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}));

const LogoAvatar = styled(Avatar)(({ theme }) => ({
  width: "100%",
  height: "100%",
  fontSize: "16px",
  fontWeight: "bold",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: "12px",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const UpdateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#020060",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#303f9f",
    color: "#fff",
  },
}));

function User({}) {
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    image: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const userId = getUserIdFromAccessToken();

  const apiUrl = process.env.REACT_APP_API_URL;
  const preset_key = "tpjsjr7k";
  const cloud_name = "dwckvxlca";

  useEffect(() => {
    loadRecruiter();
  }, []);

  async function loadRecruiter() {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}recruiters/${userId}/find/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page ...");
        navigate("/login");
        return;
      }

      const json = await response.json();
      setFormData(json);
      setLoading(false);
    } catch (error) {
      console.error("Error loading recruiter:", error);
      setError("Failed to fetch recruiter data");
      setLoading(false);
    }
  }

  function handleImageClick() {
    fileInputRef.current.click();
  }

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  function handleFile(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const imageUrl = res.data.secure_url;
        setFormData((prev) => ({ ...prev, image: imageUrl }));
      })
      .catch((err) => console.log(err));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const updatableFields = {
        company: formData.company,
        first_name: formData.first_name,
        last_name: formData.last_name,
        address: formData.address,
        city: formData.city,
        country: formData.country,
        image: formData.image,
        postal_code: formData.postal_code,
        email: formData.email,
        comp_description: formData.comp_description,
        comp_industry: formData.comp_industry
      };
  
      await axios.patch(`${apiUrl}recruiters/${userId}/update/`, updatableFields, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setShowSnackbar(true);
      setIsEditing(false);
      setLoading(false);
    } catch (error) {
      console.error("Error updating recruiter:", error);
      setError("Failed to update profile");
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <PageContainer>
      <WelcomeSection>
        <Typography
          sx={{
            color: "#020060",
            fontWeight: "bold",
            fontSize: "17px",
            opacity: "80%",
          }}
        >
          Welcome back {formData.first_name}!
        </Typography>
        <Avatar
          sx={{ bgcolor: "#e0e0e0", color: "#020060", fontWeight: "bold" }}
        >
          {formData.first_name.charAt(0).toUpperCase()}
        </Avatar>
      </WelcomeSection>
      <Typography
        variant="h4"
        sx={{ color: "#020060", fontWeight: "bold", marginBottom: "2%" }}
      >
        User profile
      </Typography>
      <ProfileSection>
        <CompanyInfo>
          <CompanyLogoContainer>
            <CompanyLogo>
              <LogoAvatar src={formData.image} alt={formData.first_name} />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFile}
              />
              <IconButton
                onClick={handleImageClick}
                disabled={!isEditing}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                  {isEditing && <CameraAltIcon />}
              </IconButton>
            </CompanyLogo>
          </CompanyLogoContainer>
          <Button
            sx={{
              color: "grey",
              opacity: "80%",
              textTransform: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            endIcon={<DriveFileRenameOutlineOutlinedIcon />}
            onClick={handleEdit}
            disabled={isEditing}
          >
            Edit
          </Button>
        </CompanyInfo>
        <Grid container spacing={3}>
          {Object.entries(formData)
            .filter(([key]) => key !== "id" && key !== "steps" && key !== "password"&& key !== "last_login"&& key !== "is_superuser" && key !== "is_staff"&& key !== "is_active"&& key !== "date_joined"&& key !== "role"&& key !== "in_call"&& key !== "user_permissions"&& key !== "groups"&& key !== "created_by"&& key !== "gender")
            .map(
              ([key, value]) =>
                key !== "image" && (
                  <Grid item xs={12} sm={5} key={key}>
                    <Typography
                      sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
                    >
                      {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                        key.replace(/_/g, " ").slice(1)}
                    </Typography>
                    <StyledTextField
                      fullWidth
                      name={key}
                      value={value}
                      onChange={handleChange}
                      variant="outlined"
                      disabled={!isEditing}
                    />
                  </Grid>
                )
            )}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <UpdateButton
            variant="contained"
            onClick={handleSave}
            disabled={!isEditing || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update profile"}
          </UpdateButton>
        </Box>
      </ProfileSection>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message="Profile updated successfully!"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0,0.5)",
          },
        }}
      />
    </PageContainer>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
