import React, { useEffect, useState } from "react";
import SearchBar from "../client/SearchBar";
import MyCard from "../client/Card";
import "../client/home.css";
import MyCardDetails from "../client/CardDetails";
import ButtonAppBar from "../../components/Navbars/navBar";
import LoginForDialog from "views/Auth/LoginForDialog";
import SignInForDialog from "views/Auth/SignInForDialog";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Snackbar,
  Button,
  Alert,
  TextField,
  InputAdornment,
  Typography,
  Container,
} from "@mui/material";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import Footer from "../client/Footer";
import App from "views/Chat/Chat";
import NoSavedPosts from "./NoSavedPosts";
import styled from "styled-components";
import { SearchIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
const SearchButton = styled(Button)(({ theme }) => ({
  borderRadius: "100px",
  height: "48px",
  padding: "5% 15%",
  marginLeft: 1,
  marginRight: 5.5,
  backgroundColor: "#0085FF",
  fontSize: "13px",
  color: "white",
  whiteSpace: "nowrap",
  "&:hover": {
    boxShadow: "0 4px 12px rgba(0, 102, 255, 0.3)",
  },
}));
const FullScreenContainer = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const LoadingContainer = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "20px",
});
const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 10,
  backgroundColor: "white",
  borderRadius: "50px",
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.08)",
  width: "100%",
  zIndex: 100,
  overflow: "hidden",
  fontFamily: "Poppins",
  // marginBottom: "2%",
  marginTop: "9%",
  // marginLeft: "-3%",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontFamily: "Poppins",
  textTransform: "none",
  fontSize: "1rem",
  width: "80%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "50px",
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));
function SavedPosts() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [saved, setSaved] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [ChatComponent, setChatComponent] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const [page, setPage] = useState(1);
  const [isShrinked, setIsShrinked] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [jobToUnsave, setJobToUnsave] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "info",
    action: null,
  });
  const handleSearch = () => {
    if (searchTerm === "") {
      setFilteredJobs(allJobs);
    } else {
      const filtered = allJobs.filter((job) =>
        job.post.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredJobs(filtered);
    }
  };
  const handleUnsavePost = async (postId) => {
    setSnackbarState({
      open: true,
      message: "Do you want to unsave this post?",
      severity: "info",
      // action: (
      //   <>
      //     <Button
      //       color="primary"
      //       size="small"
      //       onClick={() => confirmUnsave(postId)}
      //     >
      //       Yes
      //     </Button>
      //     <Button
      //       color="secondary"
      //       size="small"
      //       onClick={() => setSnackbarState({ ...snackbarState, open: false })}
      //     >
      //       No
      //     </Button>
      //   </>
      // )
    });
  };
  // const confirmUnsave = async (postId) => {
  //   try {
  //     const userId = getUserIdFromAccessToken();
  //     const response = await fetch(
  //       `${apiUrl}candidates/list_saved_posts/${userId}/`,
  //       {
  //         method: 'DELETE',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ postId })
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error('Failed to unsave post');
  //     }

  //     // Immediately update UI
  //     setAllJobs(prevJobs => prevJobs.filter(job => job.post.id !== postId));

  //     // If the unsaved post was selected, select the first remaining post or null
  //     setSelectedJob(currentSelected => {
  //       if (currentSelected?.post.id === postId) {
  //         const remainingJobs = allJobs.filter(job => job.post.id !== postId);
  //         return remainingJobs.length > 0 ? remainingJobs[0] : null;
  //       }
  //       return currentSelected;
  //     });

  //     // Show success message
  //     setSnackbarState({
  //       open: true,
  //       message: 'Post unsaved successfully',
  //       severity: 'success',
  //       action: null
  //     });

  //     // Auto-hide success message after 2 seconds
  //     setTimeout(() => {
  //       setSnackbarState(prev => ({ ...prev, open: false }));
  //     }, 2000);

  //   } catch (error) {
  //     console.error('Error unsaving post:', error);
  //     setSnackbarState({
  //       open: true,
  //       message: 'Failed to unsave post. Please try again.',
  //       severity: 'error',
  //       action: (
  //         <Button
  //           color="secondary"
  //           size="small"
  //           onClick={() => setSnackbarState(prev => ({ ...prev, open: false }))}
  //         >
  //           Close
  //         </Button>
  //       )
  //     });
  //   }
  // };
  const Load = async () => {
    setIsLoading(true);
    try {
      const userId = getUserIdFromAccessToken();
      const response = await fetch(
        `${apiUrl}candidates/list_saved_posts/${userId}/`
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.error("post_saved not found");
          setAllJobs([]);
          setSelectedJob(null);
          return { error: "post_saved not found" };
        }
        throw new Error(`An error occurred: ${response.statusText}`);
      }

      const json = await response.json();
      setAllJobs(json);
      setSaved(true);
      setSelectedJob(json.length > 0 ? json[0] : null);
      return json;
    } catch (error) {
      console.error("Error loading saved posts:", error);
      setAllJobs([]);
      setSelectedJob(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Load();
  }, []);

  useEffect(() => {
    console.log("posts data :", allJobs);
  }, [allJobs]);

  useEffect(() => {
    setIsAuth(!!localStorage.getItem("accessToken"));
  }, []);

  const handleCardClick = (job) => {
    setSelectedJob(job);
  };

  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  const closeLoginPopUp = () => {
    setOpenLoginDialog(false);
  };

  const closeSignInPopUp = () => {
    setOpenSignInDialog(false);
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  // const handleUnsave = (job) => {
  //   setJobToUnsave(job);
  //   setShowSnackbar(true);
  // };

  // const confirmUnsave = async () => {
  //   if (jobToUnsave) {
  //     // Logic to remove job from saved posts
  //     const userId = getUserIdFromAccessToken();
  //     await fetch(`${apiUrl}candidates/list_saved_posts/${userId}/`, {
  //       method: 'DELETE'
  //     });
  //     setAllJobs((prevJobs) => prevJobs.filter((job) => job !== jobToUnsave));
  //     setJobToUnsave(null);
  //   }
  //   setShowSnackbar(false);
  // };

  // const cancelUnsave = () => {
  //   setShowSnackbar(false);
  // };

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector("footer");
      if (footer) {
        const rect = footer.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsFooterVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderMainContent = () => {
    if (isLoading) {
      return (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
            hideWhileScrolling={90}
          />
          <LoadingContainer>
            <CircularProgress size={60} thickness={4} />
          </LoadingContainer>
        </>
      );
    }

    if (ChatComponent) {
      return (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <App candidate_id={getUserIdFromAccessToken()} />
        </>
      );
    }

    if (allJobs.length === 0) {
      return (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
            hideWhileScrolling={90}
          />
          <FullScreenContainer>
            <NoSavedPosts />
          </FullScreenContainer>
        </>
      );
    }

    return (
      <>
        <ButtonAppBar
          isAuth={isAuth}
          onLoginClick={handleLoginClick}
          onSignInClick={handleSignInClick}
          setChatComponent={setChatComponent}
          hideWhileScrolling={90}
        />
        {/* <Container sx={{ paddingTop: "9%", marginLeft: "11%" }}> */}
        <div className="container" style={{ paddingTop: "9%" }}>
          <div className="row justify-content-center home">
            <div className="col-lg-5 col-md-4 col-12 myCard">
              <div className="mycard-scroll">
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    background: "#004F98",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Saved posts
                </Typography>
                {/* <div className="row justify-content-center ">
            <div className="col-lg-5 col-md-4 col-12 "> */}
                <SearchBox
                  sx={{
                    display: "flex",
                    borderRadius: "50px",
                    // border: "1px solid #fff",
                    boxShadow: "0px 0px 40px 0 #0000001A",
                    // marginBottom: "2%",
                    borderColor: "transparent",
                  }}
                >
                  <StyledTextField
                    placeholder="Search by keywords, job title..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start" sx={{ ml: 2 }}>
                    //       <SearchIcon color="action" />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  ></StyledTextField>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSearch}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      borderRadius: "100px",
                      margin: 0.5,
                      paddingX: 4,
                      backgroundColor: "#0085FF",
                    }}
                  >
                    Search
                  </Button>
                </SearchBox>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-10"></div>
          </div>
        </div>
        <div className="container" style={{marginTop:'-4%'}}>
          <div className="row justify-content-center home">
            <div className="col-lg-5 col-md-4 col-12 myCard">
              <div className="mycard-scroll">
                {filteredJobs.length > 0
                  ? filteredJobs.map((job, index) => (
                      <div key={index} onClick={() => handleCardClick(job)}>
                        <MyCard
                          title={job.post.title}
                          deadline={job.post.deadline}
                          description={job.post.description}
                          image={job.post.image}
                          post_id={job.post.id}
                          savedBlack={saved}
                          recruiter={job.post.recruiter}
                        />
                      </div>
                    ))
                  : allJobs.map((job, index) => (
                      <div key={index} onClick={() => handleCardClick(job)}>
                        <MyCard
                          title={job.post.title}
                          deadline={job.post.deadline}
                          description={job.post.description}
                          image={job.post.image}
                          post_id={job.post.id}
                          savedBlack={saved}
                          recruiter={job.post.recruiter}
                        />
                      </div>
                    ))}
              </div>
            </div>

            {selectedJob && (
              <div className="col-lg-6 col-md-8 col-10">
                <div className="mycard-scroll fixed-component">
                  <MyCardDetails
                    title={selectedJob.post?.title || "N/A"}
                    description={
                      selectedJob.post?.description ||
                      "Description not available"
                    }
                    requirements={
                      <span style={{ color: "black" }}>
                        {selectedJob.post?.requirements?.join(", ") ||
                          "Requirements not available"}
                      </span>
                    }
                    localisation={
                      selectedJob.post?.localisation || "Location not specified"
                    }
                    mode={selectedJob.post?.mode || "Mode not specified"}
                    deadline={
                      selectedJob.post?.deadline || "Deadline not specified"
                    }
                    image={selectedJob.post?.image || ""}
                    id={selectedJob.post?.id}
                    isFooterVisible={isFooterVisible}
                    company={
                      selectedJob.post?.recruiter?.company ||
                      "Company not specified"
                    }
                    company_logo={selectedJob.post?.company_logo || ""}
                    setIsShrinked={setIsShrinked}
                    refresh={Load}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderMainContent()}
      <Footer />

      <Dialog open={openLoginDialog} onClose={closeLoginPopUp} maxWidth="sm">
        <DialogContent>
          <LoginForDialog />
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog open={openSignInDialog} onClose={closeSignInPopUp} maxWidth="sm">
        <DialogContent>
          <SignInForDialog />
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedPosts);
