import React from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  Paper,
  Alert,
  AlertTitle
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { COLORS } from '../../colors/color';

const AlreadyApplied = ({ jobTitle, company, applicationDate, onBackToList }) => {
  return (
    <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 4
        }}
      >
        <InfoIcon 
          sx={{ 
            fontSize: 64, 
            color: COLORS.signIn 
          }} 
        />
        
        <Paper 
          elevation={3} 
          sx={{ 
            width: '100%', 
            p: 4, 
            borderRadius: 2 
          }}
        >
          <Alert 
            severity="info" 
            sx={{ 
              mb: 3,
              '& .MuiAlert-icon': {
                fontSize: '2rem'
              }
            }}
          >
            <AlertTitle sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
              You've already applied to this post
            </AlertTitle>
          </Alert>

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have an existing application for:
            </Typography>
            
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 'bold', 
                mb: 1,
                color: COLORS.signIn
              }}
            >
              {jobTitle}
            </Typography>
            
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 3,
                color: 'text.secondary'
              }}
            >
              at {company}
            </Typography>
            
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                fontStyle: 'italic'
              }}
            >
              Application submitted on: {applicationDate}
            </Typography>
          </Box>
        </Paper>

        <Button 
          onClick={onBackToList}
          variant="contained"
          sx={{
            mt: 2,
            px: 4,
            py: 1.5,
            backgroundColor: COLORS.signIn,
            '&:hover': {
              backgroundColor: COLORS.Button_OnHold,
              color: COLORS.signIn,
            }
          }}
        >
          Back to Job Listings
        </Button>
      </Box>
    </Container>
  );
};

export default AlreadyApplied;