// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   Paper,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   LinearProgress,
//   Alert,
//   Grow,
//   Fade,
//   Select,
//   MenuItem,
//   TextField,
//   Divider,
// } from "@mui/material";
// import {
//   CloudUpload as UploadIcon,
//   Delete as DeleteIcon,
//   PictureAsPdf as PdfIcon,
//   CloudUpload as CloudUploadIcon,
//   DataObject as DataIcon,
// } from "@mui/icons-material";
// import { styled } from "@mui/material/styles";

// import { Tooltip } from "@mui/material";
// import {
//   CheckCircle as CheckCircleIcon,
//   ContentCopy as ContentCopyIcon,
// } from "@mui/icons-material";

// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const UploadBox = styled(Paper)(({ theme }) => ({
//   border: `2px dashed ${theme.palette.primary.main}`,
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(4),
//   textAlign: "center",
//   cursor: "pointer",
//   transition: "all 0.3s ease-in-out",
//   "&:hover": {
//     backgroundColor: theme.palette.action.hover,
//     transform: "scale(1.02)",
//   },
// }));

// const AnimatedDataIcon = styled(DataIcon)(({ theme }) => ({
//   fontSize: 60,
//   color: theme.palette.primary.main,
//   animation: "$float 3s ease-in-out infinite",
//   "@keyframes float": {
//     "0%": {
//       transform: "translateY(0px)",
//     },
//     "50%": {
//       transform: "translateY(-20px)",
//     },
//     "100%": {
//       transform: "translateY(0px)",
//     },
//   },
// }));

// export default function DataImport() {
//   const [files, setFiles] = useState([]);
//   const [error, setError] = useState("");
//   const [dragging, setDragging] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategories, setSelectedCategories] = useState([]);
//   const [newCategory, setNewCategory] = useState("");
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const [showDataScrapingAnimation, setShowDataScrapingAnimation] =
//     useState(false);
//   const [email, setEmail] = useState("");

//  const [code, setCode] = useState("");
//  const [copied, setCopied] = useState(false);
//  const [snackbarOpen, setSnackbarOpen] = useState(false);

//  const code_outlook = async () => {
//    try {
//      const response = await fetch(
//        `${apiUrl}ai/code_outlook/${getUserIdFromAccessToken()}/`
//      );
//      const result = await response.json();
//      console.log("code : ", result.user_code);
//      setCode(result.user_code);
//    } catch (error) {
//      console.error("Error fetching data:", error);
//    }
//  };

//  const handleCopy = () => {
//    if (navigator.clipboard && navigator.clipboard.writeText) {
//      navigator.clipboard
//        .writeText(code)
//        .then(() => {
//          setCopied(true);
//          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
//        })
//        .catch(() => {
//          setSnackbarOpen(true);
//        });
//    } else {
//      setSnackbarOpen(true);
//    }
//  };

//  useEffect(() => {
//    fetchCategories();
//    code_outlook(); // Fetch the code when component mounts
//  }, []);

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

// const getUserIdFromAccessToken = () => {
//   const accessToken = localStorage.getItem("accessToken");
//   if (!accessToken) {
//     console.error("No access token found in local storage");
//     return null;
//   }

//   try {
//     const decodedToken = parseJwt(accessToken);
//     console.log("Access token:", decodedToken);
//     return decodedToken.user_id;
//   } catch (error) {
//     console.error("Failed to decode access token", error);
//     return null;
//   }
// };

// const parseJwt = (token) => {
//   const base64Url = token.split(".")[1];
//   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   const jsonPayload = decodeURIComponent(
//     atob(base64)
//       .split("")
//       .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
//       .join("")
//   );
//   console.log("payload:", jsonPayload);

//   return JSON.parse(jsonPayload);
// };

// const handleStartScraping = async () => {
//   console.log("Starting scraping for email:", email);
//   const recruiterId = getUserIdFromAccessToken();
//   console.log(recruiterId);
//   if (!recruiterId) {
//     console.error("Failed to get recruiter ID");
//     return;
//   }

//   try {
//     const response = await fetch(
//       `${apiUrl}ai/start_scraping2/${recruiterId}/`,
//       {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email }),
//       }
//     );

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();
//     console.log("Scraping started successfully:", data);
//     // Handle successful response (e.g., show a success message to the user)
//   } catch (error) {
//     console.error("Error starting scraping:", error);
//     // Handle error (e.g., show an error message to the user)
//   }
// };

//   const fetchCategories = async () => {
//     try {
//       const response = await fetch(`${apiUrl}recruiters/categories/`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         },
//       });
//       if (!response.ok) throw new Error("Failed to fetch categories");
//       const data = await response.json();
//       setCategories(data);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//       setError("Failed to fetch categories. Please try again.");
//     }
//   };
//   const handleCategoryChange = (event) => {
//     const selectedValues = event.target.value;
//     setSelectedCategories(selectedValues);
//   };

//   const handleAddCategory = async () => {
//     if (!newCategory.trim()) return;
//     try {
//       const response = await fetch(`${apiUrl}recruiters/categories/create/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         },
//         body: JSON.stringify({ name: newCategory.trim().toLowerCase() }),
//       });
//       if (!response.ok) throw new Error("Failed to add category");
//       const addedCategory = await response.json();
//       setCategories([...categories, addedCategory]);
//       setNewCategory("");
//     } catch (error) {
//       console.error("Error adding category:", error);
//       setError("Failed to add category. Please try again.");
//     }
//   };

//   const handleFileDrop = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDragging(false);

//     const selectedFiles = Array.from(event.dataTransfer.files);
//     processFiles(selectedFiles);
//   };

//   const handleFileUpload = (event) => {
//     const selectedFiles = Array.from(event.target.files);
//     processFiles(selectedFiles);
//   };

//   const processFiles = (selectedFiles) => {
//     let newFiles = [];
//     let errors = [];

//     selectedFiles.forEach((file) => {
//       if (file.type === "application/pdf") {
//         const isDuplicate = files.some(
//           (existingFile) => existingFile.name === file.name
//         );

//         if (!isDuplicate) {
//           newFiles.push({
//             id: Date.now() + Math.random(),
//             name: file.name,
//             type: file.type,
//             size: (file.size / 1024).toFixed(2) + " KB",
//             uploadDate: new Date().toLocaleDateString(),
//             fileObject: file,
//           });
//         } else {
//           errors.push(`${file.name} (Duplicate)`);
//         }
//       } else {
//         errors.push(file.name);
//       }
//     });

//     if (errors.length > 0) {
//       setError(
//         `Some files were not uploaded. Only PDF files are allowed. The following were not uploaded: ${errors.join(
//           ", "
//         )}`
//       );
//     } else {
//       setError("");
//     }

//     setFiles([...files, ...newFiles]);
//   };
//   const uploadFiles = async () => {
//     if (files.length === 0) return;
//     if (selectedCategories.length === 0) {
//       setError("Please select a category");
//       return;
//     }

//     setIsUploading(true);
//     setUploadProgress(0);

//     try {
//       const formData = new FormData();
//       files.forEach((file) => {
//         formData.append("files[]", file.fileObject);
//       });

//       const categoryId = selectedCategories[0];
//       const selectedCategory = categories.find((cat) => cat.id === categoryId);

//       if (!selectedCategory) {
//         throw new Error("Selected category not found");
//       }

//       // Append the single category
//       formData.append("category", selectedCategory.name);

//       const response = await fetch(
//         `${apiUrl}recruiters/cvupload/${getUserIdFromAccessToken()}/`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || "Upload failed");
//       }

//       const data = await response.json();
//       console.log("Upload successful:", data);

//       // Simulate upload progress (optional)
//       for (let i = 0; i <= 100; i++) {
//         await new Promise((resolve) => setTimeout(resolve, 20));
//         setUploadProgress(i);
//       }

//       // Clear files and reset error on success
//       setFiles([]);
//       setError("");
//       setSelectedCategories([]); // Clear selected category

//       // Show success animation
//       setShowSuccessAnimation(true);
//       setTimeout(() => {
//         setShowSuccessAnimation(false);

//         setShowDataScrapingAnimation(true);
//         setTimeout(() => {
//           setShowDataScrapingAnimation(false);
//         }, 5000); // data scraping animation duration
//       }, 2000); // success animation duration
//     } catch (error) {
//       console.error("Error uploading files:", error);
//       setError(error.message || "Failed to upload files. Please try again.");
//     } finally {
//       setIsUploading(false);
//       setUploadProgress(0);
//     }
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const removeFile = (id) => {
//     setFiles(files.filter((file) => file.id !== id));
//   };

//   const removeAllFiles = () => {
//     setFiles([]);
//     setError("");
//   };

//   return (
//     <Box sx={{ display: "flex", maxWidth: "100%", mx: "auto", p: 3, mt: 4 }}>
//       <Box sx={{ width: "40%", pt: 10, pr: 3, pb: 1, pl: 4 }} align="center">
//         <Typography variant="h4" gutterBottom sx={{ mb: 3 }} align="center">
//           Email Scraping
//         </Typography>
//         <TextField
//           fullWidth
//           label="Enter Email"
//           variant="outlined"
//           value={email}
//           onChange={handleEmailChange}
//           sx={{ mt: 5 }}
//         />
//         <Button
//           fullWidth
//           variant="contained"
//           color="primary"
//           sx={{ mt: 3 }}
//           onClick={handleStartScraping}
//         >
//           Start Scraping
//         </Button>
//       </Box>
//       <Divider
//         orientation="vertical"
//         flexItem
//         sx={{ marginLeft: 7, borderColor: "black", marginTop: 15 }}
//       />

//       <Box sx={{ width: "60%", p: 10 }}>
//         <Typography variant="h4" gutterBottom sx={{ mb: 3 }} align="center">
//           Data Import
//         </Typography>
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             Generated Code
//             <Tooltip title={copied ? "Copied!" : "Copy code"}>
//               <IconButton onClick={handleCopy} size="small" sx={{ ml: 2 }}>
//                 {copied ? (
//                   <CheckCircleIcon color="success" />
//                 ) : (
//                   <ContentCopyIcon />
//                 )}
//               </IconButton>
//             </Tooltip>
//           </Typography>
//           <Paper
//             elevation={3}
//             sx={{ p: 2, maxHeight: "300px", overflow: "auto" }}
//           >
//             <pre>{code}</pre>
//           </Paper>
//         </Box>
//         <Box sx={{ mt: 3 }}>
//           <Typography variant="h6" gutterBottom>
//             Select Categories
//           </Typography>
//           <Select
//             multiple
//             value={selectedCategories}
//             onChange={handleCategoryChange}
//             renderValue={(selected) =>
//               selected
//                 .map((id) => categories.find((cat) => cat.id === id)?.name)
//                 .filter(Boolean)
//                 .join(", ")
//             }
//             sx={{ width: "100%", mb: 2 }}
//           >
//             {categories.map((category) => (
//               <MenuItem key={category.id} value={category.id}>
//                 {category.name}
//               </MenuItem>
//             ))}
//           </Select>
//           <Box sx={{ display: "flex", alignItems: "center" }}>
//             <TextField
//               value={newCategory}
//               onChange={(e) => setNewCategory(e.target.value)}
//               placeholder="New category"
//               sx={{ flexGrow: 1, mr: 2 }}
//             />
//             <Button variant="contained" onClick={handleAddCategory}>
//               Add Category
//             </Button>
//           </Box>
//         </Box>

//         <Grow in={true}>
//           <UploadBox
//             elevation={3}
//             onDragOver={handleDragOver}
//             onDragLeave={handleDragLeave}
//             onDrop={handleFileDrop}
//             sx={{
//               borderColor: dragging ? "primary.main" : "inherit",
//               borderWidth: dragging ? "3px" : "2px",
//               mt: 4,
//               mb: 4,
//             }}
//           >
//             <label htmlFor="file-upload">
//               <UploadIcon sx={{ fontSize: 60, color: "primary.main", mb: 2 }} />
//               <Typography variant="h5" gutterBottom>
//                 Click to upload or drag and drop multiple files
//               </Typography>
//               <Typography variant="body1" color="textSecondary">
//                 PDF files only (MAX. 10MB each)
//               </Typography>
//               <VisuallyHiddenInput
//                 id="file-upload"
//                 type="file"
//                 accept=".pdf"
//                 multiple
//                 onChange={handleFileUpload}
//               />
//             </label>
//           </UploadBox>
//         </Grow>

//         {error && (
//           <Alert severity="error" sx={{ mt: 2 }}>
//             {error}
//           </Alert>
//         )}
//         {showSuccessAnimation && (
//           <Fade in={showSuccessAnimation} timeout={600}>
//             <Alert severity="success" sx={{ mt: 2 }}>
//               Files uploaded successfully!
//             </Alert>
//           </Fade>
//         )}
//         {showDataScrapingAnimation && (
//           <Fade in={showDataScrapingAnimation} timeout={1000}>
//             <Box sx={{ textAlign: "center", mt: 4 }}>
//               <AnimatedDataIcon />
//               <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
//                 Data Scraping in Progress...
//               </Typography>
//             </Box>
//           </Fade>
//         )}

//         {files.length > 0 && (
//           <Paper sx={{ mt: 0 }} elevation={3}>
//             {isUploading && (
//               <Box sx={{ width: "100%", mt: 3 }}>
//                 <LinearProgress variant="determinate" value={uploadProgress} />
//               </Box>
//             )}
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 p: 2,
//               }}
//             >
//               <Typography variant="h6">
//                 Uploaded Files ({files.length})
//               </Typography>
//               <Box>
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   startIcon={<DeleteIcon />}
//                   onClick={removeAllFiles}
//                   sx={{ mr: 2 }}
//                 >
//                   Remove All
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   startIcon={<CloudUploadIcon />}
//                   onClick={uploadFiles}
//                   disabled={isUploading || files.length === 0}
//                 >
//                   {isUploading ? "Uploading..." : "Upload Files"}
//                 </Button>
//               </Box>
//             </Box>

//             <TableContainer sx={{ mt: 1 }}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>File Name</TableCell>
//                     <TableCell>Type</TableCell>
//                     <TableCell>Size</TableCell>
//                     <TableCell>Upload Date</TableCell>
//                     <TableCell align="right">Action</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {files.map((file) => (
//                     <TableRow key={file.id}>
//                       <TableCell>
//                         <Box sx={{ display: "flex", alignItems: "center" }}>
//                           <PdfIcon sx={{ mr: 1, color: "error.main" }} />
//                           {file.name}
//                         </Box>
//                       </TableCell>
//                       <TableCell>{file.type}</TableCell>
//                       <TableCell>{file.size}</TableCell>
//                       <TableCell>{file.uploadDate}</TableCell>
//                       <TableCell align="right">
//                         <IconButton
//                           color="error"
//                           onClick={() => removeFile(file.id)}
//                           size="small"
//                         >
//                           <DeleteIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Paper>
//         )}
//       </Box>
//     </Box>
//   );
// }
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  LinearProgress,
  Alert,
  Grow,
  Fade,
  Select,
  MenuItem,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
  PictureAsPdf as PdfIcon,
  CloudUpload as CloudUploadIcon,
  DataObject as DataIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { Tooltip } from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadBox = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    transform: "scale(1.02)",
  },
}));

const AnimatedDataIcon = styled(DataIcon)(({ theme }) => ({
  fontSize: 60,
  color: theme.palette.primary.main,
  animation: "$float 3s ease-in-out infinite",
  "@keyframes float": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-20px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
}));

export default function DataImport() {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [dragging, setDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showDataScrapingAnimation, setShowDataScrapingAnimation] =
    useState(false);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [copied, setCopied] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [isScrapingStarted, setIsScrapingStarted] = useState(false);

  const getUserIdFromAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token:", decodedToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  };

  const parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    console.log("payload:", jsonPayload);

    return JSON.parse(jsonPayload);
  };

  const handleStartScraping = async () => {
    console.log("Starting scraping for email:", email);
    const recruiterId = getUserIdFromAccessToken();
    console.log("Recruiter ID:", recruiterId);
    if (!recruiterId) {
      console.error("Failed to get recruiter ID");
      return;
    }

    setIsCodeLoading(true);

    try {
      const response = await fetch(
        `${apiUrl}ai/start_scraping2/${recruiterId}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Scraping API response:", data);
    } catch (error) {
      console.error("Error starting scraping:", error);
      setIsCodeLoading(false);
      // Handle error (e.g., show an error message to the user)
    }
  };
  function openAuthPopup() {
    const authUrl =
      "https://login.microsoftonline.com/common/oauth2/deviceauth";
    const width = window.screen.width / 3;
    const height = window.screen.height;
    const left = window.screen.width - width;
    const top = 0;

    window.open(
      authUrl,
      "authPopup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }
  const code_outlook = async () => {
    console.log("start to fetch code:");
    try {
      const response = await fetch(
        `${apiUrl}ai/code_outlook/${getUserIdFromAccessToken()}/`
      );
      const result = await response.json();
      console.log("Fetched code:", result.user_code);
      setCode(result.user_code);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsCodeLoading(false);
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      console.error("Clipboard API not available");
    }
  };

  // Add this useEffect to log state changes
  useEffect(() => {
    console.log("isScrapingStarted:", isScrapingStarted);
    console.log("isCodeLoading:", isCodeLoading);
    console.log("code:", code);
    fetchCategories();
    // code_outlook();
  }, [isScrapingStarted, isCodeLoading, code]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${apiUrl}recruiters/categories/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch categories");
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Failed to fetch categories. Please try again.");
    }
  };
  const handleCategoryChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedCategories(selectedValues);
  };

  const handleAddCategory = async () => {
    if (!newCategory.trim()) return;
    try {
      const response = await fetch(`${apiUrl}recruiters/categories/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ name: newCategory.trim().toLowerCase() }),
      });
      if (!response.ok) throw new Error("Failed to add category");
      const addedCategory = await response.json();
      setCategories([...categories, addedCategory]);
      setNewCategory("");
    } catch (error) {
      console.error("Error adding category:", error);
      setError("Failed to add category. Please try again.");
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    const selectedFiles = Array.from(event.dataTransfer.files);
    processFiles(selectedFiles);
  };

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    processFiles(selectedFiles);
  };

  const processFiles = (selectedFiles) => {
    let newFiles = [];
    let errors = [];

    selectedFiles.forEach((file) => {
      if (file.type === "application/pdf") {
        const isDuplicate = files.some(
          (existingFile) => existingFile.name === file.name
        );

        if (!isDuplicate) {
          newFiles.push({
            id: Date.now() + Math.random(),
            name: file.name,
            type: file.type,
            size: (file.size / 1024).toFixed(2) + " KB",
            uploadDate: new Date().toLocaleDateString(),
            fileObject: file,
          });
        } else {
          errors.push(`${file.name} (Duplicate)`);
        }
      } else {
        errors.push(file.name);
      }
    });

    if (errors.length > 0) {
      setError(
        `Some files were not uploaded. Only PDF files are allowed. The following were not uploaded: ${errors.join(
          ", "
        )}`
      );
    } else {
      setError("");
    }

    setFiles([...files, ...newFiles]);
  };
  const uploadFiles = async () => {
    if (files.length === 0) return;
    if (selectedCategories.length === 0) {
      setError("Please select a category");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files[]", file.fileObject);
      });

      const categoryId = selectedCategories[0];
      const selectedCategory = categories.find((cat) => cat.id === categoryId);

      if (!selectedCategory) {
        throw new Error("Selected category not found");
      }

      // Append the single category
      formData.append("category", selectedCategory.name);

      const response = await fetch(
        `${apiUrl}recruiters/cvupload/${getUserIdFromAccessToken()}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Upload failed");
      }

      const data = await response.json();
      console.log("Upload successful:", data);

      // Simulate upload progress (optional)
      for (let i = 0; i <= 100; i++) {
        await new Promise((resolve) => setTimeout(resolve, 20));
        setUploadProgress(i);
      }

      // Clear files and reset error on success
      setFiles([]);
      setError("");
      setSelectedCategories([]); // Clear selected category

      // Show success animation
      setShowSuccessAnimation(true);
      setTimeout(() => {
        setShowSuccessAnimation(false);

        setShowDataScrapingAnimation(true);
        setTimeout(() => {
          setShowDataScrapingAnimation(false);
        }, 5000); // data scraping animation duration
      }, 2000); // success animation duration
    } catch (error) {
      console.error("Error uploading files:", error);
      setError(error.message || "Failed to upload files. Please try again.");
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const removeFile = (id) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const removeAllFiles = () => {
    setFiles([]);
    setError("");
  };

  return (
    <Box sx={{ display: "flex", maxWidth: "100%", mx: "auto", p: 3, mt: 4 }}>
      <Box sx={{ width: "40%", pt: 10, pr: 3, pb: 1, pl: 4 }} align="center">
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }} align="center">
          Email Scraping
        </Typography>
        <TextField
          fullWidth
          label="Enter Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: 5 }}
        />
        {(isCodeLoading || code) && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Generated Code
              {code && (
                <Tooltip title={copied ? "Copied!" : "Copy code"}>
                  <IconButton onClick={handleCopy} size="small" sx={{ ml: 2 }}>
                    {copied ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                minHeight: "100px",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              {isCodeLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <pre>{code}</pre>
              )}
            </Paper>
          </Box>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={(event) => {
            handleStartScraping(event);
          }}
          onMouseUp={() => {
            setTimeout(() => {
              code_outlook();
              openAuthPopup();
            }, 2000);
            // Commented out as per your original code
            // setTimeout(() => {
            //   openAuthPopup();
            // }, 5000);
          }}
          disabled={isCodeLoading}
        >
          {isCodeLoading ? "Scraping..." : "Start Scraping"}
        </Button>
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ marginLeft: 7, borderColor: "black", marginTop: 15 }}
      />

      <Box sx={{ width: "60%", p: 10 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }} align="center">
          Data Import
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Select Categories
          </Typography>
          <Select
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            renderValue={(selected) =>
              selected
                .map((id) => categories.find((cat) => cat.id === id)?.name)
                .filter(Boolean)
                .join(", ")
            }
            sx={{ width: "100%", mb: 2 }}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="New category"
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <Button variant="contained" onClick={handleAddCategory}>
              Add Category
            </Button>
          </Box>
        </Box>

        <Grow in={true}>
          <UploadBox
            elevation={3}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleFileDrop}
            sx={{
              borderColor: dragging ? "primary.main" : "inherit",
              borderWidth: dragging ? "3px" : "2px",
              mt: 4,
              mb: 4,
            }}
          >
            <label htmlFor="file-upload">
              <UploadIcon sx={{ fontSize: 60, color: "primary.main", mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                Click to upload or drag and drop multiple files
              </Typography>
              <Typography variant="body1" color="textSecondary">
                PDF files only (MAX. 10MB each)
              </Typography>
              <VisuallyHiddenInput
                id="file-upload"
                type="file"
                accept=".pdf"
                multiple
                onChange={handleFileUpload}
              />
            </label>
          </UploadBox>
        </Grow>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {showSuccessAnimation && (
          <Fade in={showSuccessAnimation} timeout={600}>
            <Alert severity="success" sx={{ mt: 2 }}>
              Files uploaded successfully!
            </Alert>
          </Fade>
        )}
        {showDataScrapingAnimation && (
          <Fade in={showDataScrapingAnimation} timeout={1000}>
            <Box sx={{ textAlign: "center", mt: 4 }}>
              <AnimatedDataIcon />
              <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
                Data Scraping in Progress...
              </Typography>
            </Box>
          </Fade>
        )}

        {files.length > 0 && (
          <Paper sx={{ mt: 0 }} elevation={3}>
            {isUploading && (
              <Box sx={{ width: "100%", mt: 3 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
              }}
            >
              <Typography variant="h6">
                Uploaded Files ({files.length})
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={removeAllFiles}
                  sx={{ mr: 2 }}
                >
                  Remove All
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={uploadFiles}
                  disabled={isUploading || files.length === 0}
                >
                  {isUploading ? "Uploading..." : "Upload Files"}
                </Button>
              </Box>
            </Box>

            <TableContainer sx={{ mt: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Upload Date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <PdfIcon sx={{ mr: 1, color: "error.main" }} />
                          {file.name}
                        </Box>
                      </TableCell>
                      <TableCell>{file.type}</TableCell>
                      <TableCell>{file.size}</TableCell>
                      <TableCell>{file.uploadDate}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="error"
                          onClick={() => removeFile(file.id)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </Box>
  );
}
