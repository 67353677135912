import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Alert,
  Divider,
  CircularProgress,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";

const VideoCallPopup = ({ receiverId, senderId, apiUrl }) => {
  const [meetingLink, setMeetingLink] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState("");

  const createGroupLink = async () => {
    try {
      const response = await fetch(
        `${apiUrl}messaging/api/chat/${receiverId}/${senderId}/creategroup/`
      );
      if (!response.ok) throw new Error("Failed to generate meeting link");
      const result = await response.json();
      setMeetingLink(result.meeting_link);
      setIsPopupOpen(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(meetingLink);
    alert("Link copied to clipboard!");
  };

  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      {/* Video Call Icon */}
      <Tooltip title="Start a Group Video Call">
        <IconButton
          onClick={createGroupLink}
          sx={{
            backgroundColor: "primary.main",
            color: "#fff",
            "&:hover": { backgroundColor: "primary.dark" },
            boxShadow: 3,
            width: 40,
            height: 40,
          }}
        >
          <GroupsIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Tooltip>

      {/* Popup Modal */}
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        PaperProps={{
          sx: { borderRadius: 4, boxShadow: 8, p: 2 },
        }}
      >
        <DialogContent sx={{ textAlign: "center", p: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
            Share Meeting Link
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" sx={{ mb: 2 }}>
            Share this link with users to join the meeting. For security
            purposes, after opening the link, go to **Settings** and add a room
            code to protect your meeting.
          </Typography>
          {meetingLink ? (
            <>
              <Typography
                variant="body2"
                sx={{
                  wordBreak: "break-word",
                  backgroundColor: "#f4f4f4",
                  padding: 1,
                  borderRadius: 2,
                  mb: 2,
                  textAlign: "left",
                }}
              >
                {meetingLink}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={copyToClipboard}
                sx={{ width: "100%", borderRadius: 2, mb: 2 }}
              >
                Copy Link
              </Button>
            </>
          ) : (
            <CircularProgress color="primary" />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => setIsPopupOpen(false)}
            color="secondary"
            variant="outlined"
            sx={{ borderRadius: 2 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Message */}
      {error && (
        <Box sx={{ mt: 2, width: "100%", maxWidth: 400, margin: "0 auto" }}>
          <Alert severity="error" sx={{ borderRadius: 2 }}>
            {error}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default VideoCallPopup;
