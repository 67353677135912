import React, { useEffect, useState } from 'react';
import { 
  TextField, 
  Button, 
  Grid, 
  Typography, 
  Paper, 
  Switch,
  FormControlLabel,
  createTheme,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { 
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import axios from 'axios';
import { COLORS } from 'colors/color';
import RecruiterCandidatesApplications from './Candidates/RecruiterCandidatesApplications ';
import RecruiterCandidatesApplicationsPDF from './Candidates/RecruiterCandidatesApplicationsPDF';

export default function Settings() {
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();

  const [smtpSettings, setSmtpSettings] = useState({
    EMAIL_HOST: '',
    EMAIL_PORT: '',
    EMAIL_HOST_USER: '',
    EMAIL_HOST_PASSWORD: '',
    EMAIL_USE_TLS: true,
  });
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    palette: {
    //   mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });
  useEffect(()=>{
    console.log('settings')
    fetchSettings()
  },[])
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; 
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSmtpSettings(prevSettings => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    setSmtpSettings(prevSettings => ({
      ...prevSettings,
      EMAIL_USE_TLS: event.target.checked,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('SMTP Settings:', smtpSettings);
  };


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    console.log("Updated SMTP Settings:", smtpSettings);
  }, [smtpSettings]);
  
  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${apiUrl}authentication/smtp-settings/user/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
  
      const settings = {
        EMAIL_HOST: response.data.email_host || '',
        EMAIL_PORT: response.data.email_port || '',
        EMAIL_HOST_USER: response.data.email_host_user || '',
        EMAIL_HOST_PASSWORD: '', 
        EMAIL_USE_TLS: response.data.email_use_tls || false,
      };
  
      setSmtpSettings(settings);
      console.log('Fetched SMTP Settings:', settings); 
    } catch (error) {
      console.error('Error fetching SMTP settings:', error);
    } finally {
      setLoading(false); 
    }
  };
  
  const updateSettings = async () => {
    try {
        console.log('-->',smtpSettings)
      const response = await axios.patch(`${apiUrl}authentication/smtp-settings/update/`, smtpSettings, {
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
      });
      console.log('Settings updated successfully', response.data);
    } catch (error) {
      console.error('Error updating SMTP settings:', error);
    }
  };
  const testSmtpSettings = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}authentication/test-smtp/`,
        {},  // No body needed for this request
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log('Test email sent successfully', response.data);
      // Show success message to the user
    } catch (error) {
      console.error('Error testing SMTP settings:', error);
      // Show error message to the user
    }
  };
  
  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    <div className="content">
      <RecruiterCandidatesApplications recruiterId={userId} />
      <RecruiterCandidatesApplicationsPDF recruiterId={userId} />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
          padding: 3,
        //   backgroundColor: theme.palette.background.default,
        }}
      >
        <Paper 
          elevation={3} 
          sx={{
            padding: 4,
            maxWidth: '500px',
            width: '100%',
            borderRadius: 4,
            position: 'relative',
          }}
        >
          {/* <IconButton
            onClick={toggleDarkMode}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton> */}
          <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
            SMTP Settings
          </Typography>
          {!loading && (
          <form onSubmit={handleSubmit}>
          
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SMTP Host"
                  name="EMAIL_HOST"
                  value={smtpSettings.EMAIL_HOST}
                  onChange={handleInputChange}
                  helperText="Example: smtp.gmail.com, smtp.office365.com"

                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SMTP Port"
                  name="EMAIL_PORT"
                  type="number"
                  value={smtpSettings.EMAIL_PORT}
                  onChange={handleInputChange}
                  helperText="Common ports: 25, 465 (SSL), 587 (TLS)"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email Username"
                  name="EMAIL_HOST_USER"
                  value={smtpSettings.EMAIL_HOST_USER}
                  onChange={handleInputChange}
                  variant="outlined"
                  helperText="Your full email address, e.g., user@example.com"

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email Password"
                  name="EMAIL_HOST_PASSWORD"
                  type={showPassword ? 'text' : 'password'}
                  value={smtpSettings.EMAIL_HOST_PASSWORD}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={smtpSettings.EMAIL_USE_TLS}
                      onChange={handleSwitchChange}
                      name="EMAIL_USE_TLS"
                      color="primary"
                    />
                  }
                  label="Use TLS"
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  onClick={updateSettings}
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.signIn,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.Button_OnHold,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Save Settings
                </Button>
                {/* <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  onClick={handleExportCSV}
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.signIn,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.Button_OnHold,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  ExportCSV
                </Button> */}
                
              </Grid>
            </Grid>
          </form>)}
        </Paper>
      </Box>
    </div>
  );
}