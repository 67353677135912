import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Avatar,
  IconButton,
} from '@mui/material';
import { LocationOn, CalendarToday, BookmarkBorder, Share, ArrowBack } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { COLORS } from 'colors/color';
import Footer from 'components/Footer/Footer';
import axios from 'axios';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  overflow: 'visible',
}));

const JobImage = styled('div')(({ theme }) => ({
  height: 250,
  borderRadius: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 'inherit',
  },
}));

const CompanyLogo = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  border: `4px solid ${theme.palette.background.paper}`,
  position: 'absolute',
  bottom: -40,
  left: 24,
}));

const JobChip = styled(Chip)(({ theme }) => ({
  fontWeight: 600,
  borderRadius: theme.spacing(1),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  fontWeight: 600,
  padding: theme.spacing(1.5, 3),
}));

function ViewJob() {
  const { postid } = useParams(); 
  const location = useLocation();
  const navigate = useNavigate();
  // const job = location.state?.job;
  const [isLoading, setIsLoading] = useState(true);
  const [showFull, setShowFull] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());
  const [job, setJob] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const getPost = async () => {
    try {
      
      const response = await axios.get(`${apiUrl}posts/${postid}/find`);
      setJob(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching job data:", error);
      setIsLoading(false);
      // Handle error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    console.log("post id is :",postid);
    getPost();
  }, [postid]);
  useEffect(() => {
    if (job) {
      setIsLoading(false);
    }
  }, [job]);

  if (isLoading || !job) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  
  const handleApplyClick = () => {
    localStorage.getItem('accessToken') 
      ? navigate("/applyjob", { state: { job } })
      : (window.location.href = "../login");
  };
  const handleClose = () => {
    setShowForm(false);
  };
 
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!job) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">Job not found</Typography>
      </Box>
    );
  }
  return (
    <Container maxWidth="md">
      
      <Box my={4}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mb: 2 }}
          onClick={() => window.history.back()}
        >
          <ArrowBack />
        </IconButton>
        <StyledCard>
          <JobImage style={{ backgroundImage: `url(${job.image || 'https://via.placeholder.com/600x250?text=Job+Image'})` }}>
            <CompanyLogo src={job.companyLogo || 'https://via.placeholder.com/80'} alt={job.company} />
          </JobImage>
          <CardContent sx={{ pt: 6 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item xs={12} sm>
                <Typography variant="h4" component="h1" gutterBottom>
                  {job.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                  {job.company}
                </Typography>
              </Grid>
              <Grid item>
                <JobChip label={job.mode} color="primary" />
              </Grid>
            </Grid>
            
            <Box mt={3} display="flex" alignItems="center" flexWrap="wrap">
              <Box display="flex" alignItems="center" mr={3}>
                <LocationOn sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography variant="body1">{job.localisation}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <CalendarToday sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography variant="body1">Full-time</Typography>
              </Box>
            </Box>

            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Job Description
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  maxHeight: showFull ? 'none' : '100px',
                  overflow: 'hidden',
                  position: 'relative',
                  '&::after': !showFull
                    ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '50px',
                        background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                      }
                    : {},
                }}
              >
                {job.description}
              </Typography>
              <Button
                onClick={() => setShowFull(!showFull)}
                sx={{ mt: 1, textTransform: 'none', fontWeight: 600 }}
              >
                {showFull ? 'Show less' : 'Show more'}
              </Button>
            </Box>

            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Requirements
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {job.requirements?.map((req, index) => (
                  <JobChip key={index} label={req} variant="outlined" />
                ))}
              </Box>
            </Box>

            <Box mt={4} display="flex" justifyContent="space-between">
              <ActionButton variant="contained" 
              sx={{
                height: "45px",
                color: COLORS.signIn,
                backgroundColor: COLORS.Button_OnHold,
                border: "1px solid #333333",
                borderRadius: "7px",
                fontSize: "13px",
                fontWeight: 600,
                textTransform: "none",
                padding: "0 16px",
                "&:hover": {
                  backgroundColor: 'transparent',
                  borderColor: "#555555",
                  color: COLORS.signIn,
                },

                transition: "all 0.3s ease",
              }}
              startIcon={<BookmarkBorder />}>
                Save Job
              </ActionButton>
              <ActionButton variant="outlined" 
              sx={{
                color: COLORS.signIn,
                backgroundColor: 'transparent',
                border: "1px solid #333333",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: 'transparent',
                  borderColor: "#555555",
                  color: COLORS.signIn,
                },

                transition: "all 0.3s ease",
              }}
              startIcon={<Share />}>
                Share
              </ActionButton>
            </Box>

            <Box mt={4} textAlign="center">
              <ActionButton variant="contained" 
              sx={{
                height: "60px",
                color: COLORS.white,
                backgroundColor: COLORS.signIn,
                border: "1px solid #333333",
                borderRadius: "7px",
                fontSize: "13px",
                fontWeight: 600,
                textTransform: "none",
                padding: "0 16px",
                "&:hover": {
                  backgroundColor: 'transparent',
                  borderColor: "#555555",
                  color: COLORS.signIn,
                },

                transition: "all 0.3s ease",
              }}
              onClickCapture={handleApplyClick}
              disabled={userRole === "r"}
              size="large">
                Apply Now
              </ActionButton>
            </Box>
          </CardContent>
        </StyledCard>
      </Box><Footer />
    </Container>
    
  );
}

export default ViewJob;