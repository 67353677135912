// // C:\Users\youne\OneDrive\Desktop\younes_project\ATS_PROJECT-\front-end\src\views\quiz\QuizCreator.jsx
// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Paper,
//   TextField,
//   Typography,
//   Stepper,
//   Step,
//   StepLabel,
//   IconButton,
// } from "@mui/material";
// import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
// import quizApi from "./quizApi";

// const QuestionType = {
//   MCQ: "mcq",
//   CODE: "code",
//   TEXT: "text",
// };

// const QuizCreator = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const [quizData, setQuizData] = useState({
//     title: "",
//     description: "",
//     questions: [
//       {
//         question_type: QuestionType.MCQ,
//         question_text: "",
//         code_template: "",
//         points: 1,
//         order: 1,
//         answers: [
//           { answer_text: "", is_correct: false },
//           { answer_text: "", is_correct: false },
//         ],
//       },
//     ],
//   });

//   const handleQuizDetailsChange = (e) => {
//     setQuizData({
//       ...quizData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   /*************  ✨ Codeium Command ⭐  *************/
//   /******  05142e84-7c79-4015-a740-11d8098d7342  *******/
//   const handleQuestionChange = (index, field, value) => {
//     const updatedQuestions = [...quizData.questions];
//     updatedQuestions[index] = {
//       ...updatedQuestions[index],
//       [field]: value,
//     };
//     setQuizData({ ...quizData, questions: updatedQuestions });
//   };

//   const handleAnswerChange = (questionIndex, answerIndex, field, value) => {
//     const updatedQuestions = [...quizData.questions];
//     const answers = [...updatedQuestions[questionIndex].answers];
//     answers[answerIndex] = { ...answers[answerIndex], [field]: value };
//     updatedQuestions[questionIndex] = {
//       ...updatedQuestions[questionIndex],
//       answers,
//     };
//     setQuizData({ ...quizData, questions: updatedQuestions });
//   };

//   const addQuestion = () => {
//     setQuizData({
//       ...quizData,
//       questions: [
//         ...quizData.questions,
//         {
//           question_type: QuestionType.MCQ,
//           question_text: "",
//           code_template: "",
//           points: 1,
//           order: quizData.questions.length,
//           answers: [
//             { answer_text: "", is_correct: false },
//             { answer_text: "", is_correct: false },
//           ],
//         },
//       ],
//     });
//   };

//   const addAnswer = (questionIndex) => {
//     const updatedQuestions = [...quizData.questions];
//     updatedQuestions[questionIndex].answers.push({
//       answer_text: "",
//       is_correct: false,
//     });
//     setQuizData({ ...quizData, questions: updatedQuestions });
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await quizApi.createQuiz(quizData);
//       // Handle success (e.g., show success message, redirect)
//       console.log("Quiz created:", response);
//     } catch (error) {
//       // Handle error
//       console.error("Error creating quiz:", error);
//     }
//   };

//   const steps = ["Quiz Details", "Add Questions", "Review"];
//   useEffect(() => {
//     console.log("Component is mounted or updated!");
//   });
//   return (
//     <Container maxWidth="md">
//       <Paper elevation={3} sx={{ p: 4, my: 4 }}>
//         <Typography variant="h4" gutterBottom>
//           Create New Quiz
//         </Typography>

//         <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
//           {steps.map((label) => (
//             <Step key={label}>
//               <StepLabel>{label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>

//         {activeStep === 0 && (
//           <Box sx={{ mb: 4 }}>
//             <TextField
//               fullWidth
//               label="Quiz Title"
//               name="title"
//               value={quizData.title}
//               onChange={handleQuizDetailsChange}
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               fullWidth
//               label="Description"
//               name="description"
//               multiline
//               rows={4}
//               value={quizData.description}
//               onChange={handleQuizDetailsChange}
//             />
//           </Box>
//         )}

//         {activeStep === 1 && (
//           <Box>
//             {quizData.questions.map((question, questionIndex) => (
//               <Paper
//                 key={questionIndex}
//                 sx={{ p: 2, mb: 2 }}
//                 variant="outlined"
//               >
//                 <Typography variant="h6" gutterBottom>
//                   Question {questionIndex + 1}
//                 </Typography>
//                 <TextField
//                   fullWidth
//                   label="Question Text"
//                   value={question.question_text}
//                   onChange={(e) =>
//                     handleQuestionChange(
//                       questionIndex,
//                       "question_text",
//                       e.target.value
//                     )
//                   }
//                   sx={{ mb: 2 }}
//                 />

//                 {question.answers.map((answer, answerIndex) => (
//                   <Box key={answerIndex} sx={{ display: "flex", mb: 2 }}>
//                     <TextField
//                       fullWidth
//                       label={`Answer ${answerIndex + 1}`}
//                       value={answer.answer_text}
//                       onChange={(e) =>
//                         handleAnswerChange(
//                           questionIndex,
//                           answerIndex,
//                           "answer_text",
//                           e.target.value
//                         )
//                       }
//                     />
//                     <Button
//                       sx={{ ml: 1 }}
//                       variant="outlined"
//                       onClick={() =>
//                         handleAnswerChange(
//                           questionIndex,
//                           answerIndex,
//                           "is_correct",
//                           !answer.is_correct
//                         )
//                       }
//                       color={answer.is_correct ? "success" : "primary"}
//                     >
//                       {answer.is_correct ? "Correct" : "Mark Correct"}
//                     </Button>
//                   </Box>
//                 ))}

//                 <Button
//                   startIcon={<AddIcon />}
//                   onClick={() => addAnswer(questionIndex)}
//                   sx={{ mr: 1 }}
//                 >
//                   Add Answer
//                 </Button>
//               </Paper>
//             ))}

//             <Button
//               variant="contained"
//               startIcon={<AddIcon />}
//               onClick={addQuestion}
//               sx={{ mt: 2 }}
//             >
//               Add Question
//             </Button>
//           </Box>
//         )}

//         {activeStep === 2 && (
//           <Box>
//             <Typography variant="h6" gutterBottom>
//               Review Quiz
//             </Typography>
//             {/* Add review content */}
//           </Box>
//         )}

//         <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
//           <Button
//             disabled={activeStep === 0}
//             onClick={() => setActiveStep((prev) => prev - 1)}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             onClick={
//               activeStep === steps.length - 1
//                 ? handleSubmit
//                 : () => setActiveStep((prev) => prev + 1)
//             }
//           >
//             {activeStep === steps.length - 1 ? "Create Quiz" : "Next"}
//           </Button>
//         </Box>
//       </Paper>
//     </Container>
//   );
// };

// export default QuizCreator;
import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  IconButton,
  FormControlLabel,
  Checkbox,
  Divider,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import ExistingQuizzes from "./ExistingQuizzes";
const steps = ["Basic Information", "Add Questions", "Review"];
function getUserIdFromAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    console.error("No access token found in local storage");
    return null;
  }

  try {
    const decodedToken = parseJwt(accessToken);
    console.log("Access token : ", decodedToken);
    const senderId = decodedToken.user_id;
    return senderId;
  } catch (error) {
    console.error("Failed to decode access token", error);
    return null;
  }
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  console.log("payload : ", jsonPayload);

  return JSON.parse(jsonPayload);
}
export default function QuizCreator() {
  const [activeStep, setActiveStep] = useState(0);
  const [quizData, setQuizData] = useState({
    title: "",
    description: "",
    duration_minutes: 30,
    passing_score: 60,
    questions: [],
    recruiterId: getUserIdFromAccessToken(),
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    answers: [{ text: "", is_correct: false }],
  });

  const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);

  const resetCurrentQuestion = () => {
    setCurrentQuestion({
      text: "",
      answers: [{ text: "", is_correct: false }],
    });
    setEditingQuestionIndex(null);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    console.log(quizData);

    try {
      const response = await fetch(`${apiUrl}recruiters/api/quizzes/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(quizData),
      });

      if (!response.ok) throw new Error("Failed to create quiz");

      const quiz = await response.json();

      // Add questions one by one
      for (const question of quizData.questions) {
        await fetch(
          `${apiUrl}recruiters/api/quizzes/${quiz.id}/add_question/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(question),
          }
        );
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  const addAnswer = () => {
    setCurrentQuestion((prev) => ({
      ...prev,
      answers: [...prev.answers, { text: "", is_correct: false }],
    }));
  };

  const removeAnswer = (index) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      answers: prev.answers.filter((_, i) => i !== index),
    }));
  };

  const addQuestion = () => {
    if (!currentQuestion.text || currentQuestion.answers.some((a) => !a.text)) {
      return; // Don't add if question or any answer is empty
    }

    if (editingQuestionIndex !== null) {
      // Update existing question
      setQuizData((prev) => ({
        ...prev,
        questions: prev.questions.map((q, idx) =>
          idx === editingQuestionIndex ? currentQuestion : q
        ),
      }));
    } else {
      // Add new question
      setQuizData((prev) => ({
        ...prev,
        questions: [...prev.questions, currentQuestion],
      }));
    }
    resetCurrentQuestion();
  };

  const editQuestion = (index) => {
    setCurrentQuestion(quizData.questions[index]);
    setEditingQuestionIndex(index);
  };

  const removeQuestion = (index) => {
    setQuizData((prev) => ({
      ...prev,
      questions: prev.questions.filter((_, idx) => idx !== index),
    }));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              label="Quiz Title"
              value={quizData.title}
              onChange={(e) =>
                setQuizData({ ...quizData, title: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={quizData.description}
              onChange={(e) =>
                setQuizData({ ...quizData, description: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            <TextField
              type="number"
              label="Duration (minutes)"
              value={quizData.duration_minutes}
              onChange={(e) =>
                setQuizData({
                  ...quizData,
                  duration_minutes: parseInt(e.target.value),
                })
              }
              sx={{ mr: 2 }}
            />
            <TextField
              type="number"
              label="Passing Score (%)"
              value={quizData.passing_score}
              onChange={(e) =>
                setQuizData({
                  ...quizData,
                  passing_score: parseInt(e.target.value),
                })
              }
            />
            <ExistingQuizzes apiUrl={apiUrl} />
          </Box>
        );

      case 1:
        return (
          <Box sx={{ p: 2 }}>
            {/* Question input section */}
            <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                {editingQuestionIndex !== null
                  ? "Edit Question"
                  : "Add New Question"}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Question"
                value={currentQuestion.text}
                onChange={(e) =>
                  setCurrentQuestion({
                    ...currentQuestion,
                    text: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              {currentQuestion.answers.map((answer, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <TextField
                    fullWidth
                    label={`Answer ${index + 1}`}
                    value={answer.text}
                    onChange={(e) => {
                      const newAnswers = [...currentQuestion.answers];
                      newAnswers[index].text = e.target.value;
                      setCurrentQuestion({
                        ...currentQuestion,
                        answers: newAnswers,
                      });
                    }}
                    sx={{ mr: 1 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={answer.is_correct}
                        onChange={(e) => {
                          const newAnswers = [...currentQuestion.answers];
                          newAnswers[index].is_correct = e.target.checked;
                          setCurrentQuestion({
                            ...currentQuestion,
                            answers: newAnswers,
                          });
                        }}
                      />
                    }
                    label="Correct"
                  />
                  <IconButton onClick={() => removeAnswer(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                <Button startIcon={<AddIcon />} onClick={addAnswer}>
                  Add Answer
                </Button>
                <Button
                  variant="contained"
                  onClick={addQuestion}
                  disabled={
                    !currentQuestion.text ||
                    currentQuestion.answers.some((a) => !a.text)
                  }
                >
                  {editingQuestionIndex !== null
                    ? "Update Question"
                    : "Add Question"}
                </Button>
                {editingQuestionIndex !== null && (
                  <Button onClick={resetCurrentQuestion}>Cancel Edit</Button>
                )}
              </Box>
            </Paper>

            {/* List of added questions */}
            {quizData.questions.length > 0 && (
              <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Added Questions ({quizData.questions.length})
                </Typography>
                <List>
                  {quizData.questions.map((question, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        flexDirection: "column",
                        alignItems: "stretch",
                        bgcolor:
                          editingQuestionIndex === index
                            ? "action.selected"
                            : "transparent",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography variant="subtitle1">
                          Question {index + 1}
                        </Typography>
                        <Box>
                          <IconButton onClick={() => editQuestion(index)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => removeQuestion(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography>{question.text}</Typography>
                      <List dense>
                        {question.answers.map((answer, aIndex) => (
                          <ListItem key={aIndex}>
                            <Typography
                              color={
                                answer.is_correct
                                  ? "success.main"
                                  : "text.primary"
                              }
                            >
                              • {answer.text}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                      {index < quizData.questions.length - 1 && (
                        <Divider sx={{ my: 1 }} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
        );

      case 2:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">{quizData.title}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {quizData.description}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Duration: {quizData.duration_minutes} minutes | Passing Score:{" "}
              {quizData.passing_score}%
            </Typography>
            {quizData.questions.map((question, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">Question {index + 1}</Typography>
                  <Typography>{question.text}</Typography>
                  {question.answers.map((answer, aIndex) => (
                    <Typography
                      key={aIndex}
                      color={answer.is_correct ? "success.main" : "inherit"}
                    >
                      • {answer.text}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            ))}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {renderStepContent(activeStep)}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={quizData.questions.length === 0}
            >
              Create Quiz
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={
                (activeStep === 0 &&
                  (!quizData.title || !quizData.description)) ||
                (activeStep === 1 && quizData.questions.length === 0)
              }
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
