import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateField } from "@mui/x-date-pickers/DateField";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  CloudUpload,
  Delete,
  Visibility,
} from "@mui/icons-material";
import { COLORS } from "colors/color";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import LoadingBar from "components/loadingBar/LoadignBar";
const UploadCard = styled(Card)(({ theme, isDragging, isUploaded }) => ({
  width: "100%",
  height: "100%",
  minHeight: "200px",
  borderRadius: "16px",
  boxShadow: "none",
  transition: "all 0.3s ease",
  backgroundColor: isUploaded
    ? "rgba(255, 255, 255, 0.3)"
    : "rgba(255, 255, 255, 0.2)",
  backdropFilter: "blur(10px)",
  border: "2px dashed",
  borderColor: isDragging
    ? theme.palette.primary.main
    : isUploaded
      ? "#fff"
      : "rgba(224, 224, 224, 0.6)",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  transform: isDragging ? "scale(1.02)" : "scale(1)",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
    backgroundColor: "rgba(255, 255, 255, 0.35)",
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const IconWrapper = styled(Box)(({ theme, isUploaded }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 48,
    marginBottom: theme.spacing(2),
    color: isUploaded ? "#fff" : "#f1f1f1",
  },
}));

const ApplicationForm = ({
  setHiddforum,
  post_id,
  post_title,
  postApplied,
  setIsClicked,
  hasApplied,
  setHasApplied,
}) => {
  const [formData, setFormData] = useState({
    academicDegree: "",
    lastPostOccupied: "",
    linkedinProfile: "",
    dateOfBirth: null,
    familyStatus: "",
    fieldOfStudy: "",
  });

  // const { register, handleSubmit, reset } = useForm();
  // const [resumeUploaded, setResumeUploaded] = useState(false);
  // const [coverLetterUploaded, setCoverLetterUploaded] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState({ resumes: null });
  // const [uploadedFilesList, setUploadedFilesList] = useState({ resumes: null });
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [uploadedResume, setUploadedResume] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [coverLetterUploaded, setCoverLetterUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ resumes: null });
  const [uploadedFilesList, setUploadedFilesList] = useState({ resumes: null });
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedResume, setUploadedResume] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();

  //-------------------------------------------------------------------------------------------
  const validateForm = () => {
    const errors = {};

    if (!hasApplied) {
      if (!formData.academicDegree) {
        errors.academicDegree = "Academic degree is required";
      }
      if (!formData.fieldOfStudy) {
        errors.fieldOfStudy = "Field of study is required";
      }
      if (!formData.lastPostOccupied) {
        errors.lastPostOccupied = "Last post occupied is required";
      }
      if (!formData.dateOfBirth) {
        errors.dateOfBirth = "Date of birth is required";
      }
      if (!resumeUploaded && !selectedFile) {
        errors.resume = "Resume is required";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const FileUploadCard = ({ type, isUploaded, register, handleFileUpload }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const syntheticEvent = {
          target: {
            files: files,
          },
        };
        handleFileUpload(
          syntheticEvent,
          type === "resume" ? "resume" : "coverLetter"
        );
      }
    };

    const isResume = type === "resume";
    const Icon = isResume ? CloudUpload : AttachFile;
    const title = isResume ? "Resume - CV" : "Cover Letter";
    const subtitle = isResume
      ? "Accepted formats: PDF, DOCX, RTF, TXT"
      : "We'll guide you through the process";

    return (
      <Grid item xs={6}>
        <HiddenInput
          id={`${type}-upload`}
          type="file"
          {...register(type === "resume" ? "resume" : "cover_letter", {
            onChange: (e) =>
              handleFileUpload(e, type === "resume" ? "resume" : "coverLetter"),
          })}
        />
        <label
          htmlFor={`${type}-upload`}
          style={{ display: "block", height: "100%" }}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <UploadCard isDragging={isDragging} isUploaded={isUploaded}>
            <CardContent sx={{ padding: "24px", textAlign: "center" }}>
              <IconWrapper isUploaded={isUploaded}>
                <Icon />
              </IconWrapper>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: isUploaded ? "#fff" : "#f1f1f1",
                  mb: 1,
                }}
              >
                {isDragging
                  ? "Drop here"
                  : isUploaded
                    ? `${title} Uploaded`
                    : `Upload your ${title}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#f1f1f1",
                  mt: 1,
                }}
              >
                {isDragging ? "Release to upload" : subtitle}
              </Typography>
            </CardContent>
          </UploadCard>
        </label>
      </Grid>
    );
  };
  //---------------------------------------------------------------------------------------

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Clear error when field is filled
    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const handleDateChange = (newValue) => {
    setFormData((prevData) => ({ ...prevData, dateOfBirth: newValue }));
    if (formErrors.dateOfBirth) {
      setFormErrors((prev) => ({ ...prev, dateOfBirth: null }));
    }
  };
  const handleFileSelect = (fileKey, file) => {
    setSelectedFile(fileKey);
    setResumeUploaded(true);
    setUploadedResume(file);
  };

  const handleFileUpload = (event, fileType) => {
    if (event.target.files.length > 0) {
      if (fileType === "resume") {
        setResumeUploaded(true);
        setUploadedResume(event.target.files[0]);
      } else {
        setCoverLetterUploaded(true);
      }
      setUploadedFiles((prev) => ({
        ...prev,
        [fileType]: event.target.files[0],
      }));
    }
  };

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = async () => {
    try {
      const response = await fetch(
        `${apiUrl}authentication/files-for-candidate/${userId}/`
      );
      if (!response.ok) {
        throw new Error(`An error occurred: ${response.statusText}`);
      }
      const json = await response.json();
      setUploadedFiles(json);
      setUploadedFilesList(json);
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  const onSubmit = async (data) => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const applicationFormData = new FormData();
      const candidateFormData = new FormData();

      // Application data
      applicationFormData.append("post", post_id);
      applicationFormData.append("candidate", userId);
      applicationFormData.append("status", "p");

      // Candidate data
      Object.entries(formData).forEach(([key, value]) => {
        if (value) {
          candidateFormData.append(key, value);
        }
      });
      console.log('here is the candidate forum')

      // Handle resume
      if (uploadedResume) {
        if (typeof uploadedResume === "string") {
          const relativePath = uploadedResume.split("media/").pop();
          applicationFormData.append("resume_path", relativePath);
        } else if (uploadedResume instanceof File) {
          applicationFormData.append("resume", uploadedResume);
          applicationFormData.append(
            "resume_path",
            "resumes/" + uploadedResume.name
          );
        } else {
          throw new Error("Invalid resume format");
        }
      } else if (data.resume && data.resume[0]) {
        applicationFormData.append("resume", data.resume[0]);
        applicationFormData.append(
          "resume_path",
          `resumes/${data.resume[0].name}`
        );
      } else {
        throw new Error("No resume selected");
      }

      // Handle cover letter
      if (data.cover_letter && data.cover_letter[0]) {
        applicationFormData.append("cover_letter", data.cover_letter[0]);
      }

      postApplied();
      setIsClicked(true);

      // Submit application and update candidate info
      try {
        await updateCandidateInfo(candidateFormData);
        console.log("Profile updated successfully");
      } catch (error) {
        console.log("Error updating profile", "error");
      }
      await submitApplication(applicationFormData);


    setHiddforum(true);
    setHasApplied(true);
    reset();
  } catch (error) {
    console.error(
      "Error submitting application:",
      error.response?.data || error.message
    );
  } finally {
    setIsSubmitting(false);
  }
};

const submitApplication = async (formData) => {
  const response = await axios.post(
    `${apiUrl}candidates/application/create/`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  console.log("Application submitted:", response.data);
  return response;
};

const updateCandidateInfo = async (formData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}candidates/${userId}/update/`,
      {
        // Convert FormData to match the API's expected format
        academic_degree: formData.get('academicDegree') || null,
        last_post_occupied: formData.get('lastPostOccupied') || null,
        linkedin_profile: formData.get('linkedinProfile') || null,
        date_of_birth: formData.get('dateOfBirth')
          ? new Date(formData.get('dateOfBirth')).toISOString().split('T')[0]
          : null,
        family_status: formData.get('familyStatus') || null,
        field_of_study: formData.get('fieldOfStudy') || null
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }
    );
    console.log("User updated:", response.data);
    return response;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

useEffect(() => {
  fetchCandidateInfo();
}, [])
const fetchCandidateInfo = async () => {
  const response = await axios.get(
    `${apiUrl}candidates/${userId}/find/`);
  console.log("User data:", response.data);
  setFormData({
    academicDegree: response.data.academic_degree,
    lastPostOccupied: response.data.last_post_occupied,
    linkedinProfile: response.data.linkedin_profile,
    dateOfBirth: response.data.date_of_birth,
    familyStatus: response.data.family_status,
    fieldOfStudy: response.data.field_of_study,
  });
  return response;
};


function getUserIdFromAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    console.error("No access token found in local storage");
    return null;
  }
  try {
    const decodedToken = parseJwt(accessToken);
    return decodedToken.user_id;
  } catch (error) {
    console.error("Failed to decode access token", error);
    return null;
  }
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  return JSON.parse(jsonPayload);
}
const UploadedFilesList = ({
  files,
  onDelete,
  selectedFile,
  onFileSelect,
  onShowFile,
}) => (
  <Box sx={{ mt: 2, mb: 2 }}>
    <Typography variant="h6" gutterBottom>
      List of files attached to your submissions.
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Resume</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(files).map(
          ([key, file], index) =>
            file && (
              <TableRow key={key}>
                <TableCell>
                  <Radio
                    checked={selectedFile === key}
                    onChange={() => onFileSelect(key, file)}
                    value={key}
                  />
                </TableCell>
                <TableCell>
                  {file.resume
                    ? `Resume ${index + 1}`
                    : `Resume ${index + 1}`}
                </TableCell>
                <TableCell>
                  {file.date
                    ? new Date(file.date).toLocaleDateString()
                    : new Date().toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  <div className="flex justify-end">
                    <Tooltip title="View File">
                      <IconButton
                        onClick={() => onShowFile(key, file.resume || file)}
                        color="#fff"
                        className="mr-2"
                      >
                        <Visibility fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete File">
                      <IconButton onClick={() => onDelete(key)} color="#fff">
                        <Delete fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  </Box>
);
const handleFileDelete = (fileType) => {
  setUploadedFiles((prev) => ({ ...prev, [fileType]: null }));
  if (fileType === "resume") {
    setResumeUploaded(false);
  } else {
    setCoverLetterUploaded(false);
  }
};

// const handleShowFile = (fileType, file) => {
//   //console.log("File:,first method", file);
//   let fileURL;

//   if (typeof file === "string") {
//     fileURL = `${apiUrl}media/${file}`;
//   } else if (file instanceof File) {
//     fileURL = URL.createObjectURL(file);
//   } else {
//     console.error("Invalid file type", file);
//     return;
//   }

//   window.open(fileURL, "_blank");
// };
const handleShowFile = async (key, file) => {
  let fileURL;
  let resume_path;

  if (typeof file === "string") {
    resume_path = file;
  } else if (file && typeof file === "object") {
    resume_path = file.resume || file.path || file.name;
  } else {
    console.error("Invalid file type", file);
    return;
  }

  if (!resume_path) {
    console.log("Resume path is not available");
    return;
  }

  // Ensure the resume_path uses forward slashes
  const normalizedResumePath = resume_path.replace(/\\/g, "/");
  fileURL = `${apiUrl}media/${normalizedResumePath}`;

  try {
    const response = await fetch(`${apiUrl}ai/search_resume/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resume_url: fileURL }),
    });

    const contentType = response.headers.get("content-type");

    if (response.ok) {
      if (contentType && contentType.includes("application/pdf")) {
        // Create a blob from the PDF content
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        // Open the PDF in a new tab
        window.open(url, "_blank");
      } else {
        console.log("Unexpected response from server");
      }
    } else {
      if (contentType && contentType.includes("text/html")) {
        // Handle HTML response (404 page)
        const htmlContent = await response.text();
        // Create a new window and write the HTML content to it
        const newWindow = window.open();
        newWindow.document.write(htmlContent);
        newWindow.document.close();
      } else {
        const data = await response.json();
        console.log(data.message);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
const [formProgress, setFormProgress] = React.useState(0);

// Calculate the form progress based on the filled fields
const calculateFormProgress = () => {

  const filledFields = 5;
  const totalFields = 10;
  const progress = (filledFields / totalFields) * 100;
  setFormProgress(progress);
};
return (
  <Container
    maxWidth="md"
    sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
  >
    <Typography
      variant="h4"
      gutterBottom
      sx={{ fontWeight: 700, color: "#fff", my: 3 }}
    >
      {post_title}
    </Typography>

    <Box
      sx={{
        bgcolor: "#0085FF1A",
        borderRadius: "16px",
        backdropFilter: "blur(10px)",
        boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
        flex: 1,
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#0085FF",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#004F98",
        },
      }}
    >
      <Box sx={{ p: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {!hasApplied && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!formErrors.academicDegree}
                  >
                    <InputLabel id="academic-degree-label">
                      Academic Degree *
                    </InputLabel>
                    <Select
                      labelId="academic-degree-label"
                      id="academicDegree"
                      name="academicDegree"
                      value={formData.academicDegree}
                      onChange={handleInputChange}
                      label="Academic Degree *"
                    >
                      <MenuItem value="baccalaureat">Baccalauréat</MenuItem>
                      <MenuItem value="deug">DEUG</MenuItem>
                      <MenuItem value="licence">Licence</MenuItem>
                      <MenuItem value="master">Master</MenuItem>
                      <MenuItem value="doctorat">Doctorat</MenuItem>
                      <MenuItem value="ingenieur">
                        Diplôme d'Ingénieur
                      </MenuItem>
                      <MenuItem value="dut">DUT</MenuItem>
                      <MenuItem value="bts">BTS</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {formErrors.academicDegree && (
                      <FormHelperText>
                        {formErrors.academicDegree}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!formErrors.fieldOfStudy}>
                    <InputLabel id="field-of-study-label">
                      Field of Study *
                    </InputLabel>
                    <Select
                      labelId="field-of-study-label"
                      id="fieldOfStudy"
                      name="fieldOfStudy"
                      value={formData.fieldOfStudy}
                      onChange={handleInputChange}
                      label="Field of Study *"
                    >
                      <MenuItem value="computer-science">
                        Computer Science
                      </MenuItem>
                      <MenuItem value="business-administration">
                        Business Administration
                      </MenuItem>
                      <MenuItem value="mechanical-engineering">
                        Mechanical Engineering
                      </MenuItem>
                      <MenuItem value="electrical-engineering">
                        Electrical Engineering
                      </MenuItem>
                      <MenuItem value="civil-engineering">
                        Civil Engineering
                      </MenuItem>
                      <MenuItem value="medicine">Medicine</MenuItem>
                      <MenuItem value="law">Law</MenuItem>
                      <MenuItem value="psychology">Psychology</MenuItem>
                      <MenuItem value="economics">Economics</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {formErrors.fieldOfStudy && (
                      <FormHelperText>
                        {formErrors.fieldOfStudy}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>



                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="lastPostOccupied"
                    name="lastPostOccupied"
                    label="Last post occupied"
                    value={formData.lastPostOccupied}
                    onChange={handleInputChange}
                    error={!!formErrors.lastPostOccupied}
                    helperText={formErrors.lastPostOccupied}
                  />
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="linkedinProfile"
                    name="linkedinProfile"
                    label="LinkedIn profile"
                    value={formData.linkedinProfile}
                    onChange={handleInputChange}
                  />
                </Grid>



                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Date Of Birth *"
                        value={
                          formData.dateOfBirth
                            ? dayjs(formData.dateOfBirth)
                            : null
                        }
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: "outlined",
                            required: true,
                            error: !!formErrors.dateOfBirth,
                            helperText: formErrors.dateOfBirth,
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>


                {/* {formData.familyStatus ? (
                    ""
                  ) : ( */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="familyStatus"
                    name="familyStatus"
                    label="Family status"
                    value={formData.familyStatus}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
            {/* <LoadingBar progress={formProgress} /> */}


            <Grid
              container
              spacing={5}
              sx={{ marginTop: "2%", paddingLeft: "3%" }}
            >
              <Grid
                container
                spacing={5}
                sx={{ marginTop: "2%", paddingLeft: "3%" }}
              >
                <FileUploadCard
                  type="resume"
                  isUploaded={resumeUploaded}
                  register={register}
                  handleFileUpload={handleFileUpload}
                />
                <FileUploadCard
                  type="cover_letter"
                  isUploaded={coverLetterUploaded}
                  register={register}
                  handleFileUpload={handleFileUpload}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 600, color: "#fff", mt: 2 }}
              >
                Uploaded Files
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "#fff" }}>Select</TableCell>
                    <TableCell sx={{ color: "#fff" }}>File Name</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Date</TableCell>
                    <TableCell align="right" sx={{ color: "#fff" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(uploadedFilesList).map(
                    ([key, file]) =>
                      file && (
                        <TableRow key={key}>
                          <TableCell sx={{ color: "#fff" }}>
                            <Radio
                              checked={selectedFile === key}
                              onChange={() =>
                                handleFileSelect(key, file.resume || file)
                              }
                              value={key}
                            />
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {file.resume ? `Resume ${key}` : `File ${key}`}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {file.date
                              ? new Date(file.date).toLocaleDateString()
                              : new Date().toLocaleDateString()}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="View File">
                              <IconButton
                                onClick={() =>
                                  handleShowFile(key, file.resume || file)
                                }
                                sx={{ color: "#fff" }} // Set icon color to white
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete File">
                              <IconButton
                                onClick={() => handleFileDelete(key)}
                                color="#fff"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>

    <Box sx={{ mt: 3, mb: 3 }}>
      <Button
        variant="contained"
        fullWidth
        size="large"
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        sx={{
          color: COLORS.white,
          // border: "1px solid",
          // borderColor: COLORS.signIn,
          bgcolor: COLORS.button_color,
          fontWeight: 600,
          fontSize: "1rem",
          padding: "12px",
          borderRadius: "50px",
          textTransform: "none",
          transition: "all 0.3s ease",
          "&:hover": {
            bgcolor: "#004F98",
            // borderColor: COLORS.white,
            color: COLORS.white,
          },
          "&:disabled": {
            bgcolor: "#E0E0E0",
            color: "#9E9E9E",
          },
        }}
      >
        {isSubmitting ? "Submitting..." : "Submit Application"}
      </Button>
    </Box>
  </Container>
);
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
