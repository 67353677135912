import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  Button,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Define styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
  },
  tableColWide: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 2,
  },
});
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
// PDF Document component
const MyDocument = ({ candidatesApplications }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Candidates and Applications for Recruiter's Posts</Text>
        {candidatesApplications.map((candidate) => (
          <View key={candidate.id}>
            <Text style={styles.subtitle}>{candidate.first_name} {candidate.last_name}</Text>

            {/* Table for Candidate Details */}
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Email</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.email}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Username</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.username}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Role</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.role}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Gender</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.gender}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>City</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.city || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Country</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.country || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Academic Degree</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.academic_degree || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Last Post Occupied</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.last_post_occupied || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>LinkedIn Profile</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.linkedin_profile || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Date of Birth</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.date_of_birth || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Family Status</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.family_status || 'N/A'}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Field of Study</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{candidate.field_of_study || 'N/A'}</Text>
                </View>
              </View>
            </View>

            {/* Applications Table */}
            <Text style={styles.subtitle}>Applications:</Text>
            {candidate.applications.length > 0 ? (
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application ID</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Post Title</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Step</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Status</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Date</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Score Matching</Text>
                  </View>
                </View>
                {candidate.applications.map((app) => (
                  <View style={styles.tableRow} key={app.id}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.id}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.post_title}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.step}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.status}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{new Date(app.date).toLocaleDateString()}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{app.score_matching}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : (
              <Text style={styles.text}>No applications available</Text>
            )}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const RecruiterCandidatesApplicationsPDF = ({ recruiterId }) => {
  const [candidatesApplications, setCandidatesApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('Fetching recruiter candidates and applications');
    fetchRecruiterCandidatesApplications();
  }, [recruiterId]);

  const fetchRecruiterCandidatesApplications = async () => {
    try {
      console.log('recruiterId', recruiterId);
      setLoading(true);
      const response = await axios.get(`${apiUrl}recruiters/${recruiterId}/candidates-applications/`);
      setCandidatesApplications(response.data);
      setLoading(false);
      console.log('data : ', response.data);
    } catch (err) {
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  const generatePDF = async () => {
    const blob = await pdf(<MyDocument candidatesApplications={candidatesApplications} />).toBlob();
    saveAs(blob, 'recruiter_candidates_applications.pdf');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Candidates and Applications for Recruiter's Posts
        </Typography>
        <StyledButton
          variant="contained"
          color="primary"
          startIcon={<PictureAsPdfIcon />}
          onClick={generatePDF}
        >
          Download PDF
        </StyledButton>
        {/* {candidatesApplications.map((candidate) => (
          <StyledPaper key={candidate.id} elevation={3}>
            <Typography variant="h5" gutterBottom>
              {candidate.first_name} {candidate.last_name}
            </Typography>
            <StyledTableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell>{candidate.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Username</TableCell>
                    <TableCell>{candidate.username}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Role</TableCell>
                    <TableCell>{candidate.role}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Gender</TableCell>
                    <TableCell>{candidate.gender}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">City</TableCell>
                    <TableCell>{candidate.city || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Country</TableCell>
                    <TableCell>{candidate.country || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Academic Degree</TableCell>
                    <TableCell>{candidate.academic_degree || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Last Post Occupied</TableCell>
                    <TableCell>{candidate.last_post_occupied || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">LinkedIn Profile</TableCell>
                    <TableCell>{candidate.linkedin_profile || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Date of Birth</TableCell>
                    <TableCell>{candidate.date_of_birth || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Family Status</TableCell>
                    <TableCell>{candidate.family_status || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Field of Study</TableCell>
                    <TableCell>{candidate.field_of_study || 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
            <Typography variant="h6" gutterBottom>
              Applications
            </Typography>
            {candidate.applications.length > 0 ? (
              <StyledTableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Application ID</TableCell>
                      <TableCell>Post Title</TableCell>
                      <TableCell>Step</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Score Matching</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidate.applications.map((app) => (
                      <TableRow key={app.id}>
                        <TableCell>{app.id}</TableCell>
                        <TableCell>{app.post_title}</TableCell>
                        <TableCell>{app.step}</TableCell>
                        <TableCell>
                          <Chip
                            label={app.status}
                            color={
                              app.status === 'Accepted' ? 'success' :
                              app.status === 'Rejected' ? 'error' :
                              'default'
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{new Date(app.date).toLocaleDateString()}</TableCell>
                        <TableCell>{app.score_matching}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            ) : (
              <Typography variant="body2">No applications available</Typography>
            )}
          </StyledPaper>
        ))} */}
      </Box>
    </Container>
  );
};

export default RecruiterCandidatesApplicationsPDF;
