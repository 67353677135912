import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Divider,
  Stack,
  Link,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { COLORS } from "colors/color";
import logoImage from "../../assets/img/logoSH2.png";
const JobCountBanner = styled(Box)(({ theme }) => ({
  // position: "absolute",
  // bottom: 0,
  // left: 0,
  // right: 0,
  // paddingTop:'9%',
  marginTop: "1rem",
  background: "linear-gradient(90deg, #007EF2 0%, #ABD7FF 100%)",
  color: "white",
  padding: theme.spacing(1),
  textAlign: "center",
}));
const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box component="footer" sx={{ width: "100%", pt: 4, pt: "5%" }}>
      <Container maxWidth="lg">
        {/* Top Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "flex-start" },
            mb: 6,
            gap: { xs: 4, md: 0 },
          }}
        >
          {/* Logo and Description */}
          <Box sx={{ maxWidth: "md", mb: { xs: 3, md: 0 } }}>
            {/* <Typography
              variant="h5"
              component="h1"
              sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                mb: 2
              }}
            >
              SMARTHIRE
            </Typography> */}
            <img
              src={logoImage}
              alt="Logo"
              style={{ width: "100px", marginRight: "32px" }}
            />

            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                maxWidth: 450,
                paddingTop: "4%",
                width: "460px",
                height: "105px",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              Supercharge your hiring process with our AI-powered platform.
              Discover tailored candidate matches, streamline your recruitment,
              and effortlessly connect with top talent. Let AI take your hiring
              strategy to the next level, making recruitment faster, smarter,
              and more efficient than ever before.
            </Typography>
          </Box>

          {/* Newsletter Subscription */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: COLORS.title_color,
                mb: 2,
                textTransform: "none",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              Subscribe to our newsletter
            </Typography>
            <Stack direction="row" spacing={1}>
              <TextField
                placeholder="Enter your email"
                variant="outlined"
                size="small"
                sx={{
                  width: "300px",
                  height: "50px",
                  // borderColor:'#fff',
                  // "& .MuiOutlinedInput-root": {
                  //   borderColor:'#fff',
                  //   backgroundColor: "white",
                  //   borderRadius: "30px",
                  //   boxShadow: "0px 4px 40px #0000001A",
                  //   padding: "2%",
                  // },
                  "& .MuiOutlinedInput-root": {
                    height: 56,
                    borderRadius: "30px",
                    fontFamily: "Poppins",
                    borderColor: "#00000033",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 40px #0000001A",

                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                      borderWidth: "2px",
                      boxShadow: "0px 0px 5px 0.4px #0085FF",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                      borderWidth: "2px",
                      boxShadow: "0px 0px 5px 0.4px #0085FF",
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  // border: '1px solid',
                  borderRadius: "30px",
                  backgroundColor: COLORS.button_color,
                  textTransform: "none",
                  px: 3,
                  width: "100.44px",
                  height: "50.79px",
                  // boxShadow: "0 4px 8px rgba(0, 0, 2, 0.2)",
                }}
              >
                Send
              </Button>
            </Stack>
          </Box>
        </Box>

        <Divider
          sx={{
            my: 3,
            mt: "10%",
            borderColor: COLORS.button_color,
            borderWidth: "0.5px",
            width: "100%",
          }}
        />

        {/* Bottom Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "center", md: "center" },
            gap: { xs: 3, md: 0 },
          }}
        >
          {/* Copyright */}
          <Typography
            variant="body2"
            color={COLORS.button_color}
            sx={{
              weight: 400,
              fontSize: "15px",
              fontFamily: "Poppins",
            }}
          >
            © 2024 SMARTHIRE
          </Typography>

          {/* Navigation Links */}
          <Stack
            direction="row"
            spacing={4}
            sx={{
              order: { xs: 2, md: 1 },
            }}
          >
            {["Offers", "About", "Contact us", "Privacy"].map((text) => (
              <Link
                key={text}
                href="/home"
                underline="none"
                color="text.secondary"
                sx={{
                  color: COLORS.button_color,
                  // width:'416px',

                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                {text}
              </Link>
            ))}
          </Stack>

          {/* Social Media Icons */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              order: { xs: 3, md: 2 },
            }}
          >
            {[
              { icon: <FacebookIcon />, label: "Facebook" },
              { icon: <InstagramIcon />, label: "Instagram" },
              { icon: <LinkedInIcon />, label: "LinkedIn" },
            ].map((social) => (
              <IconButton
                key={social.label}
                color="primary"
                aria-label={social.label}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "primary.dark",
                  },
                }}
              >
                {social.icon}
              </IconButton>
            ))}
          </Stack>
        </Box>
      </Container>
      <JobCountBanner>
        {/* <Box
          sx={{
            // height: "0.00001px",
          }}
        ></Box> */}
        {/* <Divider/> */}
      </JobCountBanner>
    </Box>
  );
};

export default Footer;
