import React, { useState, useCallback } from 'react';
import { Box, Typography, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { AddAPhoto as AddAPhotoIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { COLORS } from 'colors/color';

const UploadCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error'
})(({ theme, error }) => ({
  width: 200,
  height: 200,
  // borderRadius: '50%',
  border: `2px dashed ${error ? 'red' : COLORS.signIn}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: COLORS.Button_OnHold,
  },
}));

const PreviewImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const RemoveButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
});

const ImageUploadButton = ({ onImageUpload, error, helperText }) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const preset_key = "tpjsjr7k";
  const cloud_name = "dwckvxlca";

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    setUploadError('');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      );
      const imageUrl = res.data.secure_url;
      setSelectedImage(imageUrl);
      onImageUpload(imageUrl);
    } catch (err) {
      console.error(err);
      setUploadError('Upload failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      uploadToCloudinary(file);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      uploadToCloudinary(file);
    }
  }, []);

  const handleRemove = useCallback(() => {
    setSelectedImage('');
    onImageUpload('');
  }, [onImageUpload]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload"
        type="file"
        onChange={handleFile}
      />
      <label htmlFor="image-upload">
        <UploadCircle
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          error={error && !selectedImage}
        >
          {loading ? (
            <CircularProgress />
          ) : selectedImage ? (
            <>
              <PreviewImage src={selectedImage} alt="Selected" />
              <RemoveButton onClick={handleRemove} size="small">
                <CloseIcon />
              </RemoveButton>
            </>
          ) : (
            <>
              <AddAPhotoIcon style={{ fontSize: 40, color: error ? 'red' : COLORS.signIn, marginBottom: 10 }} />
              <Typography variant="body2" style={{ color: error ? 'red' : COLORS.signIn, textAlign: 'center' }}>
                Click or drag<br />to upload image
              </Typography>
            </>
          )}
        </UploadCircle>
      </label>
      {(uploadError || (error && helperText)) && (
        <Typography variant="caption" color="error" style={{ marginTop: 10 }}>
          {uploadError || helperText}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploadButton;