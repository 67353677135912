import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  Avatar,
  IconButton,
  CircularProgress,
  Snackbar,
  Container,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ButtonAppBar from "components/Navbars/navBar";
import wavesImage from "../../assets/img/waves.jpg";
import Footer from "./Footer";

const ProfileCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0 4px 50px #0000001A",
  width: "80%", // Change from fixed width to percentage
  margin: "0 auto", // Center the card horizontally
  maxWidth: "1200px", // Add maximum width for larger screens

}));

const ProfileImageContainer = styled(Box)({
  position: "relative",
  // marginBottom: "2rem",
  // pt:'30%',
  display: "flex",
  alignItems: "center",
  // gap: "2rem",
});

const StyledAvatar = styled(Avatar)({
  width: "200px",
  height: "200px",
  border: "4px solid white",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
  "& .MuiAvatar-img": {
    objectFit: "cover",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    // padding: '1px' // Add some padding to ensure no cropping
  },
});

const UpdateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#fff",
  boxShadow: "0px 0px 13px 4px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#303f9f",
    color: "#fff",
  },
}));
// Create styled Select component
const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  "& .MuiOutlinedInput-root": {
    height: 56,
    borderRadius: "10px",
    fontFamily: "Poppins",
    borderColor: "#00000033",
    backgroundColor: "#fff",
    ...(isediting === "true" && {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e0e0e0",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
    }),
    ...(isediting === "false" && {
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    }),
  },
}));

// Create styled Select component with matching styles
const StyledSelect = styled(Select)(({ theme, isediting }) => ({
  height: 56,
  fontFamily: "Poppins",
  borderRadius: "10px",
  ...(isediting === "true" && {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e0e0e0",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "2px",
      boxShadow: "0px 0px 5px 0.4px #0085FF",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "2px",
      boxShadow: "0px 0px 5px 0.4px #0085FF",
    },
  }),
  ...(isediting === "false" && {
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00000033",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00000033",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  }),
  "& .MuiSelect-select": {
    padding: "16.5px 14px",
  },
}));

// Create styled DatePicker component with matching styles
const StyledDatePicker = styled(DatePicker)(({ theme, isediting }) => ({
  "& .MuiOutlinedInput-root": {
    height: 56,
    borderRadius: "10px",
    fontFamily: "Poppins",
    ...(isediting === "true" && {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e0e0e0",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
    }),
    ...(isediting === "false" && {
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    }),
  },
}));

const BaseTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  marginBottom: "4px",
}));

// Typography variants based on editing state
const EditingTypography = styled(BaseTypography)(() => ({
  color: "#00000066",
  opacity: "80%",
}));

const NonEditingTypography = styled(BaseTypography)(() => ({
  color: "#00000066",
  opacity: "60%",
}));

// TextField styles based on editing state
// const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
//   '& .MuiOutlinedInput-root': {
//     height: 56,
//     borderRadius: '10px',
//     fontFamily: 'Poppins',
//     ...(isediting === 'true' && {
//       '&:hover .MuiOutlinedInput-notchedOutline': {
//         // borderColor: 'transparent',
//         borderWidth: '2px',
//         boxShadow: '0px 0px 5px 0.4px #0085FF'
//       },
//       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//         // borderColor: 'transparent',
//         borderWidth: '2px',
//         boxShadow: '0px 0px 5px 0.4px #0085FF'
//       }
//     }),
//     ...(isediting === 'false' && {
//       '& .MuiOutlinedInput-notchedOutline': {
//         // borderColor: 'transparent'
//       },
//       '&:hover .MuiOutlinedInput-notchedOutline': {
//         // borderColor: 'transparent'
//       },
//       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//         borderColor: 'transparent'
//       }
//     })
//   }
// }));
const StyledTextArea = styled(TextField)(({ theme, isediting }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    backgroundColor: isediting === "false" ? "#f5f5f5" : "#fff",
    ...(isediting === "true" && {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e0e0e0",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "2px",
        boxShadow: "0px 0px 5px 0.4px #0085FF",
      },
    }),
    ...(isediting === "false" && {
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00000033",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    }),
    "&.Mui-disabled": {
      backgroundColor: "#fff",
    },
  },
  "& .MuiInputBase-inputMultiline": {
    padding: "16px",
    lineHeight: "1.5",
    fontSize: "1rem",
    color: "#333",
  },
}));
const StyledTypography = styled(Typography)(() => ({
  fontFamily: "Poppins",
  color: "#00000066",
  opacity: "80%",
  fontSize: "14px",
}));
const BioTypography = styled(Typography)(()=>({
  fontFamily: "Poppins",
  color: "#000000CC",
  opacity: "80%",
  fontSize: "20px",
  fontWeight:'600px',
  padding:'16px'
}));
export default function Profile() {
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
    dateOfBirth: null,
    familyStatus: "",
    academicDegree: "",
    fieldOfStudy: "",
    lastPostOccupied: "",
    linkedInProfile: "",
    image: "/api/placeholder/120/120",
    biography: "",
  });
  const [isEditingBio, setIsEditingBio] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  useEffect(() => {
    loadProfile();
  }, []);
  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  useEffect(() => {
    console.log("formdata:", formData);
  }, [formData]);
  const loadProfile = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}candidates/${getUserId()}/find/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 401) {
        window.location.href = "/login";
        return;
      }

      const data = await response.json();
      console.log("Raw API response:", data);
      const dateOfBirth = data.date_of_birth ? dayjs(data.date_of_birth) : null;

      // Check if data has the required fields before setting state
      setFormData({
        company: data.company || "",
        email: data.email || "",
        firstName: data.first_name || "",
        lastName: data.last_name || "",
        address: data.address || "",
        city: data.city || "",
        country: data.country || "",
        postalCode: data.postal_code || "",
        dateOfBirth: dateOfBirth,
        familyStatus: data.family_status || "",
        academicDegree: data.academic_degree || "",
        fieldOfStudy: data.field_of_study || "",
        lastPostOccupied: data.last_post_occupied || "",
        linkedInProfile: data.linkedin_profile || "",
        image: data.image || "/api/placeholder/120/120",
        biography: data.biography || "",
      });
    } catch (error) {
      console.error("Error loading profile:", error);
      showNotification("Error loading profile data", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_PRESET_KEY);

    try {
      setLoading(true);
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setFormData((prev) => ({ ...prev, image: data.secure_url }));
      showNotification("Profile image updated successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      showNotification("Error uploading image", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleDateChange = (newValue) => {
    const validatedDate = newValue && dayjs.isDayjs(newValue) ? newValue : null;

    setFormData((prev) => ({
      ...prev,
      dateOfBirth: validatedDate,
    }));
  };
  const handleSubmit = async (e, type = "profile") => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}candidates/${getUserId()}/update/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            company: formData.company || null,
            email: formData.email || null,
            first_name: formData.firstName || null,
            last_name: formData.lastName || null,
            address: formData.address || null,
            city: formData.city || null,
            country: formData.country || null,
            postal_code: formData.postalCode || null,
            date_of_birth: formData.dateOfBirth
              ? formData.dateOfBirth.format("YYYY-MM-DD")
              : null,
            family_status: formData.familyStatus || null,
            academic_degree: formData.academicDegree || null,
            field_of_study: formData.fieldOfStudy || null,
            last_post_occupied: formData.lastPostOccupied || null,
            linkedin_profile: formData.linkedInProfile || null,
            image: formData.image || null,
            biography: formData.biography || null,
          }),
        }
      );

      if (response.ok) {
        // Handle different success cases based on type
        if (type === "biography") {
          showNotification("Biography updated successfully");
          setIsEditingBio(false);
        } else {
          showNotification("Profile updated successfully");
          setIsEditing(false);
        }
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      showNotification("Error updating profile", "error");
    } finally {
      setLoading(false);
    }
  };

  const getUserId = () => {
    const token = localStorage.getItem("accessToken");
    if (!token) return null;

    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const payload = JSON.parse(atob(base64));
      return payload.user_id;
    } catch (error) {
      console.error("Error parsing token:", error);
      return null;
    }
  };

  const showNotification = (message, severity = "success") => {
    setAlertMessage(message);
    setSeverity(severity);
    showSnackbar(message);
  };
  const handleBioEdit = () => {
    setIsEditingBio(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = () => setIsEditing(true);

  const handleImageClick = () => fileInputRef.current.click();

  return (
    <>
      <ButtonAppBar isAuth={true} hideWhileScrolling={90} />
      {/* <Container maxWidth="lg"> */}

      <Box sx={{ position: "relative", width: "100%" }}>
        {/* Background Container */}
        <Box
          sx={{
            position: "relative",
            minHeight: "440px",
            overflow: "hidden",
            backgroundImage: `url(${wavesImage})`,
          }}
        ></Box>

        {/* Content Container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            position: "relative",
            marginTop: "-100px",
          }}
        >
          {/* Profile Info Container */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginTop: "25px",
              width: "80%", // Match ProfileCard width
              maxWidth: "1200px", // Match ProfileCard maxWidth
              px: { xs: "16px", md: "1%" }, // Responsive padding
              pb: "2%",
            }}
          >
            <Box position="relative">
              <StyledAvatar
                src={formData.image}
                alt={formData.firstName || "Profile"}
                variant="circular"
              />

              {isEditing && (
                <IconButton
                  onClick={handleImageClick}
                  sx={{
                    position: "absolute",
                    bottom: 12,
                    right: 15,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(4px)",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <CameraAltIcon sx={{ fontSize: 20 }} />
                </IconButton>
              )}
              <input
                type="file"
                ref={fileInputRef}
                hidden
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Box>

            {/* Text Content */}
            <ProfileImageContainer>
              <Box sx={{ pt: 10 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: "#1a237e",
                    fontSize: "30px",
                    fontFamily: "Poppins",
                  }}
                >
                  {formData.firstName} {formData.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    color: "#00000066",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  {formData.company}
                </Typography>
              </Box>
            </ProfileImageContainer>
          </Box>
        </Box>
      </Box>
      <ProfileCard elevation={0}>
        {/* Profile Header */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-between",
            mb: 4,
            // mx:5
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "22px",
              color: "#1a237e",
              fontWeight: 700,
              textTransform: "none",
              // mb: 3,
            }}
          >
            Personal information
          </Typography>

          <Button
            variant="outlined"
            // startIcon={}
            onClick={handleEdit}
            disabled={isEditing}
            sx={{
              py: 1,
              border: "none",
              borderRadius: "10px",
              textTransform: "none",
              borderColor: "#e0e0e0",
              color: "#004F98",
              boxShadow: "0px 4px 50px 0px #0000001A",
              "&:hover": {
                border: "none",

                // borderColor: "#1a237e",
                backgroundColor: "rgba(26, 35, 126, 0.04)",
              },
            }}
          >
            Edit <DriveFileRenameOutlineIcon />
          </Button>
        </Box>

        {/* Form Fields */}
        <Grid
          container
          columnSpacing={5}
          rowSpacing={6}
          sx={{
            pl: 5,
            pr: 5,
          }}
        >
          {Object.entries(formData)
            .filter(
              ([key]) =>
                ![
                  "image",
                  "academicDegree",
                  "fieldOfStudy",
                  "lastPostOccupied",
                  "linkedInProfile",
                  "dateOfBirth",
                  "postalCode",
                  "familyStatus",
                  "biography",
                ].includes(key)
            )
            .map(([key, value]) => (
              <Grid item xs={12} sm={4} key={key}>
                <FormControl fullWidth>
                  <StyledTypography
                    sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
                  >
                    {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                      key.replace(/_/g, " ").slice(1)}
                  </StyledTypography>
                  <StyledTextField
                    fullWidth
                    name={key}
                    value={value}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    isediting={isEditing.toString()}
                    // label={key
                    //   .replace(/_/g, " ")
                    //   .split(" ")
                    //   .map(
                    //     (word) => word.charAt(0).toUpperCase() + word.slice(1)
                    //   )
                    //   .join(" ")}
                  />
                </FormControl>
              </Grid>
            ))}

          {/* New Fields */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              {/* <InputLabel id="academic-degree-label">
                Academic Degree
              </InputLabel> */}
              <StyledTypography
                sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
              >
                Academic Degree
              </StyledTypography>
              <StyledSelect
                disabled={!isEditing}
                labelId="academic-degree-label"
                id="academicDegree"
                name="academicDegree"
                value={formData.academicDegree}
                onChange={handleInputChange}
                // label="Academic Degree"
                isediting={isEditing.toString()}
              >
                <MenuItem value="baccalaureat">Baccalauréat</MenuItem>
                <MenuItem value="deug">DEUG</MenuItem>
                <MenuItem value="licence">Licence</MenuItem>
                <MenuItem value="master">Master</MenuItem>
                <MenuItem value="doctorat">Doctorat</MenuItem>
                <MenuItem value="ingenieur">Diplôme d'Ingénieur</MenuItem>
                <MenuItem value="dut">DUT</MenuItem>
                <MenuItem value="bts">BTS</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              {/* <InputLabel id="field-of-study-label">Field of Study</InputLabel> */}
              <StyledTypography
                sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
              >
                Field of Study
              </StyledTypography>
              <StyledSelect
                disabled={!isEditing}
                labelId="field-of-study-label"
                id="fieldOfStudy"
                name="fieldOfStudy"
                value={formData.fieldOfStudy}
                onChange={handleInputChange}
                // label="Field of Study"
                isediting={isEditing.toString()}
              >
                <MenuItem value="computer-science">Computer Science</MenuItem>
                <MenuItem value="business-administration">
                  Business Administration
                </MenuItem>
                <MenuItem value="mechanical-engineering">
                  Mechanical Engineering
                </MenuItem>
                <MenuItem value="electrical-engineering">
                  Electrical Engineering
                </MenuItem>
                <MenuItem value="civil-engineering">Civil Engineering</MenuItem>
                <MenuItem value="medicine">Medicine</MenuItem>
                <MenuItem value="law">Law</MenuItem>
                <MenuItem value="psychology">Psychology</MenuItem>
                <MenuItem value="economics">Economics</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTypography
              sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
            >
              Last Post Occupied
            </StyledTypography>
            <StyledTextField
              disabled={!isEditing}
              fullWidth
              required
              id="lastPostOccupied"
              name="lastPostOccupied"
              // label="Last Post Occupied"
              value={formData.lastPostOccupied}
              onChange={handleInputChange}
              isediting={isEditing.toString()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTypography
              sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
            >
              LinkedIn Profile
            </StyledTypography>
            <StyledTextField
              disabled={!isEditing}
              fullWidth
              id="linkedInProfile"
              name="linkedInProfile"
              // label="LinkedIn Profile"
              value={formData.linkedInProfile}
              onChange={handleInputChange}
              isediting={isEditing.toString()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTypography
              sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
            >
              Date of Birth
            </StyledTypography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                disabled={!isEditing}
                fullWidth
                required
                // label="Date of Birth"
                value={formData.dateOfBirth}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                isediting={isEditing.toString()}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTypography
              sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
            >
              Family Status
            </StyledTypography>
            <StyledTextField
              disabled={!isEditing}
              fullWidth
              id="familyStatus"
              name="familyStatus"
              // label="Family Status"
              value={formData.familyStatus}
              onChange={handleInputChange}
              isediting={isEditing.toString()}
            />
          </Grid>
        </Grid>

        {/* Update Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <UpdateButton
            variant="contained"
            onClick={(e) => handleSubmit(e, "profile")}
            disabled={!isEditing || loading}
            sx={{
              bgcolor: "#fff",
              color: "#004F98",
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "Poppins",
              px: 4,
              px: 2,
              py: 1,
              fontWeight: "500px",
              "&:hover": {
                bgcolor: "#0085FF",
              },
              "&.Mui-disabled": {
                bgcolor: "#e0e0e0",
              },
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                <span>Updating...</span>
              </Box>
            ) : (
              "Update Profile"
            )}
          </UpdateButton>
        </Box>
      </ProfileCard>
      <Box sx={{ pt: "4%" }}></Box>
      <ProfileCard elevation={0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-between",
            mb: 4,
          }}
        >
          <StyledTypography
            variant="h6"
            sx={{
              fontSize: "22px",
              color: "#1a237e",
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Profile biography
          </StyledTypography>

          <Button
            variant="outlined"
            onClick={handleBioEdit}
            disabled={isEditingBio}
            sx={{
              py: 1,
              border: "none",
              borderRadius: "10px",
              textTransform: "none",
              borderColor: "#e0e0e0",
              color: "#004F98",
              boxShadow: "0px 4px 50px 0px #0000001A",
              "&:hover": {
                border: "none",

                // borderColor: "#1a237e",
                backgroundColor: "rgba(26, 35, 126, 0.04)",
              },
            }}
          >
            Edit <DriveFileRenameOutlineIcon />
          </Button>
        </Box>
        {isEditingBio ? (
          <StyledTextArea
            fullWidth
            multiline
            rows={6}
            name="biography"
            value={formData.biography}
            onChange={handleInputChange}
            disabled={!isEditingBio}
            placeholder="Write your professional biography here..."
            variant="outlined"
            isediting={isEditingBio.toString()}
          />
        ) : (
          <BioTypography >
            {formData.biography}
          </BioTypography>
        )}

        {isEditingBio && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <UpdateButton
              variant="contained"
              onClick={(e) => handleSubmit(e, "biography")}
              disabled={loading}
              sx={{
                bgcolor: "#fff",
                color: "#004F98",
                borderRadius: "10px",
                textTransform: "none",
                fontFamily: "Poppins",
                px: 4,
                px: 2,
                py: 1,
                fontWeight: "500px",
                "&:hover": {
                  bgcolor: "#0085FF",
                },
                "&.Mui-disabled": {
                  bgcolor: "#e0e0e0",
                },
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  <span>Updating...</span>
                </Box>
              ) : (
                "Save Biography"
              )}
            </UpdateButton>
          </Box>
        )}
      </ProfileCard>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(3px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)",
          },
        }}
        message={snackbar.message}
      ></Snackbar>
      {/* </Container> */}
      <Footer />
    </>
  );
}
