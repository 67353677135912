import React, { useState, useCallback } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Camera } from 'lucide-react';
import axios from 'axios';

const ImageUploadButton = ({ onImageUpload, error }) => {
  const [loading, setLoading] = useState(false);

  const preset_key = "tpjsjr7k";
  const cloud_name = "dwckvxlca";

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      );
      const imageUrl = res.data.secure_url;
      onImageUpload(imageUrl);
    } catch (err) {
      console.error(err);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  const handleFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      uploadToCloudinary(file);
    }
  }, []);

  return (
    <Box>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload"
        type="file"
        onChange={handleFile}
      />
      <label htmlFor="image-upload">
        <IconButton
          component="span"
          sx={{
            backgroundColor: error ? 'rgba(255, 0, 0, 0.1)' : 'white',
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

            '&:hover': {
              backgroundColor: error ? 'rgba(255, 0, 0, 0.2)' : 'white',
              boxShadow: "0 4px 8px rgba(0, 0, 2, 0.2)",

            },
            padding: '6px',
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Camera size={18} color={error ? 'red' : '#020060'} />
          )}
        </IconButton>
      </label>
    </Box>
  );
};

export default ImageUploadButton;