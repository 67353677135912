import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

const LoadingSkeleton = () => {
  return (
    <>
      {[...Array(3)].map((_, outerIndex) => (
        <Box
          key={outerIndex}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 4,
            padding: 4,
            backgroundColor: "#f9fafc",
            borderRadius: "12px",
            boxShadow: "0 0px 40px 0 #0000001A",
            marginBottom: 4  // Added margin between repeated boxes
          }}
        >
          {[...Array(1)].map((_, index) => (
            <Stack
              key={index}
              spacing={3}
              sx={{ width: "100%", maxWidth: 500 }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  height: 250,
                  borderRadius: "20px",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.08)",
                  backgroundColor: "#e0e0e0",
                }}
              />
              <Skeleton
                variant="text"
                width="80%"
                animation="wave"
                sx={{
                  fontSize: "1.5rem",
                  backgroundColor: "#d0d0d0",
                  borderRadius: "10px",
                }}
              />
              <Skeleton
                variant="text"
                width="60%"
                animation="wave"
                sx={{
                  fontSize: "1rem",
                  backgroundColor: "#d0d0d0",
                  borderRadius: "10px",
                }}
              />
            </Stack>
          ))}
        </Box>
      ))}
    </>
  );
};

export default LoadingSkeleton;