import SearchBar from "./SearchBar";
import MyCard from "./Card";
import "./home.css";
import { Box, CircularProgress, Skeleton, Stack } from "@mui/material";
import MyCardDetails from "./CardDetails";
import ButtonAppBar from "../../components/Navbars/navBar";
import LoginForDialog from "views/Auth/LoginForDialog";
import SignInForDialog from "views/Auth/SignInForDialog"; // Make sure the path is correct
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import App from "views/Chat/Chat";
import useSignalingStore from "../signalingStore";
import ZoomableWrapper from "views/ZoomableWraper";
import CustomPagination from "./pagination";
import BasicSkeleton from "components/skeleton/skeleton";
import LoadingSkeleton from "components/skeleton/LoadingSkeleton";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

  const [ChatComponent, setChatComponent] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [lastPost, setLastPost] = useState(null);
  const [firstJob, setFirstJob] = useState(null);
  const [isShrinked, setIsShrinked] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [incomingPost, setIncomingPost] = useState(null);

  const closeLoginPopUp = () => {
    setOpenLoginDialog(false);
  };

  const closeSignInPopUp = () => {
    setOpenSignInDialog(false);
  };
  useEffect(() => {
    if (location.state && location.state.post) {
      console.log("Received post data:", location.state.post);
      setIncomingPost(location.state.post);
      setSelectedJob(location.state.post);
      // console.log('location data :',location.state.post)
    }
  }, [location]);

  const Load = async (pageNumber = 1) => {
    setIsLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Reduced timeout for better UX
      const response = await fetch(`${apiUrl}posts/list/?page=${pageNumber}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();

      // Always set as new page instead of concatenating
      setAllJobs(json.results);
      setLastPost(json.results[0]);
      setHasMore(!!json.next);
      setTotalCount(json.count || 0);

      // Reset selected job when loading new page
      if (pageNumber > 1) {
        setSelectedJob(json.results[0]);
      } else {
        // On first page load, show the first job if no job is selected
        setSelectedJob((prev) => prev || json.results[0]);
      }

      // Scroll to top of the list
      const cardContainer = document.querySelector(".mycard-scroll");
      if (cardContainer) {
        cardContainer.scrollTop = 0;
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [rowsPerPage] = useState(10); // Or whatever number your API returns per page
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1); // Add 1 because API uses 1-based indexing
    Load(newPage + 1);

    // Scroll both the window and the card container to top
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });

    // Scroll the card container
    const cardContainer = document.querySelector(".mycard-scroll");
    if (cardContainer) {
      cardContainer.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    }
  };

  // Use this effect to load the first page when the component mounts
  useEffect(() => {
    Load(1);
  }, []);
  // Use this effect to handle infinite scrolling
  // useEffect(() => {
  //   if (!isLoading && hasMore) {
  //     const handleScroll = () => {
  //       if (
  //         window.innerHeight + window.scrollY >=
  //           document.body.offsetHeight - 100 &&
  //         !isLoading
  //       ) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     };

  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }
  // }, [isLoading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      Load(page);
    }
  }, [page]);

  // useEffect(() => {
  //   if (ChatComponent === false) {
  //     //console.log("ChatComponent", ChatComponent);
  //     const handleScroll = () => {
  //       if (
  //         window.innerHeight + window.scrollY >=
  //           document.body.offsetHeight - 2 &&
  //         hasMore &&
  //         !isLoading
  //       ) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     };

  //     window.addEventListener("scroll", handleScroll);

  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [hasMore, isLoading]);

  const handleSearchResults = (results, message) => {
    setSearchResults(results);
    setNoResultsMessage(message);
    // Reset pagination when searching
    setPage(1);
    if (results.length > 0) {
      setSelectedJob(results[0].post);
    }
  };

  const jobsToDisplay =
    searchResults.length > 0
      ? searchResults.map((result) => result.post)
      : allJobs;

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  // useEffect(() => {
  //   //console.log("is Auth ?: ", isAuth);
  // }, [isAuth]);

  const handleCardClick = (job) => {
    console.log("clicked");
    if (isSmallScreen) {
      navigate(`/viewjob/${job.id}`, { state: { job } });
    } else {
      setSelectedJob(job);
      console.log("job", job);
    }
  };

  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  useEffect(() => {
    if (ChatComponent == true) {
      const handleScroll = () => {
        //console.log("scrolling");
        const footer = document.querySelector("footer");
        const rect = footer.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsFooterVisible(isVisible);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const senderId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const senderId = decodedToken.user_id;
      return senderId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      //console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    //console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const connectToSocket = (userId) => {
    const signalingServerUrl = `${apiUrlsoket}ws/notif/${userId}/`;
    const newSignalingServer = new WebSocket(signalingServerUrl);
    // Save the signalingServer to the global state
    useSignalingStore.getState().setSignalingServer(newSignalingServer);

    return newSignalingServer;
  };

  const { signalingServer } = useSignalingStore();
  useEffect(() => {
    console.log("shrinked Home", isShrinked);
  }, [isShrinked]);
  useEffect(() => {
    if (!signalingServer) {
      // If no signaling server, connect
      const userId = getUserIdFromAccessToken(); // Implement this function
      if (userId) {
        connectToSocket(userId);
      }
    }
  }, [signalingServer]);
  return (
    <>
      {ChatComponent ? (
        <>
          <ZoomableWrapper zoomLevel={0.8}>
            <ButtonAppBar
              isAuth={isAuth}
              onLoginClick={handleLoginClick}
              onSignInClick={handleSignInClick}
              setChatComponent={setChatComponent}
            />
            <App candidate_id={senderId} />
          </ZoomableWrapper>
        </>
      ) : (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
            hideWhileScrolling={isShrinked ? 0 : 500}
            isShrinked={isShrinked}
          />
          <SearchBar props={handleSearchResults} />
          <div className="container" style={{ paddingTop: "4%" }}>
            <div className="row justify-content-center home">
              <div className="col-lg-5 col-md-4 col-12 myCard">
                <div className="mycard-scroll">
                  {noResultsMessage ? (
                    <div className="no-results-message">
                      <p className="no-results-title">
                        {noResultsMessage.split(".")[0]}
                      </p>
                      <p className="no-results-subtitle">
                        {noResultsMessage.split(".")[1]}
                      </p>
                    </div>
                  ) : (
                    jobsToDisplay.map((job, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleCardClick(job);
                        }}
                      >
                        <MyCard
                          title={job.title}
                          deadline={job.deadline}
                          description={job.description}
                          image={job.image}
                          post_id={job.id}
                          recruiter={job.recruiter}
                          localisation={job.localisation}
                        />
                      </div>
                    ))
                  )}
                  {isLoading && <LoadingSkeleton />}

                  {!noResultsMessage && (
                    <div
                      className="pagination-container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {isLoading ? (
                        ""
                      ) : (
                        <CustomPagination
                          page={page - 1} // Subtract 1 because component uses 0-based indexing
                          rowsPerPage={rowsPerPage}
                          count={totalCount}
                          handleChangePage={handlePageChange}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!isSmallScreen && (
                <div className="col-lg-6 col-md-8 col-10">
                  <div className="mycard-scroll fixed-component rounded-corners">
                    {" "}
                    {selectedJob ? (
                      <MyCardDetails
                        title={selectedJob.title}
                        description={selectedJob.description}
                        requirements={
                          <span style={{ color: "black", fontSize: "12px" }}>
                            {selectedJob.requirements.join(", ")}
                          </span>
                        }
                        localisation={selectedJob.localisation}
                        mode={selectedJob.mode}
                        deadline={selectedJob.deadline}
                        image={selectedJob.image}
                        id={selectedJob.id}
                        isFooterVisible={isFooterVisible}
                        has_applied={selectedJob.has_applied}
                        company={selectedJob.recruiter.company}
                        company_logo={selectedJob.company_logo}
                        setIsShrinked={setIsShrinked}
                        site_url={selectedJob.recruiter.site_url}
                      />
                    ) : !lastPost || isLoading ? (
                      // <div
                      //   style={{
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     alignItems: "center",
                      //     height: "100%",
                      //     minHeight: "400px", // Add minimum height to prevent layout shift
                      //   }}
                      // >
                      //   <CircularProgress size="3rem" />
                      // </div>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          gap: 4,
                          padding: 4,
                          backgroundColor: "#f9fafc",
                          borderRadius: "12px",
                          boxShadow: "0 0px 40px 0 #0000001A",
                        }}
                      >
                        {[...Array(1)].map((_, index) => (
                          <Stack
                            key={index}
                            spacing={3}
                            sx={{ width: "100%", maxWidth: 500 ,}}
                            

                          >
                            <Skeleton variant="circular" width={180} height={180} />
                            <Skeleton
                              variant="circular"
                              animation="wave"
                              sx={{
                                height: 250,
                                borderRadius: "20px",
                                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.08)",
                                backgroundColor: "#e0e0e0",
                              }}
                            />
                            <Skeleton
                              variant="circular"
                              width="80%"
                              animation="wave"
                              sx={{
                                fontSize: "1.5rem",
                                backgroundColor: "#d0d0d0",
                                borderRadius: "10px",
                              }}
                            />
                            <Skeleton
                              variant="circular"
                              width="60%"
                              animation="wave"
                              sx={{
                                fontSize: "1rem",
                                backgroundColor: "#d0d0d0",
                                borderRadius: "10px",
                              }}
                            />
                          </Stack>
                        ))}
                      </Box>
                    ) : (
                      <MyCardDetails
                        title={lastPost.title}
                        description={lastPost.description}
                        requirements={
                          <span style={{ color: "black", fontSize: "12px" }}>
                            {lastPost.requirements.join(", ")}
                          </span>
                        }
                        localisation={lastPost.localisation}
                        mode={lastPost.mode}
                        deadline={lastPost.deadline}
                        image={lastPost.image}
                        id={lastPost.id}
                        isFooterVisible={isFooterVisible}
                        has_applied={lastPost.has_applied}
                        company={lastPost.recruiter.company}
                        company_logo={lastPost.company_logo}
                        setIsShrinked={setIsShrinked}
                        // post_id={lastPost.id}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />

          <Dialog open={openLoginDialog} onClose={closeLoginPopUp}>
            <DialogContent>
              <LoginForDialog />
            </DialogContent>
            <DialogActions />
          </Dialog>

          <Dialog open={openSignInDialog} onClose={closeSignInPopUp}>
            <DialogContent>
              <SignInForDialog />
            </DialogContent>
            <DialogActions />
          </Dialog>
        </>
      )}
    </>
  );
}
export default Home;
