import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { keyframes } from '@mui/system';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const FriendlyErrorDisplay = ({ message, onRetry }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#ffec"
      color="#2d3436"
    >
      <Box
        sx={{
          animation: `${bounce} 2s infinite`,
          fontSize: '100px',
          marginBottom: 2,
        }}
      >
        🤪
      </Box>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#6c5ce7',
        }}
      >
        Oops! We've hit a silly snag!
      </Typography>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 3,
          textAlign: 'center',
          maxWidth: '80%',
          color: '#636e72',
        }}
      >
        {message}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 4,
          textAlign: 'center',
          fontStyle: 'italic',
          color: '#2d3436',
        }}
      >
        Don't worry, even forms need a vacation sometimes!
      </Typography>
      <Button
        variant="contained"
        onClick={onRetry}
        sx={{
          backgroundColor: '#00b894',
          color: 'white',
          borderRadius: '50px',
          padding: '10px 30px',
          fontSize: '18px',
          '&:hover': {
            backgroundColor: '#00a884',
          },
        }}
      >
        Give it another shot! 🚀
      </Button>
    </Box>
  );
};

export default FriendlyErrorDisplay;