import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import {
  Button,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Container,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingBottom: theme.spacing(2),
}));

const RecruiterCandidatesApplications = ({ recruiterId }) => {
  const [candidatesApplications, setCandidatesApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('Fetching recruiter candidates and applications');
    fetchRecruiterCandidatesApplications();
  }, [recruiterId]);

  const fetchRecruiterCandidatesApplications = async () => {
    try {
      console.log('recruiterId', recruiterId);
      setLoading(true);
      const response = await axios.get(`${apiUrl}recruiters/${recruiterId}/candidates-applications/`);
      setCandidatesApplications(response.data);
      setLoading(false);
      console.log('data : ', response.data);
    } catch (err) {
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  const generateCSV = () => {
    const csvData = candidatesApplications.flatMap(candidate => 
      candidate.applications.map(app => ({
        'Candidate ID': candidate.id,
        'First Name': candidate.first_name,
        'Last Name': candidate.last_name,
        'Email': candidate.email,
        'Username': candidate.username,
        'Role': candidate.role,
        'Gender': candidate.gender,
        'City': candidate.city || '',
        'Country': candidate.country || '',
        'Academic Degree': candidate.academic_degree || '',
        'Last Post Occupied': candidate.last_post_occupied || '',
        'LinkedIn Profile': candidate.linkedin_profile || '',
        'Date of Birth': candidate.date_of_birth || '',
        'Family Status': candidate.family_status || '',
        'Field of Study': candidate.field_of_study || '',
        'Application ID': app.id,
        'Application Step': app.step,
        'Application Status': app.status,
        'Resume Path': app.resume_path,
        'Cover Letter': app.cover_letter,
        'Application Date': new Date(app.date).toLocaleDateString(),
        'Score Matching': app.score_matching,
        'Post ID': app.post,
        'Post Title': app.post_title
      }))
    );

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'recruiter_candidates_applications.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Candidates and Applications for Recruiter's Posts
        </Typography>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={generateCSV}
          sx={{ mb: 2 }}
        >
          Generate CSV
        </Button>
        {/* {candidatesApplications.length > 0 ? (
          <List>
            {candidatesApplications.map(candidate => (
              <StyledCard key={candidate.id}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {candidate.first_name} {candidate.last_name}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography><strong>Email:</strong> {candidate.email}</Typography>
                      <Typography><strong>Username:</strong> {candidate.username}</Typography>
                      <Typography><strong>Role:</strong> {candidate.role}</Typography>
                      <Typography><strong>Gender:</strong> {candidate.gender}</Typography>
                      <Typography><strong>City:</strong> {candidate.city || 'N/A'}</Typography>
                      <Typography><strong>Country:</strong> {candidate.country || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography><strong>Academic Degree:</strong> {candidate.academic_degree || 'N/A'}</Typography>
                      <Typography><strong>Last Post Occupied:</strong> {candidate.last_post_occupied || 'N/A'}</Typography>
                      <Typography><strong>LinkedIn Profile:</strong> {candidate.linkedin_profile || 'N/A'}</Typography>
                      <Typography><strong>Date of Birth:</strong> {candidate.date_of_birth || 'N/A'}</Typography>
                      <Typography><strong>Family Status:</strong> {candidate.family_status || 'N/A'}</Typography>
                      <Typography><strong>Field of Study:</strong> {candidate.field_of_study || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Applications:</Typography>
                  {candidate.applications.length > 0 ? (
                    <List>
                      {candidate.applications.map(app => (
                        <StyledListItem key={app.id}>
                          <ListItemText
                            primary={`Application ID: ${app.id}`}
                            secondary={
                              <>
                                <Typography component="span" variant="body2">
                                  Post: {app.post_title} (ID: {app.post})
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2">
                                  Step: {app.step}
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2">
                                  Status: {app.status}
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2">
                                  Date: {new Date(app.date).toLocaleDateString()}
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2">
                                  Score Matching: {app.score_matching}
                                </Typography>
                              </>
                            }
                          />
                          <Chip
                            label={app.status}
                            color={app.status === 'Accepted' ? 'success' : app.status === 'Rejected' ? 'error' : 'default'}
                            sx={{ mt: 1 }}
                          />
                        </StyledListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>No applications for this candidate.</Typography>
                  )}
                </CardContent>
              </StyledCard>
            ))}
          </List>
        ) : (
          <Typography>No candidates or applications found for this recruiter's posts.</Typography>
        )} */}
      </Box>
    </Container>
  );
};

export default RecruiterCandidatesApplications;