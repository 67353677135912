import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ImageUploadButton from "../ImageUploadButton";
import MyArrayInput from "../MyArrayInput";
import InputMask from "react-input-mask";
import CardPost from "./CardPost";
import PostDetails from "./PostDetails";
import { COLORS } from "colors/color";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import { Switch, FormControlLabel } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Chips } from "primereact/chips";
import { Row, Col, Container } from "reactstrap";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import ChipInput from "components/Chips/chip";
import styled from "styled-components";
import GlobalCityAutocomplete from "components/CitiesPicker/CitiesPicker";
import SalaryInputWithCurrency from "components/Inputs/SalaryInputWithCurrency";
import SkeletonLoaderDemo from "components/Loading/LoadingDemo";
import AnimatedLoading from "components/Loading/aminateLoading";
import ArchiveSwitch from "components/archiveSwich/archiveSwich";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlFront = process.env.REACT_APP_API_FRONT_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

const WelcomeSection = styled(Paper)(({ theme }) => ({
  padding: "1rem",
  marginBottom: "2rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "right",
  backgroundColor: "#fff",
  borderRadius: "16px !important",
  boxShadow: "0 4px 11px rgba(0.1, 0.1, 0.1, 0.2)",
  "&&": {
    borderRadius: "16px",
  },
}));
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "6px",
    padding: "16px",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  },
});

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
});
const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: COLORS.grey,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: COLORS.signIn,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: COLORS.signIn,
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
  },
}));
const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: "16px",
  "&.MuiChip-outlined": {
    borderColor: COLORS.signIn,
  },
}));
const StyledUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const StyledPreviewImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "200px",
  objectFit: "contain",
});

const PostPage = () => {
  const [postImage, setPostImage] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postRequirement, setPostRequirement] = useState([]);
  const [postDeadline, setPostDeadline] = React.useState(null);
  const [posts, setPosts] = useState([]);
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const theme = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();
  const [errors, setErrors] = useState({});
  const [offerType, setOfferType] = useState("");
  const [location, setLocation] = useState(null);
  const [numPeople, setNumPeople] = useState(null);
  const [salary, setSalary] = useState(null);
  const [isCheckedScr, setIsCheckedScr] = useState(false);
  const [workingMode, setWorkingMode] = useState("");
  const [showAllPosts, setShowAllPosts] = useState(false);
  const [currency, setCurrency] = useState("MAD");
  const client_id = "86dpwd87p8nanb";
  const secret_id = process.env.LINKEDIN_CLIENT_SECRET;
  const [loading, setLoading] = useState(true);
  const [postId, setPostId] = useState();

  const handleDateChange = (newValue) => {
    const dateOnly = newValue ? dayjs(newValue).format("YYYY-MM-DD") : null;
    setPostDeadline(dateOnly);
    console.log(postDeadline);
  };
  const convertOfferType = (type) => {
    return type === "internship" ? "i" : "j";
  };
  const validateFirstDialog = () => {
    let tempErrors = {};
    tempErrors.postTitle = postTitle ? "" : "Post title is required";
    tempErrors.postDescription = postDescription
      ? ""
      : "Post description is required";
    tempErrors.postRequirement =
      postRequirement.length > 0 ? "" : "At least one requirement is required";
    tempErrors.postDeadline = postDeadline ? "" : "Deadline is required";
    tempErrors.offerType = offerType ? "" : "Offer type is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const validateSecondDialog = () => {
    let tempErrors = {};
    tempErrors.location = location ? "" : "Location is required";
    tempErrors.workingMode = workingMode ? "" : "Working mode is required";
    tempErrors.postImage = postImage ? "" : "Image is required";

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const handleNext = () => {
    if (validateFirstDialog()) {
      console.log("test");
      setOpen(false);
      setOpen2(true);
    }
  };

  const handleSave = () => {
    if (validateSecondDialog()) {
      save();
    }
  };
  useEffect(() => {
    console.log("clientid is : ", client_id);
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  const handleLinkedInAuth = () => {
    const client_id = "86dpwd87p8nanb";
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/talent/post/PostDetails/`
    );
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirectUri}&scope=openid%20email%20profile%20w_member_social`;

    // Open LinkedIn auth in a new tab
    const newWindow = window.open(linkedInAuthUrl, "_blank");

    // Set up a listener for messages from the new window
    window.addEventListener("message", handleAuthMessage, false);

    // Function to handle the message from the new window
    function handleAuthMessage(event) {
      if (event.origin !== window.location.origin) return;

      const { code } = event.data;
      if (code) {
        console.log("Received code from LinkedIn auth:", code);
        // fetchCallbackData(code);
        window.removeEventListener("message", handleAuthMessage);
        newWindow.close();
      }
    }
  };

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const response = await axios.get(
        `${apiUrl}authentication/linkedin_callback_post/`,
        {
          params: { code: code },
        }
      );
      console.log("LinkedIn token response:", response.data);
      localStorage.setItem(
        "accessToken_LinkedIn",
        response.data.linkedIn_access_token
      );
      localStorage.setItem(
        "linkedin_person_id",
        response.data.linkedin_person_id
      );
      handleCreatePost(
        response.data.linkedIn_access_token,
        response.data.linkedin_person_id
      );
      resetForm();
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };
  useEffect(() => {
    console.log("test1");
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);
  useEffect(() => {
    Load();
    console.log("user id is :", userId);
  }, []);
  useEffect(() => {
    Load();
  }, [showAllPosts]);
  useEffect(() => {
    console.log("Loading", loading);
  }, [loading]);
  const handleCreatePost = async (token, personnalId) => {
    const formData = JSON.parse(localStorage.getItem("postFormData"));
    if (!formData) {
      console.error("Form data not found in localStorage");
      return;
    }

    const {
      postImage,
      postTitle,
      postDescription,
      postRequirement,
      postDeadline,
      location,
      numPeople,
      workingMode,
      salary,
      currency,
      offerType,
      postId,
    } = formData;

    if (
      !postTitle ||
      !postDeadline ||
      !location ||
      !postDescription ||
      !postRequirement ||
      !postId
    ) {
      console.error("Missing required fields");
      toast.error("Missing required fields");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}authentication/linkedin/post-create/`,
        {
          token: token,
          personnalId: personnalId,
          subject: postTitle,
          lien: `${apiUrlFront}viewjob/${postId}`,
          postDeadline: postDeadline,
          location: location,
          postImage: postImage,
          postDescription: postDescription,
          requirement: postRequirement,
          workMode: workingMode,
          salary: salary || null,
          numToHire: numPeople || null,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      console.log(response.data);
      toast.success("Post created and shared on LinkedIn successfully!");

      // Reset form and clear localStorage
      resetForm();
      localStorage.removeItem("linkedin_person_id");
      localStorage.removeItem("accessToken_LinkedIn");
      localStorage.removeItem("postFormData");

      if (window.opener) {
        window.opener.postMessage(
          { action: "postCreated" },
          window.location.origin
        );
        window.close();
      }
    } catch (error) {
      console.error("Error creating post:", error);
      toast.error("Failed to create post on LinkedIn");
    }
  };
  useEffect(() => {
    console.log("apiUrlFront", apiUrlFront);
    window.addEventListener("message", handlePostCreatedMessage);
    return () => {
      window.removeEventListener("message", handlePostCreatedMessage);
    };
  }, []);
  const handlePostCreatedMessage = (event) => {
    if (event.origin !== window.location.origin) return;
    if (event.data.action === "postCreated") {
      // Refresh the post list or update state as needed
      Load();
      // Show a success message to the user
      toast.success("Post created and shared on LinkedIn successfully!");
    }
  };
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  async function Load() {
    try {
      const endpoint = showAllPosts
        ? `${apiUrl}posts/listScraped/${userId}/all/`
        : `${apiUrl}posts/listScraped/${userId}/`;
      const response = await fetch(endpoint, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 401) {
        // Handle unauthorized access
        return;
      }
      setLoading(false);
      const json = await response.json();
      setPosts(json);
      console.log(json);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }
  async function save() {
    setOpen2(false);
    const formData = {
      postImage,
      postTitle,
      postDescription,
      postRequirement,
      postDeadline,
      location,
      workingMode,
      currency,
      offerType,
      // Add other necessary fields here
    };

    try {
      setLoading(true);
      console.log("Sending data to server:", {
        image: postImage,
        title: postTitle,
        description: postDescription,
        requirements: postRequirement,
        deadline: postDeadline,
        recruiter: userId,
        mode: workingMode,
        salary: salary,
        number_of_people_to_hire: numPeople,
        scraped: isCheckedScr,
        currency: currency,
        localisation: location,
        offerType: convertOfferType(offerType),
      });

      const response = await axios.post(`${apiUrl}posts/create/`, {
        image: postImage,
        title: postTitle,
        description: postDescription,
        requirements: postRequirement,
        deadline: postDeadline,
        recruiter: userId,
        mode: workingMode,
        salary: salary || null,
        number_of_people_to_hire: numPeople || null,
        scraped: isCheckedScr,
        currency: currency,
        localisation: location,
        offerType: convertOfferType(offerType),
      });

      console.log("Server response:", response.data);

      const newPostId = response.data.id;
      setPostId(newPostId);

      // Update formData with the new postId
      formData.postId = newPostId;

      if (isChecked) {
        localStorage.setItem("postFormData", JSON.stringify(formData));
        if (
          !localStorage.getItem("linkedin_person_id") ||
          !localStorage.getItem("accessToken_LinkedIn")
        ) {
          handleLinkedInAuth();
        } else {
          handleCreatePost(
            localStorage.getItem("accessToken_LinkedIn"),
            localStorage.getItem("linkedin_person_id")
          );
        }
      }

      setLoading(false);
      resetForm();
      Load();
      toast.success("Post created successfully!");
    } catch (err) {
      setLoading(false);
      console.error("Post Registration Failed:", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", err.response.data);
        console.error("Error response status:", err.response.status);
        console.error("Error response headers:", err.response.headers);
        toast.error(
          `Post Registration Failed: ${err.response.data.message || err.response.statusText
          }`
        );
      } else if (err.request) {
        // The request was made but no response was received
        console.error("Error request:", err.request);
        toast.error(
          "Post Registration Failed: No response received from server"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", err.message);
        toast.error(`Post Registration Failed: ${err.message}`);
      }
    }
  }
  const resetForm = () => {
    setPostTitle("");
    setPostDescription("");
    setPostRequirement([]);
    setOfferType("");
    setPostDeadline("");
    setPostImage("");
    setSalary("");
    setNumPeople("");
  };

  const handleImageUpload = (postImage) => {
    setPostImage(postImage);
    setErrors((prev) => ({ ...prev, postImage: "" }));
  };

  const deletePostById = async (postId) => {
    try {
      await axios.delete(`${apiUrl}posts/${postId}/delete/`);
      console.log(`Post with ID ${postId} has been deleted.`);
      Load();
    } catch (error) {
      console.error(`Error deleting post with ID ${postId}: ${error.message}`);
    }
  };

  const updatePost = async (updatedData) => {
    try {
      const response = await axios.put(
        `${apiUrl}posts/${updatedData.postid}/update/`,
        updatedData
      );
      if (response.status === 200) {
        console.log(`Post with ID has been updated.`);
        Load();
      } else {
        console.error(`Error updating post with ID : ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error updating post: ${error.message}`);
    }
  };

  const handleSelectPost = (post) => {
    setSelectedPost(post);
  };

  const handleBackToList = () => {
    setSelectedPost(null);
  };
  // const handleSalaryChange = (e) => {
  //   const value = e.target.value;
  //   setSalary(value ? parseFloat(value) : null);
  //   console.log("salary value is :", salary);
  // };
  const handleSalaryChange = (value) => {
    setSalary(value ? parseFloat(value) : null);
    console.log("salary value is :", value);
  };

  const filteredCards = posts.filter((post) => {
    const job = post.title.toLowerCase();
    const filterLower = filter.toLowerCase();
    return job.startsWith(filterLower);
  });

  const onArchivePost = async (postId, archivedStatus) => {
    try {
      // If trying to de-archive, check the deadline first
      const post = posts.find((p) => p.id === postId);
      if (post && new Date(post.deadline) < new Date()) {
        toast.warning(
          "You need to change the deadline to de-archive this post."
        );
        return;
      }

      const response = await fetch(
        `${apiUrl}posts/${postId}/archive/${userId}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({ archived: archivedStatus }),
        }
      );
      if (response.ok) {
        console.log(
          `Post with ID ${postId} has been ${archivedStatus ? "archived" : "unarchived"
          }.`
        );
        await Load();
        toast.success(
          `Post has been ${archivedStatus ? "archived" : "de-archived"
          } successfully.`
        );
      } else {
        console.error(
          `Error updating archive status for post with ID ${postId}: ${response.statusText}`
        );
        toast.error("Failed to update archive status.");
      }
    } catch (error) {
      console.error(`Error updating archive status: ${error.message}`);
      toast.error("An error occurred while updating archive status.");
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckboxChangeScr = (event) => {
    setIsCheckedScr(event.target.checked);
    console.log('new state ', isCheckedScr);
  }
  const [value, setValue] = useState([]);
  // if (loading) return <AnimatedLoading />;
  // if (error) return <div>Error: {error}</div>;
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-right" autoClose={3000} />

      <div className="d-flex flex-column content">
        {loading ? (
          <AnimatedLoading count={6} />
        ) : (
          <>
            {selectedPost ? (
              <PostDetails
                post={selectedPost}
                onBack={handleBackToList}
                apiUrl={apiUrl}
              />
            ) : (
              <>
                <WelcomeSection>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end", // This ensures the items are aligned to the right
                      transform: "scale(0.9)", // Adjust this if needed, it might scale things incorrectly
                      width: "100%", // Ensure the container takes full width
                    }}
                  >
                    <Stack
                      direction="row"
                      padding={2}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between", // Push items to opposite sides
                      }}
                    >
                      <Typography variant="h4" gutterBottom>
                        Email Candidates Extraction
                      </Typography>
                      {/* <TextField
                        style={{ marginRight: "30px" }} // Adjust this for spacing
                        variant="standard"
                        type="text"
                        placeholder="Filter by Job post"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      /> */}

                      {/* <Button
                        onClick={() => setOpen(true)}
                        variant="contained"
                        type="submit"
                        sx={{
                          height: "100%",
                          color: COLORS.white,
                          border: "1px solid",
                          borderColor: COLORS.grey,
                          bgcolor: COLORS.signIn,
                          ":hover": {
                            bgcolor: COLORS.white,
                            borderColor: COLORS.signIn,
                            color: COLORS.signIn,
                          },
                        }}
                      >
                        Add new Post
                      </Button> */}
                    </Stack>
                  </Box>
                </WelcomeSection>

                <Container fluid className="px-4">
                  <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: '2%' }}>
                    <FormControlLabel
                      control={
                        <ArchiveSwitch
                          checked={showAllPosts}
                          onChange={(e) => setShowAllPosts(e.target.checked)}
                        />
                      }
                      // label="Show Archived Posts"
                      sx={{ marginRight: "30px" }} // You can keep this or adjust it as needed
                    />
                  </Box>

                  <Row className="g-4">
                    {filteredCards.map((post) => (
                      <Col key={post.id} xs={12} md={6} lg={4}>
                        <CardPost
                          postid={post.id}
                          imageSrc={post.image}
                          title={post.title}
                          description={post.description}
                          requirement={post.requirements}
                          deadline={post.deadline}
                          company={post.company}
                          onDeletePost={deletePostById}
                          updatePost={updatePost}
                          handleImageUpload={handleImageUpload}
                          onSelectPost={() => handleSelectPost(post)}
                          archived={post.archived}
                          onArchivePost={onArchivePost}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </>
            )}
            <StyledDialog
              open={open}
              onClose={() => setOpen(false)}
              fullWidth
              maxWidth="sm"
            >
              <StyledDialogContent>
                <Stack spacing={3} margin={2}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={COLORS.signIn}
                  >
                    Add Post
                  </Typography>
                  <Divider sx={{ my: 2, borderColor: "grey" }} />
                  <StyledTextField
                    variant="outlined"
                    label="Post Title"
                    value={postTitle}
                    onChange={(event) => setPostTitle(event.target.value)}
                    error={!!errors.postTitle}
                    helperText={errors.postTitle}
                    required
                    fullWidth
                  />
                  <StyledTextField
                    variant="outlined"
                    label="Post Description"
                    value={postDescription}
                    onChange={(event) => setPostDescription(event.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                    error={!!errors.postDescription}
                    helperText={errors.postDescription}
                    required
                  />
                  <FormControl fullWidth error={!!errors.offerType}>
                    <InputLabel id="offer-type-label">Offer Type</InputLabel>
                    <StyledSelect
                      labelId="offer-type-label"
                      value={offerType}
                      onChange={(event) => setOfferType(event.target.value)}
                      label="Offer Type"
                      required
                    >
                      <MenuItem value="job">Job</MenuItem>
                      <MenuItem value="internship">Internship</MenuItem>
                    </StyledSelect>
                    {errors.offerType && (
                      <FormHelperText>{errors.offerType}</FormHelperText>
                    )}
                  </FormControl>
                  <ChipInput
                    requirement={postRequirement}
                    setRequirement={setPostRequirement}
                  />
                  <Box sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Deadline"
                        value={postDeadline ? dayjs(postDeadline) : null}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: "outlined",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Stack>
              </StyledDialogContent>
              <DialogActions sx={{ padding: 2, justifyContent: "flex-end" }}>
                <Button
                  onClick={() => setOpen(false)}
                  variant="contained"
                  sx={{
                    height: "100%",
                    color: COLORS.signIn,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.Button_OnHold,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.signIn,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Next
                </Button>
              </DialogActions>
            </StyledDialog>
            <StyledDialog
              open={open2}
              onClose={() => setOpen2(false)}
              fullWidth
              maxWidth="sm"
            >
              <StyledDialogContent>
                <Stack spacing={3} sx={{ margin: "16px" }}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={COLORS.signIn}
                  >
                    Add Post
                  </Typography>
                  <Divider sx={{ my: 2, borderColor: "grey" }} />
                  <ImageUploadButton
                    onImageUpload={handleImageUpload}
                    error={!!errors.postImage}
                    helperText={errors.postImage}
                    required
                  />
                  <Box>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      fontWeight="medium"
                    >
                      Working Mode
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {["Remote", "Onsite", "Hybrid"].map((mode) => (
                        <StyledChip
                          key={mode}
                          label={mode}
                          onClick={() => {
                            setWorkingMode(mode);
                            setErrors((prev) => ({ ...prev, workingMode: "" }));
                          }}
                          color={workingMode === mode ? "primary" : "default"}
                          variant={workingMode === mode ? "filled" : "outlined"}
                        />
                      ))}
                    </Stack>
                    {errors.workingMode && (
                      <FormHelperText error>
                        {errors.workingMode}
                      </FormHelperText>
                    )}
                  </Box>
                  <GlobalCityAutocomplete
                    value={location}
                    onChange={(newValue) => {
                      setLocation(newValue);
                      setErrors((prev) => ({ ...prev, location: "" }));
                    }}
                    error={!!errors.location}
                    helperText={errors.location}
                  />

                  <FormControl fullWidth>
                    <InputLabel id="number-of-people-label">
                      Number of people to hire
                    </InputLabel>
                    <Select
                      labelId="number-of-people-label"
                      value={numPeople}
                      onChange={(event) => setNumPeople(event.target.value)}
                      label="Number of people to hire"
                    >
                      {[...Array(10).keys()].map((num) => (
                        <MenuItem key={num} value={num + 1}>
                          {num + 1}
                        </MenuItem>
                      ))}
                      <MenuItem value={10}>10+</MenuItem>
                    </Select>
                  </FormControl>

                  {/* <SalaryInputWithCurrency
                salary={salary}
                setSalary={handleSalaryChange}
                currency={currency}
                setCurrency={setCurrency}
              /> */}
                  <SalaryInputWithCurrency
                    salary={salary}
                    setSalary={handleSalaryChange}
                    currency={currency}
                    setCurrency={setCurrency}
                  />
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Make a post on LinkedIn"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedScr}
                          onChange={handleCheckboxChangeScr}
                          color="primary"
                        />
                      }
                      label="Enable scraping"
                    />
                  </Box>

                </Stack>
              </StyledDialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    height: "100%",
                    color: COLORS.signIn,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.Button_OnHold,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                  onClick={() => {
                    setOpen(true);
                    setOpen2(false);
                  }}
                >
                  PREVIOUS
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.signIn,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                  onClick={handleSave}
                >
                  ADD POST
                </Button>
              </DialogActions>
            </StyledDialog>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default PostPage;
