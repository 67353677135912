// // C:\Users\youne\OneDrive\Desktop\younes_project\ATS_PROJECT-\front-end\src\views\quiz\QuizTaker.jsx
// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Paper,
//   Typography,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormControl,
//   FormLabel,
//   TextField,
//   CircularProgress,
//   Alert,
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import quizApi from "./quizApi";
// const QuizTaker = () => {
//   const { accessCode } = useParams();
//   const [quiz, setQuiz] = useState(null);
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [responses, setResponses] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [submitted, setSubmitted] = useState(false);

//   useEffect(() => {
//     const fetchQuiz = async () => {
//       try {
//         const quizData = await quizApi.getQuizByCode(accessCode);
//         setQuiz(quizData);
//         // Initialize responses
//         const initialResponses = {};
//         quizData.questions.forEach((question) => {
//           initialResponses[question.id] = {
//             question: question.id,
//             selected_answer: null,
//             text_response: "",
//           };
//         });
//         setResponses(initialResponses);
//         setLoading(false);
//       } catch (err) {
//         setError("Failed to load quiz. Please check the access code.");
//         setLoading(false);
//       }
//     };

//     fetchQuiz();
//   }, [accessCode]);

//   const handleAnswerChange = (questionId, value, isTextResponse = false) => {
//     setResponses({
//       ...responses,
//       [questionId]: {
//         ...responses[questionId],
//         [isTextResponse ? "text_response" : "selected_answer"]: value,
//       },
//     });
//   };

//   const handleSubmit = async () => {
//     try {
//       const responsesArray = Object.values(responses);
//       await quizApi.submitQuizResponses(quiz.id, responsesArray);
//       setSubmitted(true);
//     } catch (err) {
//       setError("Failed to submit quiz responses. Please try again.");
//     }
//   };

//   if (loading) {
//     return (
//       <Container sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container sx={{ mt: 4 }}>
//         <Alert severity="error">{error}</Alert>
//       </Container>
//     );
//   }

//   if (submitted) {
//     return (
//       <Container sx={{ mt: 4 }}>
//         <Alert severity="success">
//           Quiz submitted successfully! We'll notify you when your results are
//           ready.
//         </Alert>
//       </Container>
//     );
//   }

//   const question = quiz?.questions[currentQuestion];

//   return (
//     <Container maxWidth="md">
//       <Paper elevation={3} sx={{ p: 4, my: 4 }}>
//         <Typography variant="h4" gutterBottom>
//           {quiz?.title}
//         </Typography>
//         <Typography variant="body1" sx={{ mb: 4 }}>
//           {quiz?.description}
//         </Typography>

//         {question && (
//           <Box sx={{ mb: 4 }}>
//             <Typography variant="h6" gutterBottom>
//               Question {currentQuestion + 1} of {quiz.questions.length}
//             </Typography>
//             <FormControl component="fieldset" sx={{ width: "100%" }}>
//               <FormLabel component="legend">{question.question_text}</FormLabel>

//               {question.question_type === "mcq" ? (
//                 <RadioGroup
//                   value={responses[question.id]?.selected_answer || ""}
//                   onChange={(e) =>
//                     handleAnswerChange(question.id, parseInt(e.target.value))
//                   }
//                 >
//                   {question.answers.map((answer) => (
//                     <FormControlLabel
//                       key={answer.id}
//                       value={answer.id}
//                       control={<Radio />}
//                       label={answer.answer_text}
//                     />
//                   ))}
//                 </RadioGroup>
//               ) : (
//                 <TextField
//                   fullWidth
//                   multiline
//                   rows={4}
//                   value={responses[question.id]?.text_response || ""}
//                   onChange={(e) =>
//                     handleAnswerChange(question.id, e.target.value, true)
//                   }
//                   placeholder={
//                     question.question_type === "code"
//                       ? "Enter your code here..."
//                       : "Enter your answer here..."
//                   }
//                   sx={{ mt: 2 }}
//                 />
//               )}
//             </FormControl>
//           </Box>
//         )}

//         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Button
//             disabled={currentQuestion === 0}
//             onClick={() => setCurrentQuestion((prev) => prev - 1)}
//           >
//             Previous
//           </Button>
//           {currentQuestion === quiz?.questions.length - 1 ? (
//             <Button variant="contained" color="primary" onClick={handleSubmit}>
//               Submit Quiz
//             </Button>
//           ) : (
//             <Button
//               variant="contained"
//               onClick={() => setCurrentQuestion((prev) => prev + 1)}
//             >
//               Next
//             </Button>
//           )}
//         </Box>
//       </Paper>
//     </Container>
//   );
// };

// export default QuizTaker;
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Alert,
  Paper,
} from "@mui/material";
import { Timer, Check, Close } from "@mui/icons-material";

export default function QuizTaker({ quizId }) {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [attemptId, setAttemptId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch quiz data
  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await fetch(
          `${apiUrl}recruiters/api/quizzes/${quizId}/`
        );
        if (!response.ok) throw new Error("Failed to fetch quiz");
        const data = await response.json();
        setQuiz(data);
        setTimeLeft(data.duration_minutes * 60);

        // Create quiz attempt
        const attemptResponse = await fetch(
          `${apiUrl}recruiters/api/quiz-attempts/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ quiz: quizId }),
          }
        );

        if (!attemptResponse.ok) throw new Error("Failed to create attempt");
        const attemptData = await attemptResponse.json();
        setAttemptId(attemptData.id);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  // Timer logic
  useEffect(() => {
    if (!timeLeft || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          handleSubmitQuiz();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleAnswerChange = (questionId, answerId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: answerId,
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestion < quiz.questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  const handleSubmitQuiz = async () => {
    setLoading(true);
    try {
      const answersArray = Object.entries(answers).map(
        ([questionId, answerId]) => ({
          question: parseInt(questionId),
          answer: parseInt(answerId),
        })
      );

      const response = await fetch(
        `${apiUrl}recruiters/api/quiz-attempts/${attemptId}/submit/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ answers: answersArray }),
        }
      );

      if (!response.ok) throw new Error("Failed to submit quiz");

      const data = await response.json();
      setResults(data);
      setShowResults(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setConfirmSubmit(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (showResults) {
    return (
      <Card sx={{ m: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Quiz Results
          </Typography>
          <Typography
            variant="h6"
            color={
              results.score >= quiz.passing_score
                ? "success.main"
                : "error.main"
            }
          >
            Score: {Math.round(results.score)}%
          </Typography>
          <Typography variant="body1" gutterBottom>
            {results.score >= quiz.passing_score
              ? "Congratulations! You passed!"
              : "Unfortunately, you did not pass."}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Passing score: {quiz.passing_score}%
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Question Review
            </Typography>
            {quiz.questions.map((question, index) => (
              <Paper key={question.id} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  {index + 1}. {question.text}
                </Typography>
                {question.answers.map((answer) => {
                  const isSelected = answers[question.id] === answer.id;
                  const isCorrect = answer.is_correct;

                  return (
                    <Box
                      key={answer.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: isSelected
                          ? isCorrect
                            ? "success.main"
                            : "error.main"
                          : "text.primary",
                        mb: 1,
                      }}
                    >
                      {isSelected && (isCorrect ? <Check /> : <Close />)}
                      <Typography sx={{ ml: 1 }}>{answer.text}</Typography>
                    </Box>
                  );
                })}
              </Paper>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ m: 2 }}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5">{quiz.title}</Typography>
            <Box display="flex" alignItems="center">
              <Timer sx={{ mr: 1 }} />
              <Typography>Time remaining: {formatTime(timeLeft)}</Typography>
            </Box>
          </Box>

          <LinearProgress
            variant="determinate"
            value={((currentQuestion + 1) / quiz.questions.length) * 100}
            sx={{ mb: 2 }}
          />

          <Typography variant="body1" gutterBottom>
            Question {currentQuestion + 1} of {quiz.questions.length}
          </Typography>

          <Typography variant="h6" gutterBottom>
            {quiz.questions[currentQuestion].text}
          </Typography>

          <RadioGroup
            value={answers[quiz.questions[currentQuestion].id] || ""}
            onChange={(e) =>
              handleAnswerChange(
                quiz.questions[currentQuestion].id,
                e.target.value
              )
            }
          >
            {quiz.questions[currentQuestion].answers.map((answer) => (
              <FormControlLabel
                key={answer.id}
                value={answer.id}
                control={<Radio />}
                label={answer.text}
              />
            ))}
          </RadioGroup>
        </CardContent>
      </Card>

      <Box display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          onClick={handlePreviousQuestion}
          disabled={currentQuestion === 0}
        >
          Previous
        </Button>

        {currentQuestion === quiz.questions.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmSubmit(true)}
            disabled={Object.keys(answers).length !== quiz.questions.length}
          >
            Submit Quiz
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNextQuestion}>
            Next
          </Button>
        )}
      </Box>

      <Dialog open={confirmSubmit} onClose={() => setConfirmSubmit(false)}>
        <DialogTitle>Submit Quiz?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to submit your quiz? You cannot change your
            answers after submission.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmSubmit(false)}>Cancel</Button>
          <Button
            onClick={handleSubmitQuiz}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
