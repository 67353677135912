import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  MenuItem,
  Select,
  InputAdornment,
  Stack,
  Container,
  Card,
  CardContent,
  Grid,
  useTheme,
  alpha,
} from "@mui/material";
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  Assignment as AssignmentIcon,
  LocationOn as LocationIcon,
  AccessTime as AccessTimeIcon,
  WorkOutline as WorkIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ButtonAppBar from "components/Navbars/navBar";
import App from "views/Chat/Chat";
import Footer from "views/client/Footer";
import "./index.css";
import { COLORS } from "colors/color";
import CustomPagination from "views/client/pagination";
import CustomTablePagination from "views/client/pagination";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: 56,
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    fontFamily: "Poppins",
    borderColor: "#00000033",
    backgroundColor: "#fff",
    boxShadow: "0 4px 20px 0 #0000000D",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e0e0e0",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "2px",
      boxShadow: "0px 0px 5px 0.4px #0085FF",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "2px",
      boxShadow: "0px 0px 5px 0.4px #0085FF",
    },
  },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  height: 56,
  fontFamily: "Poppins",
  borderTopRightRadius: "50px",
  borderBottomRightRadius: "50px",
  boxShadow: "0 4px 20px 0 #0000000D",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e0e0e0",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0.4px #0085FF",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0.4px #0085FF",
  },
}));
const HeaderTableContainer = styled(Paper)(({ theme }) => ({
  borderRadius: "100px",
  marginBottom: "35px",
  backgroundColor: "#ffffff",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "100px",
  overflow: "hidden",
  backgroundColor: "transparent",
  "& .MuiTable-root": {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  padding: "16px",
  "&:first-of-type": {
    borderTopLeftRadius: "100px",
    borderBottomLeftRadius: "100px",
  },
  "&:last-of-type": {
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "100px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: "relative",
  transition: "all 0.2s ease",
  // backgroundColor: "#fff",
 
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "40px", // Adjust starting position from left
    right: "40px", // Adjust ending position from right
    height: "1px", // Set the thickness of the border
    backgroundColor: "#004F9833", // Set the border color
    
  },
  "&:hover": {
    // backgroundColor: theme.palette.action.hover,
    background: 'linear-gradient(to top, #E5F3FF 0%, rgba(255,255,255,0) 50%)',

    transform: "translateY(-1px)",
  },
  "& td": {
    padding: "20px 16px",
    border: "none",
    "&:first-of-type": {
      borderTopLeftRadius: "100px",
      borderBottomLeftRadius: "100px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "100px",
      borderBottomRightRadius: "100px",
    },
  },
}));



const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableRow-root": {
    position: "relative",
    height: "50px",
    margin: "50px 0 0 0",
    background: "#ffffff",
    borderRadius: "100px",
    boxShadow: "0 4px 40px 0 rgba(0, 0, 0, 0.1)",
    "& .MuiTableCell-root": {
      fontFamily:'Poppins',
      backgroundColor: "#ffffff",
      fontWeight: 600,
      color: "#004F9899",
      borderBottom: "none",
      fontSize: '11px',
      fontWeight:'500',
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(1.5),
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[50]
      : theme.palette.grey[800],
  border: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(
    ["background-color", "box-shadow", "transform"],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[700],
    transform: "translateY(-1px)",
    boxShadow: theme.shadows[2],
  },
  "&:active": {
    transform: "translateY(0)",
    boxShadow: theme.shadows[1],
  },
  "&.Mui-disabled": {
    opacity: 0.6,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[800],
  },
}));
const PostsApplied = () => {
  const [applications, setApplications] = useState([]);
  const [applicationsWithSteps, setApplicationsWithSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [stepCounts, setStepCounts] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const [ChatComponent, setChatComponent] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setIsAuth(!!localStorage.getItem("accessToken"));
  }, []);

  const getUserId = () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) return null;

      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.user_id;
    } catch (error) {
      console.error("Error parsing token:", error);
      return null;
    }
  };

  const fetchApplicationStep = async (applicationId) => {
    try {
      const response = await fetch(
        `${apiUrl}authentication/application/${applicationId}/step/`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch application step");
      }
      const data = await response.json();
      return data.application_step;
    } catch (error) {
      console.error(
        `Error fetching step for application ${applicationId}:`,
        error
      );
      return "Unknown";
    }
  };

  const fetchApplications = async () => {
    const userId = getUserId();
    if (!userId) {
      setError("Authentication required");
      setIsLoginDialogOpen(true);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${apiUrl}candidates/list_applied_posts/${userId}/`
      );

      if (!response.ok) {
        throw new Error(
          response.status === 404
            ? "No applications found"
            : "Failed to fetch applications"
        );
      }

      const data = await response.json();
      setApplications(data);

      // Fetch steps for all applications
      const applicationsWithStepsData = await Promise.all(
        data.map(async (application) => {
          console.log("apps:", application.id);
          const step = await fetchApplicationStep(application.id);
          return { ...application, currentStep: step };
        })
      );

      // Calculate step counts
      const counts = applicationsWithStepsData.reduce((acc, app) => {
        acc[app.currentStep] = (acc[app.currentStep] || 0) + 1;
        return acc;
      }, {});

      setStepCounts(counts);
      setApplicationsWithSteps(applicationsWithStepsData);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  
  const getStatusColor = (status) => {
    const colors = {
      pending: "warning",
      rejected: "error",
      accepted: "success",
      in_progress: "info",
    };
    return colors[status.toLowerCase()] || "default";
  };

  const filteredApplications = applicationsWithSteps.filter((app) => {
    const matchesSearch =
      app.post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.post.localisation.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus =
      statusFilter === "all" ||
      app.currentStep.toLowerCase() === statusFilter.toLowerCase();
    return matchesSearch && matchesStatus;
  });

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[page])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleRefresh = () => {
    fetchApplications();
  };
  const getStatusChipProps = (status) => {
    const baseProps = {
      size: "small",
      sx: {
        borderRadius: "8px",
        fontWeight: 600,
        padding: "4px 8px",
      },
    };

    const statusConfig = {
      application_review: {
        backgroundColor: alpha(theme.palette.error.light, 0.1),
        color: theme.palette.error.dark,
        label: "Application Review",
      },
      on_boarding: {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        color: theme.palette.success.dark,
        label: "on boarding",
      },
    };

    // Normalize the status to handle different cases or formats
    const normalizedStatus = status.toLowerCase().replace(/ /g, "_");

    // Retrieve the configuration based on the normalized status
    const config = statusConfig[normalizedStatus] || {
      backgroundColor: alpha(theme.palette.warning.light, 0.1),
      color: theme.palette.warning.main,
      label: status,
    };

    return {
      ...baseProps,
      sx: {
        ...baseProps.sx,
        backgroundColor: config.backgroundColor,
        color: config.color,
      },
      label: config.label,
    };
  };
  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  const senderId = getUserIdFromAccessToken();
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  return (
    <>
      {ChatComponent ? (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <App candidate_id={senderId} />
        </>
      ) : (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            hideWhileScrolling={90}
            setChatComponent={setChatComponent}
          />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, pt: "6%",scrollBehavior: 'smooth' }}>
            <Stack spacing={4}>
              {/* Header Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    background: "#004F98",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Posts applied
                </Typography>
                {/* <StyledIconButton
                  onClick={handleRefresh}
                  disabled={isLoading}
                  // sx={{
                  //   backgroundColor: alpha(theme.palette.primary.main, 0.05),
                  //   "&:hover": {
                  //     backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  //   },
                  // }}
                >
                  <RefreshIcon />
                </StyledIconButton> */}
                <StyledIconButton
                  onClick={handleRefresh}
                  disabled={isLoading}
                  size="medium"
                  color="primary"
                  // aria-label={tooltipText}
                >
                  {isLoading ? (
                    <CircularProgress size={24} thickness={4} color="inherit" />
                  ) : (
                    <RefreshRoundedIcon />
                  )}
                </StyledIconButton>
              </Box>
              {/* Filters Section */}
              <StyledCard elevation={0}>
                <CardContent>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <SearchTextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search by keywords, job title, location..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <StyledSelect
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      sx={{ minWidth: 200 }}
                    >
                      <MenuItem value="all">All Statuses</MenuItem>
                      {Object.keys(stepCounts).map((step) => (
                        <MenuItem key={step} value={step.toLowerCase()}>
                          {step}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Stack>
                </CardContent>
              </StyledCard>

              {/* Applications Table */}
              <HeaderTableContainer elevation={0}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      {/* <StyledTableCell>Application #</StyledTableCell> */}
                      <StyledTableCell>Position</StyledTableCell>
                      <StyledTableCell>Location</StyledTableCell>
                      <StyledTableCell>Mode</StyledTableCell>
                      <StyledTableCell>Deadline</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              p: 4,
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : filteredApplications.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              p: 4,
                              gap: 2,
                            }}
                          >
                            <AssignmentIcon
                              sx={{ fontSize: 48, color: "text.secondary" }}
                            />
                            <Typography variant="h6" color="text.secondary">
                              No applications found
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredApplications
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((application, index) => (
                          <StyledTableRow key={application.id}>
                            {/* <TableCell>
                              <Typography variant="body2" fontWeight={600}>
                                #{page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell> */}
                            <TableCell>
                              <Stack spacing={0.5}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  {application.post.title}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  Applied on{" "}
                                  {new Date(
                                    application.date
                                  ).toLocaleDateString()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <LocationIcon fontSize="small" color="action" />
                                <Typography variant="body2">
                                  {application.post.localisation}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <WorkIcon fontSize="small" color="action" />
                                <Typography variant="body2">
                                  {application.post.mode}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <AccessTimeIcon
                                  fontSize="small"
                                  color="action"
                                />
                                <Typography variant="body2">
                                  {new Date(
                                    application.post.deadline
                                  ).toLocaleDateString()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Chip
                                {...getStatusChipProps(application.currentStep)}
                              />
                            </TableCell>
                          </StyledTableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredApplications.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                {isLoading? '':(
                  <Box display="flex"        
                  justifyContent="center" 
                  alignItems="center"
                  paddingTop={5}
                  paddingBottom={3}
                  >
                    <CustomPagination 
                  page={page}
                  handleChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  count={filteredApplications.length}

                />
                  </Box>
                  )}
                
              </HeaderTableContainer>
            </Stack>

            {/* Login Dialog */}
            <Dialog
              open={isLoginDialogOpen}
              onClose={() => setIsLoginDialogOpen(false)}
              PaperProps={{
                sx: { borderRadius: 3 },
              }}
            >
              <DialogTitle>Authentication Required</DialogTitle>
              <DialogContent>
                <Typography>
                  Please log in to view your applications.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsLoginDialogOpen(false)}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate("/login")}
                  sx={{ borderRadius: 2 }}
                  autoFocus
                >
                  Login
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default PostsApplied;