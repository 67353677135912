import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '8px',
  backgroundColor: '#fff',
  borderRadius: '100px',
  boxShadow: '0px 2.63px 20px 0 rgba(0, 0, 0, 0.1)',
  width: 'fit-content'
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  minWidth: '100px',
  height: '40px',
  backgroundColor: '#0085FF',
  borderRadius: '100px',
  padding: '8px 16px',
  color: '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: '#cce7ff',
    color: '#fff',
  },
  '& .MuiButton-startIcon, & .MuiButton-endIcon': {
    color: '#fff',
  }
}));

const PageButton = styled(Button)(({ theme, active }) => ({
  minWidth: '40px',
  width: '40px',
  height: '40px',
  padding: 0,
  borderRadius: '100px',
  backgroundColor: active ? '#0085FF' : 'transparent',
  color: active ? '#fff' : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : theme.palette.action.hover,
  }
}));

const Ellipsis = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 4px',
  color: '#9E9E9E',
});

const CustomPagination = ({
  count,
  page = 0,
  rowsPerPage = 10,
  handleChangePage,
  onRowsPerPageChange,
  onPageChange
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);
  
  const handleChange = (newPage) => {
    if (handleChangePage) {
      handleChangePage(null, newPage);
    }
    if (onPageChange) {
      onPageChange(null, newPage);
    }
  };

  const getPageRange = () => {
    const currentPage = page + 1;
    const range = [];
    
    // Always show first page
    range.push(1);
    
    if (totalPages <= 7) {
      // Show all pages if total pages are 7 or less
      for (let i = 2; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Dynamic range for more than 7 pages
      if (currentPage <= 4) {
        range.push(2, 3, 4, 5, '...', totalPages);
      } else if (currentPage >= totalPages - 3) {
        range.push('...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        range.push('...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    
    return range;
  };

  const renderPageNumbers = () => {
    const items = [];
    const currentPage = page + 1;
    
    // Previous button
    items.push(
      <NavigationButton
        key="prev"
        onClick={() => handleChange(page - 1)}
        disabled={page === 0}
        startIcon={<NavigateBeforeIcon />}
      >
        Previous
      </NavigationButton>
    );

    // Page numbers
    getPageRange().forEach((pageNum, index) => {
      if (pageNum === '...') {
        items.push(<Ellipsis key={`ellipsis-${index}`}>...</Ellipsis>);
      } else {
        items.push(
          <PageButton
            key={pageNum}
            active={currentPage === pageNum}
            onClick={() => handleChange(pageNum - 1)}
          >
            {pageNum}
          </PageButton>
        );
      }
    });

    // Next button
    items.push(
      <NavigationButton
        key="next"
        onClick={() => handleChange(page + 1)}
        disabled={currentPage === totalPages}
        endIcon={<NavigateNextIcon />}
      >
        Next
      </NavigationButton>
    );

    return items;
  };

  return <PaginationContainer>{renderPageNumbers()}</PaginationContainer>;
};

export default CustomPagination;