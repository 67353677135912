import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  InputAdornment,
  Divider,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import wavesImage from "../../assets/img/waves.jpg";

const SearchButton = styled(Button)(({ theme }) => ({
  borderRadius: "100px",
  height: "4.8vh",
  padding: "5% 15%",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
  backgroundColor: "#0085FF",
  fontSize: "1rem",
  color: "white",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "0 4px 12px rgba(0, 102, 255, 0.3)",
  },
  [theme.breakpoints.down("md")]: {
    padding: "4% 12%",
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3% 10%",
    fontSize: "0.875rem",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "2% 8%",
    fontSize: "0.8rem",
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  minHeight: "600px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingTop: theme.spacing(15),
  position: "relative",
  backgroundImage: `url(${wavesImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(10),
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  backgroundColor: "white",
  padding: theme.spacing(0.5),
  borderRadius: "50px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.08)",
  width: "100%",
  maxWidth: "40%",
  margin: "0 auto",
  position: "absolute",
  top: "70%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
    maxWidth: "80%",
    position: "relative",
    top: "auto",
    left: "auto",
    transform: "none",
  },
  [theme.breakpoints.down("xs")]: {
    maxWidth: "90%",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontFamily: 'Poppins',
  textTransform: 'none',
  fontSize: '1rem',
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 0,
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
}));

const JobCountBanner = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  background: "linear-gradient(90deg, #007EF2 0%, #ABD7FF 100%)",
  color: "white",
  padding: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
    padding: theme.spacing(0.5),
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  position: "relative",
  zIndex: 1,
  fontWeight: 500,
  letterSpacing: "0.1rem",
  textAlign: "center",
  color: "white",
  padding: "0.5rem 1rem",
  borderRadius: "8px",
  transition: "all 0.3s ease",
  mixBlendMode: "difference",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    zIndex: -1,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.75rem",
    padding: "0.35rem 0.75rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.5rem",
    padding: "0.25rem 0.5rem",
  },
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  color: "black",
  textShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  maxWidth: "70rem",
  margin: "0 auto",
  textAlign: "center",
  position: "relative",
  fontFamily: 'Poppins',
  zIndex: 1,
  fontSize: '1.4rem',
  fontWeight: 500,
  textTransform: "none",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    maxWidth: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.875rem",
  },
}));

const SearchBar = ({ props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };
  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const handleSearch = async (event) => {
    event.preventDefault();
    if (searchTerm.trim() === "" && location.trim() === "") {
      showSnackbar("Please enter either a job title or a city name.", "error");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      let url = `${apiUrl}posts/search_recommendation/?`;
      if (searchTerm.trim()) {
        url += `title=${encodeURIComponent(searchTerm.trim())}`;
      }
      if (location.trim()) {
        url += `${
          url.includes("?") ? "&" : "?"
        }localisation=${encodeURIComponent(location.trim())}`;
      }

      console.log(url);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);

      if (data.posts && data.posts.length === 0) {
        props([], data.message);
      } else {
        props(data.posts, "");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <SearchContainer>
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: "center",
            mb: 8,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <HeadingText variant="h3" component="h2" sx={{ mb: 3, mt: 3 }}>
            Elevate your chances of recruitment
            <br />
            with our AI-Powered recruiter
          </HeadingText>
          <SubtitleText variant="subtitle1" sx={{ mb: 6 }}>
            Boost your recruitment success with our AI-driven platform. Get personalized job matches, optimize your applications, and connect with top employers effortlessly. Let AI enhance your job search and hiring process, making it faster and smarter.
          </SubtitleText>
        </Box>

        <form onSubmit={handleSearch}>
          <SearchBox>
            <StyledTextField
              fullWidth
              placeholder="Search by keywords, job title..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 2 }}>
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <StyledTextField
              fullWidth
              placeholder="City, zip..."
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 2 }}>
                    <LocationOnIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <SearchButton variant="contained" type="submit" sx={{ textTransform: "none", fontFamily: 'Poppins' }}>
              Find jobs
            </SearchButton>
          </SearchBox>
        </form>
      </Container>

      <JobCountBanner>
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          "300+ daily job offers are available. Find your new job today"
        </Typography>
      </JobCountBanner>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)",
          },
        }}
      />
    </SearchContainer>
  );
};

export default SearchBar;