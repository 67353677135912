import React from 'react';
import { Box, Typography } from '@mui/material';

const TextDivider = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        my: 2,
        '&::before, &::after': {
          content: '""',
          flex: 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        },
        '&::before': {
          marginRight: 1,
        },
        '&::after': {
          marginLeft: 1,
        }
      }}
    >
      <Typography variant="body2" color="text.secondary">
        {children}
      </Typography>
    </Box>
  );
};

export default TextDivider;