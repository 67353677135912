import React, { useState, useEffect } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";
import { List, ListItem, ListItemText, InputAdornment } from "@mui/material";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { InputTextarea } from "primereact/inputtextarea";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import StarIcon from "@mui/icons-material/Star";
import { COLORS } from "colors/color";
import useSignalingStore from "../signalingStore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";

export function ControlledBoard({
  candidates,
  onUpdateStep,
  steps,
  setSteps,
  createStep,
  deleteSteps,
  post,
}) {
  const [controlledBoard, setBoard] = useState({ columns: [] });
  // const [count, setCount] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState(dayjs().subtract(1, "day"));
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [subject, setSubject] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [userId, setUserId] = useState("");
  const [recruiterName, setRecruiterName] = useState("");
  const [recruiterEmail, setRecruiterEmail] = useState("");
  const [recruiterPhone, setRecruiterPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [interviewType, setInterviewType] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [newTemplate, setNewTemplate] = useState({ name: "", content: "" });
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [addTemplateDialogOpen, setAddTemplateDialogOpen] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [stepName, setStepName] = useState();

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
  const navigate = useNavigate();
  const [candidateId, setcandidateId] = useState();
  const recruiterId = getUserIdFromAccessToken();
  const [newStepDialog, setNewStepDialog] = useState({
    open: false,
    afterStep: null,
    newStepName: "",
  });

  const position = "Software Engineer";
  const {
    signalingServer,
    signalingServer2,
    setSignalingServer,
    setSignalingServer2,
  } = useSignalingStore();
  

  useEffect(() => {
    console.log("test1");
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const response = await axios.post(
        `${apiUrl}authentication/linkedin-callback/`,
        {
          grant_type: "authorization_code",
          code: code,
          redirect_uri: `${window.location.origin}/talent/post/PostDetails`,
          client_id: "86o1ech9y4in4m",
          client_secret: "WPL_AP0.jW3gypWTWCnun6rz.MTIxMTM2NzA2NA==",
        }
      );
      console.log("LinkedIn token response:", response.data);
      // Handle the response, store the access token, etc.
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };

  useEffect(() => {
    console.log("those are the steps ", steps);
  }, []);
  useEffect(() => {
    console.log("test1");
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  useEffect(() => {
    if (candidates.length > 0) {
      const columnsMap = steps.reduce((acc, step) => {
        acc[step] = {
          id: step,
          title: step,
          cards: [],
        };
        return acc;
      }, {});

      candidates.forEach((candidate) => {
        const { step } = candidate;
        if (columnsMap[step]) {
          columnsMap[step].cards.push({
            id: candidate.id,
            candidateId: candidate.candidate.id,
            title: `${candidate.candidate.last_name} ${candidate.candidate.first_name}`,
            nom: candidate.candidate.last_name,
            prenom: candidate.candidate.first_name,
            description: candidate.candidate.email,
            phone: candidate.score_matching,
            step,
          });
        } else {
          console.warn(
            `Candidate ${candidate.id} has an invalid step: ${step}`
          );
        }
      });

      const columns = steps.map((step) => columnsMap[step]).filter(Boolean);

      setBoard({ columns });
    }
  }, [candidates, steps]);

  useEffect(() => {
    const infos = getDecodedToken();
    if (infos) {
      setUserId(infos.user_id);
    }
  }, []);
  useEffect(() => {
    const fetchAllNotes = async () => {
      if (candidates && candidates.length > 0) {
        const newNotes = {};
        for (const candidate of candidates) {
          await fetchNotesForCandidate(candidate.id, newNotes);
        }
        setNotes(newNotes);
      }
    };

    fetchAllNotes();
  }, [candidates]);

  useEffect(() => {
    fetchUserInfo();
  }, [userId]);
  useEffect(() => {
    getTemplates();
  }, []);
  // function getUserIdFromAccessToken() {
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (!accessToken) {
  //     console.error("No access token found in local storage");
  //     return null;
  //   }

  //   try {
  //     const decodedToken = parseJwt(accessToken);
  //     return decodedToken.user_id;
  //   } catch (error) {
  //     console.error("Failed to decode access token", error);
  //     return null;
  //   }
  // }

  // function parseJwt(token) {
  //   const base64Url = token.split(".")[1];
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   const jsonPayload = decodeURIComponent(
  //     atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );
  //   return JSON.parse(jsonPayload);
  // }
  const postTemplate = async (title, message) => {
    console.log("title,message", title, message, userId);
    try {
      const response = await axios.post(`${apiUrl}posts/messagetemplates/`, {
        title: title,
        message: message,
        recruiter: userId,
      });
    } catch (err) {
      alert("Post Registration Failed!");
    }
  };

  const addStep = (afterStep) => {
    setNewStepDialog({ open: true, afterStep, newStepName: "" });
    console.log("==>", setNewStepDialog.newStepName);
    // setSteps(setNewStepDialog.newStepName)
  };

  const handleAddNewStep = () => {
    const { afterStep, newStepName } = newStepDialog;
    const trimmedName = newStepName.trim();

    if (trimmedName === "") {
      toast.error("Step name cannot be empty.");

      return;
    }

    if (steps.includes(trimmedName)) {
      toast.error("Step name must be unique");
      return;
    }

    setSteps((prevSteps) => {
      const index = prevSteps.indexOf(afterStep);
      if (index === -1) {
        toast.error("Error: Step not found");
        return prevSteps;
      }
      const newSteps = [...prevSteps];
      newSteps.splice(index + 1, 0, trimmedName);
      return newSteps;
    });

    setBoard((prevBoard) => {
      const index = prevBoard.columns.findIndex(
        (column) => column.id === afterStep
      );
      if (index === -1) {
        toast.error("Error: Step not found");
        return prevBoard;
      }
      const newColumns = [...prevBoard.columns];
      newColumns.splice(index + 1, 0, {
        id: trimmedName,
        title: trimmedName,
        cards: [],
      });
      // setStepName
      createStep(trimmedName);
      return { columns: newColumns };
    });

    toast.success(`New step "${trimmedName}" added successfully.`);
    setNewStepDialog({ open: false, afterStep: null, newStepName: "" });
  };

  const removeStep = (stepToRemove) => {
    if (steps.length > 1) {
      const stepToRemoveColumn = controlledBoard.columns.find(
        (col) => col.id === stepToRemove
      );
      if (stepToRemoveColumn && stepToRemoveColumn.cards.length > 0) {
        toast.warning("Cannot remove a step that contains candidates.");
        return;
      }
      deleteSteps(stepToRemove);

      const newSteps = steps.filter((step) => step !== stepToRemove);
      setSteps(newSteps);

      setBoard((prevBoard) => ({
        columns: prevBoard.columns.filter(
          (column) => column.id !== stepToRemove
        ),
      }));
    } else {
      toast.warning("Cannot remove the last remaining step.");
    }
  };
  const getTemplates = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}posts/messagetemplates/recruiter/${recruiterId}/`
      );
      console.log("Templates fetched:", response.data);
      setTemplates(response.data);
      return response.data;
    } catch (err) {
      console.error("Failed to fetch templates:", err);
      throw err;
    }
  };

  const updateTemplate = async (templateId, title, message) => {
    try {
      const response = await axios.put(
        `${apiUrl}posts/messagetemplates/recruiter/${recruiterId}/`,
        {
          id: templateId,
          title: title,
          message: message,
          recruiter: recruiterId,
        }
      );
      console.log("Template updated:", response.data);
      return response.data;
    } catch (err) {
      console.error("Failed to update template:", err);
      throw err;
    }
  };

  const deleteTemplate = async (templateId) => {
    try {
      const response = await axios.delete(
        `${apiUrl}posts/messagetemplates/recruiter/${userId}/`,
        {
          data: { id: templateId },
        }
      );
      console.log("Template deleted");
      return response.data;
    } catch (err) {
      console.error("Failed to delete template:", err);
      throw err;
    }
  };

  const fetchUserInfo = async () => {
    if (userId) {
      try {
        const response = await fetch(`${apiUrl}recruiters/${userId}/find/`, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });

        if (response.status === 401) {
          console.log("Unauthorized. Redirecting to login page...");
          navigate("/login");
          return;
        }

        const json = await response.json();
        setUserId(json.id);
        setRecruiterName(`${json.last_name} ${json.first_name}`);
        setRecruiterEmail(json.email);
        setCompanyName(json.company);
        setRecruiterPhone(json.phone);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    }
  };

  // const postToLinkedIn = async () => {
  //   try {
  //     const response = await fetch(`${apiUrl}ai/api/linkedin/post/`, {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //       },
  //     });
  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error("Error posting to LinkedIn:", error);
  //   }
  // };

  const handleSubmit = async (event) => {
    // Add this line to prevent any possible default behavior
    if (event) event.preventDefault();
    
    console.log("handleSubmit called");  // Add this line for debugging
    handleDialogClose();
    
    const eventData = {
      candidate_id: selectedCandidate.candidateId,
      recruiter: userId,
      event_title: eventTitle,
      event_start_date: eventDate,
    };
    
    try {
      const response = await axios.post(
        `${apiUrl}authentication/events/`,
        eventData
      );
      console.log("Event created successfully:", response.data);
      sendMessage();
      handleDialogClose();
      sendWebSocketMessage(
        selectedCandidate.candidateId,
        eventDate.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log('After successful event creation');
    } catch (error) {
      console.error("There was an error creating the event!", error);
    }
  };

  const sendMessage = async () => {
    try {
      const response = await fetch(`${apiUrl}ai/send-email/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: subject,
          message: emailBody,
          recipient: selectedCandidate.email,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Email sent successfully");
      } else {
        console.log(`Error: ${data.error}`);
      }
    } catch (error) {
      console.log(`Error: ${error.toString()}`);
    }
  };

  function getDecodedToken() {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found in local storage.");
      return null;
    }

    function decodeJWT(token) {
      const parts = token.split(".");
      if (parts.length !== 3) {
        throw new Error("Invalid token format.");
      }
      const payload = parts[1];
      const decodedPayload = atob(payload);
      const decodedData = JSON.parse(decodedPayload);
      return decodedData;
    }

    try {
      return decodeJWT(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  }
  const [lastPositoninCart, setLastPositioninCart] = useState();

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
    // setCount((prevCount) => prevCount + 1);
    setInterviewType(destination.toColumnId);

    if (onUpdateStep) {
      onUpdateStep(_card.id, destination.toColumnId);
      sendnotif(_card, destination.toColumnId);
    }
  }
  const sendnotif = (card, newStep) => {
    const receiverId = card.candidateId;
    console.log(
      "Connecting to signaling server: ",
      signalingServer ? "Connected" : "Not connected"
    );

    // Check if signaling server is not connected or closed
    if (!signalingServer || signalingServer.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected. Attempting to reconnect...");

      // Attempt to reconnect
      const userId = getUserIdFromAccessToken(); // Make sure this function is defined
      if (userId) {
        const newSignalingServer = connectToSocket(userId); // Make sure this function is defined
        setSignalingServer(newSignalingServer);

        // Wait for the connection to be established
        newSignalingServer.onopen = () => {
          console.log(
            "Reconnected to signaling server. Sending notification..."
          );
          sendNotificationMessage(
            newSignalingServer,
            card,
            newStep,
            receiverId,
            userId
          );
        };
      } else {
        console.error("Unable to reconnect: User ID not available");
        return false;
      }
    } else {
      // If already connected, send the notification immediately
      return sendNotificationMessage(
        signalingServer,
        card,
        newStep,
        receiverId,
        userId
      );
    }
  };

  // Helper function to send the actual notification message
  const sendNotificationMessage = (
    server,
    card,
    newStep,
    receiverId,
    userId
  ) => {
    const timestamp = new Date().toISOString();
    const messageData = {
      type: "notif_message",
      notif: `Your application status to ${post.title} has been updated to: ${newStep}`,
      timestamp,
      link: "/post_applied",
      sender_id: userId,
      receiver_id: receiverId,
    };

    try {
      server.send(JSON.stringify(messageData));
      console.log("Notification sent successfully");
      return true;
    } catch (error) {
      console.error("Error sending notification:", error);
      return false;
    }
  };
  const sendWebSocketMessage = (candidateId, eventDateTime) => {
    const { signalingServer2 } = useSignalingStore.getState();

    if (signalingServer2 && signalingServer2.readyState === WebSocket.OPEN) {
      const messageData = {
        type: "chat_message",
        message: `Meeting scheduled for ${eventDateTime}`,
        timestamp: new Date().toISOString(),
        sender_id: userId,
        receiver_id: candidateId,
      };

      try {
        signalingServer2.send(JSON.stringify(messageData));
        console.log("WebSocket message sent successfully");
        // navigate(0);
      } catch (error) {
        console.error("Error sending WebSocket message:", error);
      }
    } else {
      console.log("WebSocket is not connected. Cannot send message.");
      // Attempt to reconnect
      const userId = getUserIdFromAccessToken();
      if (userId && candidateId) {
        const newSignalingServer2 = connectToSocketchat(userId, candidateId);
        setSignalingServer2(newSignalingServer2);
      }
    }
  };

  function handleDialogClose() {
    setOpen(false);
  }
  const [hoveredNote, setHoveredNote] = useState(null);
  const [noteDetailsAnchor, setNoteDetailsAnchor] = useState(null);
  // const showNoteDetails = (note, event) => {
  //   setHoveredNote(note);
  //   setNoteDetailsAnchor(event.currentTarget);
  // };

  // const hideNoteDetails = () => {
  //   setHoveredNote(null);
  //   setNoteDetailsAnchor(null);
  // };
  const getCurrentStep = (cardId) => {
    for (const column of controlledBoard.columns) {
      const card = column.cards.find((c) => c.id === cardId);
      if (card) {
        return column.id; // This is the step (column title)
      }
    }
    return null;
  };
  function renderCard(card, { dragging }) {
    const cardNotes = notes[card.id] || [];
    const visibleNotes = cardNotes.slice(0, 1);
    const additionalNotesCount = Math.max(0, cardNotes.length - 1);
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: 1 }}>
        <Paper
          elevation={dragging ? 3 : 1}
          sx={{
            p: 2,
            borderRadius: 2,
            background: dragging ? "#f5f5f5" : "#fff",
            border: dragging ? "2px dashed #999" : "1px solid #e0e0e0",
            transition: "all 0.3s ease",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            "&:hover": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.6)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ paddingTop: "1%" }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {card.title}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <EmailIcon
                  fontSize="small"
                  sx={{ mr: 1, color: "text.secondary" }}
                />
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <StarIcon fontSize="small" sx={{ mr: 1, color: "orange" }} />
                <Typography variant="body2" fontWeight="medium">
                  Match Score: {card.phone}%
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setOpen(true);
                  setSelectedCandidate({
                    id: card.id,
                    candidateId: card.candidateId,
                    firstName: card.prenom,
                    lastName: card.nom,
                    email: card.description,
                    phone: card.phone,
                    step: card.step,
                  });
                  setInterviewType(card.step);
                  setLastPositioninCart(card.step);
                  setcandidateId(card.candidateId);

                  setSubject(
                    `Interview Invitation: ${companyName} - ${position} - ${eventDate.format(
                      "MMMM D, YYYY"
                    )}`
                  );
                }}
                sx={{
                  color: COLORS.grey,
                  "&:hover": {
                    backgroundColor: COLORS.grey,
                    color: COLORS.white,
                  },
                }}
              >
                <AddAlertIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Chip
              icon={<PersonIcon />}
              label={card.step}
              size="small"
              sx={{
                backgroundColor: COLORS.pulse_color,
                color: COLORS.white,
              }}
            />
            <Typography variant="caption" color="text.secondary">
              ID: {card.id}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Notes</Typography>
            <List>
              {visibleNotes.map((note) => (
                <ListItem key={note.id}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 1,
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      },
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => handleNoteHover(note, e)}
                    onMouseLeave={handleNoteLeave}
                  >
                    <Typography noWrap>
                      {note.note.substring(0, 5)}...
                    </Typography>
                  </Paper>
                  <IconButton onClick={() => deleteNote(card.id, note.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
              {/* {additionalNotesCount > 0 && (
                <ListItem>
                  <Button
                    onClick={() => handleViewAllNotes(card.id)}
                    startIcon={<AddIcon />}
                  >
                    {additionalNotesCount} more note
                    {additionalNotesCount > 1 ? "s" : ""}
                  </Button>
                </ListItem>
              )} */}
              {additionalNotesCount > 0 && (
                <ListItem>
                  <Button
                    onClick={() => handleViewAllNotes(card.id)}
                    startIcon={<VisibilityIcon />}
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      textTransform: "none",
                      padding: "8px 16px",
                      display: "flex",
                      alignItems: "center",
                      border: "2px dashed",
                      color: "text.secondary",
                      borderColor: "grey.400",
                      "&:hover": {
                        backgroundColor: "grey.100",
                        borderColor: "grey.600",
                        borderStyle: "solid",
                        color: "text.primary",
                      },
                    }}
                  >
                    Click to view {additionalNotesCount} hidden note
                    {additionalNotesCount > 1 ? "s" : ""}
                    <KeyboardArrowRightIcon sx={{ ml: 1 }} />
                  </Button>
                </ListItem>
              )}
            </List>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Add a note"
              value={newNotes[card.id] || ""}
              onChange={(e) =>
                setNewNotes((prev) => ({ ...prev, [card.id]: e.target.value }))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        addNote(card.id);
                        setNewNotes((prev) => ({ ...prev, [card.id]: "" }));
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>
      </Grid>
    );
  }

  useEffect(() => {
    if (selectedCandidate) {
      setEmailBody(`
Dear ${selectedCandidate.firstName},

We are pleased to inform you that you have been shortlisted for an interview for the position of ${position} at ${companyName}.

Interview Details:

- Date: ${eventDate.format("MMMM D, YYYY")}
- Time: ${eventDate.format("h:mm A")}
- Interview Type: ${interviewType}

Please confirm your availability for the interview by responding to this email at your earliest convenience. If you have any questions or need to reschedule, feel free to let us know.

We look forward to speaking with you.

Best regards,
${recruiterName}  
${companyName}  
${recruiterEmail}  

`);
      setSubject(
        `Interview Invitation: ${companyName} - ${position} - ${eventDate.format(
          "MMMM D, YYYY"
        )}`
      );
    }
  }, [
    selectedCandidate,
    eventDate,
    recruiterName,
    companyName,
    recruiterEmail,
    recruiterPhone,
    interviewType,
  ]);

  useEffect(() => {
    if (selectedCandidate) {
      fetchNotes(selectedCandidate.id);
    }
  }, [selectedCandidate]);

  const [notes, setNotes] = useState({});
  const [newNotes, setNewNotes] = useState({});
  useEffect(() => {
    const fetchAllNotes = async () => {
      if (candidates && candidates.length > 0) {
        const newNotes = {};
        for (const candidate of candidates) {
          await fetchNotesForCandidate(candidate.id, newNotes);
        }
        setNotes(newNotes);
        console.log("Notes:", newNotes);
      }
    };

    fetchAllNotes();
  }, [candidates]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleNoteHover = (note, event) => {
    setHoveredNote(note);
    setAnchorEl(event.currentTarget);
  };

  const handleNoteLeave = () => {
    setHoveredNote(null);
    setAnchorEl(null);
  };

  const fetchNotesForCandidate = async (candidateId, notesObj = null) => {
    if (candidateId) {
      try {
        const storedUserId =
          localStorage.getItem("userId") || getUserIdFromAccessToken();

        if (!storedUserId) {
          console.error("User ID is not available");
          return;
        }

        const response = await axios.get(
          `${apiUrl}messaging/applications/${candidateId}/notes/${storedUserId}/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );

        if (notesObj) {
          notesObj[candidateId] = response.data;
        } else {
          setNotes((prevNotes) => ({
            ...prevNotes,
            [candidateId]: response.data,
          }));
        }
      } catch (error) {
        console.error(
          `Error fetching notes for candidate ${candidateId}:`,
          error
        );
      }
    }
  };
  const fetchNotes = async (applicationId) => {
    try {
      const response = await axios.get(
        `${apiUrl}messaging/applications/${applicationId}/notes/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      setNotes(response.data);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const addNote = async (applicationId) => {
    let cardToAddNoteTo = null;
    for (const column of controlledBoard.columns) {
      cardToAddNoteTo = column.cards.find((card) => card.id === applicationId);
      if (cardToAddNoteTo) break;
    }

    if (!cardToAddNoteTo) {
      console.error("Cannot add note: Card not found");
      return;
    }

    const storedUserId =
      localStorage.getItem("userId") || getUserIdFromAccessToken();

    if (!storedUserId) {
      console.error("User ID is not available");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}messaging/applications/${applicationId}/notes/${storedUserId}/`,
        {
          note: newNotes[applicationId] || "",
          application: applicationId,
          recruiter: storedUserId,
          step: cardToAddNoteTo.step,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      setNotes((prevNotes) => ({
        ...prevNotes,
        [applicationId]: [...(prevNotes[applicationId] || []), response.data],
      }));
      setNewNotes((prevNewNotes) => ({
        ...prevNewNotes,
        [applicationId]: "",
      }));
    } catch (error) {
      console.error("Error adding note:", error);
      if (error.response) {
        console.error("Server response:", error.response.data);
      }
    }
  };
  const deleteNote = async (applicationId, noteId) => {
    const storedUserId =
      localStorage.getItem("userId") || getUserIdFromAccessToken();

    if (!storedUserId) {
      console.error("User ID is not available");
      return;
    }

    try {
      await axios.delete(
        `${apiUrl}messaging/applications/${applicationId}/notes/${storedUserId}/${noteId}/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      setNotes((prevNotes) => ({
        ...prevNotes,
        [applicationId]: prevNotes[applicationId].filter(
          (note) => note.id !== noteId
        ),
      }));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };
  // const connectToSocket = (userId) => {
  //   const signalingServerUrl = `wss://smart-hire.ma/ws/notif/${userId}/`;
  //   const newSignalingServer = new WebSocket(signalingServerUrl);

  //   newSignalingServer.onmessage = async (message) => {
  //     const data = JSON.parse(message.data);
  //     console.log("Message from server:", data);

  //     if (data.type === "notif") {
  //       // Handle notification
  //     }
  //   };

  //   // Save the signalingServer to the global state
  //   useSignalingStore.getState().setSignalingServer(newSignalingServer);

  //   return newSignalingServer;
  // };
  // const connectToSocketchat = (userId, receiver_id) => {
  //   if (!userId || !receiver_id) {
  //     console.error(
  //       "Invalid userId or receiver_id for chat WebSocket connection"
  //     );
  //     return null;
  //   }

  //   const signalingServerUrl2 = `wss://smart-hire.ma/ws/chat/${userId}/${receiver_id}/`;
  //   const newSignalingServer2 = new WebSocket(signalingServerUrl2);

  //   newSignalingServer2.onmessage = async (message) => {
  //     const data = JSON.parse(message.data);
  //     console.log("Message from server:", data);

  //     if (data.type === "chat_message") {
  //       // Handle chat message
  //       console.log("Received chat message:", data.message);
  //     }
  //   };

  //   // Use the setSignalingServer2 function from the store
  //   useSignalingStore.getState().setSignalingServer2(newSignalingServer2);

  //   return newSignalingServer2;
  // };
  const connectToSocket = (userId) => {

    const signalingServerUrl = `${apiUrlsoket}ws/notif/${userId}/`;
    const newSignalingServer = new WebSocket(signalingServerUrl);

    newSignalingServer.onmessage = async (message) => {
      const data = JSON.parse(message.data);
      console.log("Message from server:", data);

      if (data.type === "notif") {
        // Handle notification
      }
    };

    // Save the signalingServer to the global state
    useSignalingStore.getState().setSignalingServer(newSignalingServer);

    return newSignalingServer;
  };
  const connectToSocketchat = (userId, receiver_id) => {
    if (!userId || !receiver_id) {
      console.error(
        "Invalid userId or receiver_id for chat WebSocket connection"
      );
      return null;
    }


    const signalingServerUrl2 = `${apiUrlsoket}ws/chat/${userId}/${receiver_id}/`;
    const newSignalingServer2 = new WebSocket(signalingServerUrl2);

    newSignalingServer2.onmessage = async (message) => {
      const data = JSON.parse(message.data);
      console.log("Message from server:", data);

      if (data.type === "chat_message") {
        // Handle chat message
        console.log("Received chat message:", data.message);
      }
    };

    // Use the setSignalingServer2 function from the store
    useSignalingStore.getState().setSignalingServer2(newSignalingServer2);

    return newSignalingServer2;
  };
  useEffect(() => {
    const userId = getUserIdFromAccessToken();
    if (userId && candidateId && (!signalingServer || !signalingServer2)) {
      const newSignalingServer = connectToSocket(userId);
      setSignalingServer(newSignalingServer);

      const newSignalingServer2 = connectToSocketchat(userId, candidateId);
      setSignalingServer2(newSignalingServer2);
    }
  }, [signalingServer, signalingServer2, candidateId]);

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const [noteDetailsOpen, setNoteDetailsOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [allNotesOpen, setAllNotesOpen] = useState(false);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [hoverTimer, setHoverTimer] = useState(null);
  const showNoteDetails = (note, event) => {
    setHoveredNote(note);
  };

  const hideNoteDetails = () => {
    setHoveredNote(null);
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setNoteDetailsOpen(true);
  };

  const handleViewAllNotes = (cardId) => {
    setCurrentCardId(cardId);
    setAllNotesOpen(true);
  };

  const handleCloseNoteDetails = () => {
    setNoteDetailsOpen(false);
    setSelectedNote(null);
  };

  const handleCloseAllNotes = () => {
    setAllNotesOpen(false);
    setCurrentCardId(null);
  };
  console.log("Popover state:", {
    open: Boolean(noteDetailsAnchor),
    anchorEl: noteDetailsAnchor,
    hoveredNote,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (noteDetailsAnchor) {
      setIsPopoverOpen(true);
    } else {
      const timer = setTimeout(() => setIsPopoverOpen(false), 300);
      return () => clearTimeout(timer);
    }
  }, [noteDetailsAnchor]);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTemplate(selectedId);
    const selectedTemplateContent =
      templates.find((t) => t.id === selectedId)?.message || "";
    setEmailBody(selectedTemplateContent);
  };

  const handleNewTemplateChange = (event) => {
    const { name, value } = event.target;
    setNewTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddTemplate = () => {
    if (newTemplate.name && newTemplate.content) {
      setTemplates((prev) => [...prev, { id: Date.now(), ...newTemplate }]);
      setNewTemplate({ name: "", content: "" });
      postTemplate(newTemplate.name, newTemplate.content);
    }
  };

  const handleEditTemplate = (id) => {
    setEditingTemplateId(id);
    const template = templates.find((t) => t.id === id);
    if (template) {
      setNewTemplate({ name: template.title, content: template.message });
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      await updateTemplate(
        editingTemplateId,
        newTemplate.name,
        newTemplate.content
      );

      setTemplates((prev) =>
        prev.map((t) =>
          t.id === editingTemplateId
            ? { ...t, title: newTemplate.name, message: newTemplate.content }
            : t
        )
      );

      setEditingTemplateId(null);
      setNewTemplate({ name: "", content: "" });
      setOpenTemplate(false);

      toast.success("Template updated successfully");
    } catch (error) {
      console.error("Failed to update template:", error);
      toast.error("Failed to update template");
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      await deleteTemplate(id);

      setTemplates((prev) => prev.filter((t) => t.id !== id));
      if (selectedTemplate === id) {
        setSelectedTemplate("");
      }

      toast.success("Template deleted successfully");
    } catch (error) {
      console.error("Failed to delete template:", error);
      toast.error("Failed to delete template");
    }
  };

  const StyledSelect = styled(Select)(({ theme }) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  }));

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const CustomBoard = ({ columns = [], ...props }) => {
    return (
      <Board {...props}>
        {columns.map((column, index) => (
          <Board.Column
            key={column.id}
            {...column}
            header={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {column.title}
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => props.onAddStep(index)}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onRemoveStep(index);
                      console.log("index is :", index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            }
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {column.cards.map((card, cardIndex) => (
                  <Board.Card key={card.id} index={cardIndex} {...card}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {props.renderCard(card, { dragging: false })}
                      </div>
                    )}
                  </Board.Card>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Board.Column>
        ))}
      </Board>
    );
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Board
            onCardDragEnd={handleCardMove}
            disableColumnDrag
            renderCard={renderCard}
            renderColumnHeader={({ id, title }) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "8px",
                }}
              >
                {title}
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      addStep(title);
                    }}
                    title="Add new step"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeStep(title);
                      console.log("Deleting step:", title);
                    }}
                    title="Remove this step"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          >
            {controlledBoard}
          </Board>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(hoveredNote)}
        anchorEl={anchorEl}
        onClose={handleNoteLeave}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ pointerEvents: "none" }}
      >
        {hoveredNote && (
          <Paper elevation={3} sx={{ p: 2, maxWidth: 300 }}>
            <Typography>
              <img
                src={require("assets/img/note.png")}
                alt="Logo"
                style={{ width: "40px", marginRight: "16px" }}
              />
              {hoveredNote.note}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Created: {new Date(hoveredNote.date).toLocaleString()}
            </Typography>
            <br />
            <Typography variant="caption" color="text.secondary">
              Done in the {hoveredNote.step} {<br></br>} By{" "}
              {hoveredNote.recruiter_name}
            </Typography>
          </Paper>
        )}
      </Popover>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 16,
            padding: 24,
          },
        }}
      >
        <DialogTitle sx={{ pb: 3, borderBottom: "1px solid #e0e0e0" }}>
          <Typography variant="h5" fontWeight="bold">
            Add New Event
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Stack spacing={3}>
            <TextField
              autoFocus
              label="Event Title"
              fullWidth
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Event Date and Time"
                value={eventDate}
                onChange={(newValue) => setEventDate(newValue)}
                disablePast
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxChecked}
                  onChange={(e) => setCheckboxChecked(e.target.checked)}
                  color="primary"
                />
              }
              label="Send email notification"
            />
            {checkboxChecked && selectedCandidate && (
              <>
                <Typography variant="body2" color="text.secondary">
                  Recipient: {selectedCandidate.email}
                </Typography>
                <TextField
                  label="Subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  fullWidth
                  variant="outlined"
                />
                <Box sx={{ mb: 2 }}>
                  <StyledSelect
                    value={selectedTemplate}
                    onChange={handleSelectChange}
                    fullWidth
                    displayEmpty
                    variant="outlined"
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select a template</em>;
                      }
                      const template = templates.find((t) => t.id === selected);
                      return template ? template.title : "";
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select a template</em>
                    </MenuItem>
                    {templates.map((template) => (
                      <StyledMenuItem
                        key={template.id}
                        value={template.id} // Change this from template.message to template.id
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{template.title}</Typography>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditTemplate(template.id);
                              setOpenTemplate(true);
                            }}
                            sx={{ mr: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteTemplate(template.id);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </Box>
                <TextField
                  multiline
                  rows={6}
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="Email Body"
                />
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setAddTemplateDialogOpen(true)}
                  sx={{
                    alignSelf: "flex-start",
                    color: COLORS.signIn,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.Button_OnHold,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Add New Template
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 3, borderTop: "1px solid #e0e0e0" }}>
        <Button
          onClick={handleDialogClose}
          color="inherit"
          sx={{
            height: "100%",
            color: COLORS.signIn,
            border: "1px solid",
            borderColor: COLORS.signIn,
            bgcolor: COLORS.white,
            ":hover": {
              bgcolor: COLORS.Button_OnHold,
              borderColor: COLORS.signIn,
              color: COLORS.signIn,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="button"
          variant="contained"
          color="primary"
          sx={{
            height: "100%",
            color: COLORS.white,
            border: "1px solid",
            borderColor: COLORS.grey,
            bgcolor: COLORS.signIn,
            ":hover": {
              bgcolor: COLORS.Button_OnHold,
              borderColor: COLORS.signIn,
              color: COLORS.signIn,
            },
          }}
        >
          Add Event
        </Button>
      </DialogActions>
      </Dialog>

      <Dialog
        open={addTemplateDialogOpen}
        onClose={() => setAddTemplateDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add New Template</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="New Template Name"
            value={newTemplate.name}
            onChange={handleNewTemplateChange}
            fullWidth
            sx={{ marginTop: "10px" }}
          />
          <InputTextarea
            name="content"
            placeholder="New Template Content"
            value={newTemplate.content}
            onChange={handleNewTemplateChange}
            rows={5}
            style={{ width: "100%", marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddTemplateDialogOpen(false)}
            sx={{
              alignSelf: "flex-start",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.Button_OnHold,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddTemplate}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Add Template
          </Button>
          {/* <Button
            onClick={async () => {
              console.log("LinkedInAuth");
              try {
                console.log("window.location.origin", window.location.origin);
                window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86o1ech9y4in4m&redirect_uri=${window.location.origin}/talent/post/PostDetails/&state=1234&scope=openid%20email%20profile`;
              } catch (error) {
                console.error(
                  "Error redirecting to LinkedIn authentication:",
                  error
                );
              }
            }}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Add linkedin account
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openTemplate}
        fullWidth
        maxWidth="lg"
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 600 } }}
      >
        <DialogTitle>
          <Typography>Edit message template</Typography>
        </DialogTitle>
        <DialogContent>
          {editingTemplateId !== null && (
            <Box sx={{ marginTop: "10px" }}>
              <TextField
                name="name"
                label="Template Name"
                value={newTemplate.name}
                onChange={handleNewTemplateChange}
                fullWidth
                sx={{ marginBottom: "10px" }}
              />
              <InputTextarea
                name="content"
                value={newTemplate.content}
                onChange={handleNewTemplateChange}
                rows={5}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <Button
                onClick={handleUpdateTemplate}
                sx={{
                  height: "100%",
                  color: COLORS.white,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.signIn,
                  ":hover": {
                    bgcolor: COLORS.white,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
              >
                Update Template
              </Button>
              <Button
                sx={{
                  alignSelf: "flex-start",
                  color: COLORS.signIn,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.Button_OnHold,
                  ":hover": {
                    bgcolor: COLORS.white,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
                onClick={() => {
                  setEditingTemplateId(null);
                  setOpenTemplate(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={newStepDialog.open}
        onClose={() => setNewStepDialog({ ...newStepDialog, open: false })}
      >
        <DialogTitle>Add New Step</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Step Name"
            type="text"
            fullWidth
            value={newStepDialog.newStepName}
            onChange={(e) =>
              setNewStepDialog({
                ...newStepDialog,
                newStepName: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNewStepDialog({ ...newStepDialog, open: false })}
            sx={{
              alignSelf: "flex-start",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.Button_OnHold,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddNewStep}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={allNotesOpen}
        onClose={handleCloseAllNotes}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>All Notes</DialogTitle>
        <DialogContent>
          <List>
            {Object.values(notes[currentCardId] || []).map((note) => (
              <ListItem key={note.id}>
                <img
                  src={require("assets/img/note.png")}
                  alt="Logo"
                  style={{ width: "40px", marginRight: "16px" }}
                />
                <ListItemText
                  primary={note.note}
                  secondary={
                    <>
                      {`Created: ${new Date(note.date).toLocaleString()}`}
                      <br />
                      {`Step: ${note.step}`}
                      <br />
                      {`By: ${note.recruiter_name}`}
                    </>
                  }
                />
                <IconButton onClick={() => deleteNote(currentCardId, note.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAllNotes}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addTemplateDialogOpen}
        onClose={() => setAddTemplateDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add New Template</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="New Template Name"
            value={newTemplate.name}
            onChange={handleNewTemplateChange}
            fullWidth
            sx={{ marginTop: "10px" }}
          />
          <InputTextarea
            name="content"
            placeholder="New Template Content"
            value={newTemplate.content}
            onChange={handleNewTemplateChange}
            rows={5}
            style={{ width: "100%", marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddTemplateDialogOpen(false)}
            sx={{
              alignSelf: "flex-start",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.Button_OnHold,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddTemplate}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Add Template
          </Button>
          {/* <Button
            onClick={async () => {
              console.log("LinkedInAuth");
              try {
                console.log("window.location.origin", window.location.origin);
                window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86o1ech9y4in4m&redirect_uri=${window.location.origin}/talent/post/PostDetails/&state=1234&scope=openid%20email%20profile`;
              } catch (error) {
                console.error(
                  "Error redirecting to LinkedIn authentication:",
                  error
                );
              }
            }}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Add linkedin account
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openTemplate}
        fullWidth
        maxWidth="lg"
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 600 } }}
      >
        <DialogTitle>
          <Typography>Edit message template</Typography>
        </DialogTitle>
        <DialogContent>
          {editingTemplateId !== null && (
            <Box sx={{ marginTop: "10px" }}>
              <TextField
                name="name"
                label="Template Name"
                value={newTemplate.name}
                onChange={handleNewTemplateChange}
                fullWidth
                sx={{ marginBottom: "10px" }}
              />
              <InputTextarea
                name="content"
                value={newTemplate.content}
                onChange={handleNewTemplateChange}
                rows={5}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <Button
                onClick={handleUpdateTemplate}
                sx={{
                  height: "100%",
                  color: COLORS.white,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.signIn,
                  ":hover": {
                    bgcolor: COLORS.white,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
              >
                Update Template
              </Button>
              <Button
                sx={{
                  alignSelf: "flex-start",
                  color: COLORS.signIn,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.Button_OnHold,
                  ":hover": {
                    bgcolor: COLORS.white,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
                onClick={() => {
                  setEditingTemplateId(null);
                  setOpenTemplate(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
