import React, { useState, useRef } from 'react';
import { Box, Typography, Button, LinearProgress, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const FileItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const FileIcon = styled(InsertDriveFileIcon)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileUpload = ({ field, handleChange }) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map(file => ({
      file,
      name: file.name,
      size: file.size,
      progress: 0,
    }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
    handleChange({ target: { name: field.name, value: event.target.files } });

    // Simulate upload progress
    newFiles.forEach(fileObj => {
      const interval = setInterval(() => {
        setFiles(prevFiles => prevFiles.map(f => {
          if (f.name === fileObj.name) {
            const newProgress = Math.min(f.progress + 10, 100);
            if (newProgress === 100) clearInterval(interval);
            return { ...f, progress: newProgress };
          }
          return f;
        }));
      }, 500);
    });
  };

  const removeFile = (fileToRemove) => {
    setFiles(files => files.filter(file => file !== fileToRemove));
    handleChange({ target: { name: field.name, value: files.filter(file => file !== fileToRemove).map(f => f.file) } });
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <UploadBox onClick={openFileDialog}>
        <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          {field.label}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Click to select files
        </Typography>
        <HiddenInput
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          accept={field.accept}
          multiple={field.multiple}
        />
      </UploadBox>

      {files.length > 0 && (
        <FileList>
          {files.map((fileObj, index) => (
            <FileItem key={index}>
              <FileIcon />
              <ListItemText
                primary={fileObj.name}
                secondary={`${(fileObj.size / 1024 / 1024).toFixed(2)} MB`}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => removeFile(fileObj)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
              <Box sx={{ width: '100%', mt: 1, mr:3 }}>
                <LinearProgress variant="determinate" value={fileObj.progress} />
              </Box>
            </FileItem>
          ))}
        </FileList>
      )}
    </Box>
  );
};

export default FileUpload;