import React, { useState } from 'react';
import { Box, Typography, Chip, Divider, Card, CardContent, Grid, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { COLORS } from 'colors/color';
import { Calendar, FileText, ChevronDown, Eye, EyeOff, Check, X } from 'lucide-react';

const ExistingFormDisplay = ({ formData }) => {
  const [expanded, setExpanded] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);

  if (!formData) return null;

  const toggleExpanded = () => setExpanded(!expanded);
  const toggleShowAllFields = () => setShowAllFields(!showAllFields);

  const visibleFields = showAllFields ? formData.form_structure : formData.form_structure.slice(0, 5);

  return (
    <Card elevation={3} sx={{
      marginBottom: 4,
      backgroundColor: COLORS.white,
      borderRadius: 4,
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
      },
    }}>
      <Box sx={{
        backgroundColor: COLORS.signIn,
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="bold" color={COLORS.white}>
            {formData.title}
          </Typography>
          <Chip
            label={formData.is_active ? "Active" : "Inactive"}
            color={formData.is_active ? "success" : "error"}
            size="small"
            sx={{ fontWeight: 'bold', fontSize: '0.9rem', padding: '4px 8px' }}
          />
        </Box>
        <Typography variant="body1" color={COLORS.lightGrey}>
          {formData.description}
        </Typography>
      </Box>
      
      <CardContent>
        <Grid container spacing={3} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Calendar size={20} color={COLORS.signIn} />
              <Typography variant="body2" color="text.secondary">
                Created: {new Date(formData.created_at).toLocaleString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Calendar size={20} color={COLORS.signIn} />
              <Typography variant="body2" color="text.secondary">
                Updated: {new Date(formData.updated_at).toLocaleString()}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 3 }} />
        
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6" fontWeight="bold" color={COLORS.signIn} gutterBottom>
            Form Fields
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Total fields: {formData.form_structure.length}
          </Typography>
        </Box>

        {visibleFields.map((field, index) => (
          <Accordion key={index} expanded={expanded} onChange={toggleExpanded}>
            <AccordionSummary
              expandIcon={<ChevronDown size={20} color={COLORS.signIn} />}
              sx={{ 
                backgroundColor: index % 2 === 0 ? COLORS.lightGrey : 'transparent',
                '&:hover': { backgroundColor: COLORS.lightGrey },
              }}
            >
              <Typography sx={{ width: '40%', flexShrink: 0 }}>
                {field.label}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Type: {field.type}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    Required: {field.required ? <Check size={16} color="green" /> : <X size={16} color="red" />}
                  </Typography>
                </Grid>
                {field.options && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      Options: {field.options.join(', ')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

        {formData.form_structure.length > 5 && (
          <Button 
            onClick={toggleShowAllFields}
            startIcon={showAllFields ? <EyeOff size={20} /> : <Eye size={20} />}
            sx={{ 
              marginTop: 2,
              color: COLORS.signIn,
              '&:hover': { backgroundColor: COLORS.lightGrey },
            }}
          >
            {showAllFields ? 'Show Less' : 'Show All Fields'}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ExistingFormDisplay;