import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardMedia,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "colors/color";
import SvgComponent from "./SvgComponent.jsx";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdminProfile from "./adminProfile.jsx";
const apiUrl = process.env.REACT_APP_API_URL;

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "8px",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 200,
  objectFit: "cover",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

function getUserIdFromAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    console.error("No access token found in local storage");
    return null;
  }

  try {
    const decodedToken = parseJwt(accessToken);
    console.log("Access token : ", decodedToken);
    return decodedToken.user_id;
  } catch (error) {
    console.error("Failed to decode access token", error);
    return null;
  }
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  console.log("payload : ", jsonPayload);

  return JSON.parse(jsonPayload);
}

function RecruiterDetails({ recruiterId, onBack }) {
  // Destructure onBack here
  const [recruiter, setRecruiter] = useState(null);
  const [posts, setPosts] = useState([]);
  const adminId = getUserIdFromAccessToken();
  const [postFilter, setPostFilter] = useState("");

  useEffect(() => {
    if (adminId) {
      fetchRecruiterDetails();
      fetchRecruiterPosts();
    }
  }, [recruiterId, adminId]);

  const fetchRecruiterDetails = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}recruiters/admin/${adminId}/recruiters/${recruiterId}/`
      );
      setRecruiter(response.data);
    } catch (error) {
      console.error("Failed to fetch recruiter details", error);
    }
  };

  const fetchRecruiterPosts = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}recruiters/${recruiterId}/posts/`
      );
      setPosts(response.data);
    } catch (error) {
      console.error("Failed to fetch recruiter posts", error);
    }
  };

  if (!recruiter) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Card sx={{ margin: "2%", marginTop: "1%" }}>
        <CardContent>
          {/* Go Back Button */}
          <Box sx={{ margin: "2%" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                marginTop: "-2.78%",
                marginBottom: "-3.24%",
                color: COLORS.signIn,
                cursor: "pointer",
              }}
              onClick={onBack} // Use the onBack function
            >
              &larr;
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {/* Form Fields on the Left */}
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Company"
                    value={recruiter.company}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email address"
                    value={recruiter.email}
                    margin="normal"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={recruiter.first_name}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={recruiter.last_name}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    value={recruiter.address}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="City"
                    value={recruiter.city}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Country"
                    value={recruiter.country}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Postal Code"
                    value={recruiter.postal_code}
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Industry"
                    value={recruiter.comp_industry}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={recruiter.comp_description}
                    margin="normal"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* SVG Component on the Right */}
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  transform: "translateX(20%)", // Ensure perfect vertical centering
                }}
              >
                <SvgComponent />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ margin: "2%" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginTop: 3, color: COLORS.signIn }}
        >
          All Posts done by {recruiter.first_name} {recruiter.last_name}
        </Typography>
        <Grid container spacing={3}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <StyledCard>
                <StyledCardMedia
                  component="img"
                  image={post.image || "https://via.placeholder.com/300x200"}
                  alt={post.title}
                />
                <StyledCardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{ color: COLORS.signIn, fontWeight: "bold" }}
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    <span style={{ color: COLORS.grey, fontWeight: "bold" }}>
                      Deadline:{" "}
                    </span>
                    {post.deadline || "Not specified"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <span style={{ color: COLORS.grey, fontWeight: "bold" }}>
                      Total Applications:{" "}
                    </span>
                    {post.total_applications || 0}
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default RecruiterDetails;
