import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
`;

const Dot = styled('span')(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'inline-block',
  margin: '0 8px',
  animation: `${pulse} 1.5s infinite`,
}));

const AnimatedLoading = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <Box mb={2}>
      <Dot style={{ animationDelay: '0s' }} />
      <Dot style={{ animationDelay: '0.3s' }} />
      <Dot style={{ animationDelay: '0.6s' }} />
    </Box>
    <Typography variant="h6" color="textSecondary">
      Loading...
    </Typography>
  </Box>
);

export default AnimatedLoading;