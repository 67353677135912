import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import { Subject as SubjectIcon, Work as WorkIcon } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import DescriptionIcon from "@mui/icons-material/Description";
import SchoolIcon from "@mui/icons-material/School";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { styled } from "@mui/material/styles";
import { COLORS } from "colors/color";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.spacing(4),
  boxShadow: "0 10px 40px rgba(0,0,0,0.1)",
  marginBottom: theme.spacing(6),
  overflow: "visible",
  position: "relative",
  transition: "all 0.3s ease-in-out",
  background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 20px 60px rgba(0,0,0,0.15)",
  },
}));
const apiUrl = process.env.REACT_APP_API_URL;

const ScoreTriangle = styled(Box)(({ theme, score }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: 0,
  height: 0,
  borderStyle: "solid",
  borderWidth: "0 340px 200px 0",
  borderColor: `transparent ${getScoreColor(score)} transparent transparent`,
  zIndex: 2,
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
}));

const ScoreContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 15,
  right: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  zIndex: 3,
}));

const InfoIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: -10,
  right: -10,
  padding: 4,
  color: "#fff",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const ScoreText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "3rem",
  color: "#fff",
}));

const ScoreComment = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  color: "#fff",
  textAlign: "right",
  maxWidth: "180px",
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: 500,
  borderRadius: "20px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  "& .MuiChip-icon": {
    color: theme.palette.text.secondary,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  padding: theme.spacing(1.5, 3),
  fontWeight: 600,
  textTransform: "none",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
  },
}));

const getScoreColor = (score) => {
  if (score >= 75) return "#4caf50";
  if (score >= 45) return "#0befef";
  return "#f9510e";
};

const getScoreComment = (score) => {
  if (score >= 75) return "Excellent match!";
  if (score >= 45) return "Good potential";
  return "Consider carefully";
};

const CandidateCard = ({ candidate, resume_path, coverLetter }) => {
  const [age, setAge] = useState(0);
  useEffect(() => {
    if (!candidate || !candidate.candidate?.date_of_birth) return;

    const calculateAge = (dateOfBirth) => {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    };

    const age = calculateAge(candidate.candidate.date_of_birth);
    setAge(age);
  }, [candidate]);

  if (!candidate) return null;

  const handleResumeClick = async () => {
    console.log(resume_path);
    if (!resume_path) {
      alert("Resume  is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = resume_path.replace(/\\/g, "/");
    const fileURL = `${apiUrl}media/${normalizedResumePath}`;

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLetterClick = async () => {
    console.log(coverLetter);
    if (!coverLetter) {
      alert("Cover Letter is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = coverLetter.replace(/\\/g, "/");
    const fileURL = `${apiUrl}media/${normalizedResumePath}`;

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <StyledCard>
      <Tooltip title="AI-generated match score" arrow placement="top-end">
        <Box>
          <ScoreTriangle score={candidate.score_matching} />
          <ScoreContent>
            <Tooltip
              title="This score represents the AI-evaluated match between the job description and the candidate's resume. It considers factors such as skills, experience, and qualifications to determine the overall fit for the position."
              arrow
              placement="bottom"
            >
              <InfoIcon size="small">
                <InfoOutlinedIcon fontSize="small" />
              </InfoIcon>
            </Tooltip>
            <ScoreText>{candidate.score_matching}%</ScoreText>
            <ScoreComment>
              {getScoreComment(candidate.score_matching)}
            </ScoreComment>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <SmartToyIcon sx={{ fontSize: 14, mr: 0.5, color: "#fff" }} />
              <Typography
                variant="caption"
                sx={{ color: "#fff", fontSize: "0.7rem" }}
              >
                AI Evaluation
              </Typography>
            </Box>
          </ScoreContent>
        </Box>
      </Tooltip>
      <CardContent
        sx={{ p: { xs: 3, sm: 4, md: 5 }, pt: { xs: 6, sm: 7, md: 8 } }}
      >
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={candidate.candidate.image || "com.png"}
                alt={`${candidate.candidate.first_name} ${candidate.candidate.last_name}`}
                sx={{
                  width: 90,
                  height: 90,
                  mr: 2,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                }}
              />

              {/* <Typography variant="h6" color="text.secondary">
                {age}
              </Typography> */}

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                <Box>
                  <Typography variant="h5" fontWeight="bold">
                    {candidate.candidate.first_name}{" "}
                    {candidate.candidate.last_name}
                    {age !== 0 ? `, ${age}` : ""}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  <Typography variant="body1">
                    {candidate.candidate.family_status}
                  </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  <InfoChip
                    icon={<LocationOnIcon />}
                    label={candidate.candidate.city || "Location not specified"}
                    size="small"
                  />
                  <InfoChip
                    icon={<EmailIcon />}
                    label={candidate.candidate.email}
                    size="small"
                    onClick={() =>
                      (window.location.href = `mailto:${candidate.candidate.email}`)
                    }
                    sx={{ cursor: "pointer" }}
                  />
                  <InfoChip
                    icon={<LinkedInIcon />}
                    label={
                      candidate.candidate.linkedin_profile ||
                      "LinkedIn not specified"
                    }
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <div className="row">
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <SchoolIcon color={COLORS.signIn} />
                    </ListItemIcon>
                    <ListItemText
                      secondary="Academic Degree"
                      primary={candidate.candidate.academic_degree}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <SubjectIcon color={COLORS.signIn} />
                    </ListItemIcon>
                    <ListItemText
                      primary={candidate.candidate.field_of_study}
                      secondary="Field of Study"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <WorkIcon color={COLORS.signIn} />
                    </ListItemIcon>
                    <ListItemText
                      primary={candidate.candidate.last_post_occupied}
                      secondary="Last Position"
                    />
                  </ListItem>
                </List>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Application Status
              </Typography>
              <Chip
                label={candidate.step}
                sx={{
                  fontSize: "1rem",
                  padding: "20px 15px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  backgroundColor: COLORS.onHold_button,
                  color: COLORS.white,
                  boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                }}
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Applied on:{" "}
                {new Date(candidate.candidate.last_login).toLocaleDateString()}
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <div className="row" style={{ margin: "1%" }}>
              <div className="col-6">
                <ActionButton
                  variant="contained"
                  startIcon={<DescriptionIcon />}
                  onClick={handleResumeClick}
                  fullWidth
                  sx={{
                    // margin: "4%",
                    // width: "60%",
                    marginBottom: "2%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Resume
                </ActionButton>
              </div>
              <div className="col-6">
                <ActionButton
                  variant="outlined"
                  startIcon={<SchoolIcon />}
                  onClick={handleLetterClick}
                  fullWidth
                  sx={{
                    // margin: "4%",
                    // width: "60%",
                    color: "#000",
                    border: "1px solid",
                    borderColor: COLORS.SignIn,
                    bgcolor: "transparent",
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: "#000",
                      color: COLORS.SignIn,
                    },
                    "!important": true,
                  }}
                >
                  C_Letter
                </ActionButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

const CandidateCards = ({ candidates }) => {
  if (!Array.isArray(candidates)) {
    console.error("Candidates prop is not an array:", candidates);
    return null;
  }

  return (
    <Box>
      {candidates.map((candidate, index) => (
        <CandidateCard
          key={index}
          candidate={candidate}
          resume_path={candidate.resume_path}
          coverLetter={candidate.cover_letter}
        />
      ))}
    </Box>
  );
};

export default CandidateCards;
