import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Tooltip,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  Share as ShareIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const ExistingQuizzes = ({ apiUrl }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      const response = await fetch(`${apiUrl}recruiters/api/quizzes/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setQuizzes(data);
      }
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };

  const handleShare = (quiz) => {
    setSelectedQuiz(quiz);
    setShareDialogOpen(true);
  };

  const handleCopyLink = () => {
    if (selectedQuiz) {
      // Generate a shareable link using your frontend URL and quiz ID
      const shareableLink = `${window.location.origin}/take-quiz/${selectedQuiz.id}`;
      navigator.clipboard.writeText(shareableLink);
      setSnackbarMessage("Link copied to clipboard!");
      setSnackbarOpen(true);
      setShareDialogOpen(false);
    }
  };

  const handleDelete = async (quizId) => {
    if (window.confirm("Are you sure you want to delete this quiz?")) {
      try {
        const response = await fetch(
          `${apiUrl}recruiters/api/quizzes/${quizId}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          setQuizzes(quizzes.filter((quiz) => quiz.id !== quizId));
          setSnackbarMessage("Quiz deleted successfully");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error deleting quiz:", error);
        setSnackbarMessage("Error deleting quiz");
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Existing Quizzes
      </Typography>
      <List>
        {quizzes.map((quiz) => (
          <ListItem
            key={quiz.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #eee",
              py: 2,
            }}
          >
            <Box>
              <Typography variant="subtitle1">{quiz.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                Duration: {quiz.duration_minutes} mins | Passing Score:{" "}
                {quiz.passing_score}%
              </Typography>
            </Box>
            <Box>
              <Tooltip title="Share Quiz">
                <IconButton onClick={() => handleShare(quiz)}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Quiz">
                <IconButton onClick={() => handleDelete(quiz.id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItem>
        ))}
      </List>

      <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
        <DialogTitle>Share Quiz</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Share this quiz with candidates:
          </Typography>
          <TextField
            fullWidth
            value={
              selectedQuiz
                ? `${window.location.origin}/take-quiz/${selectedQuiz.id}`
                : ""
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCopyLink} startIcon={<CopyIcon />}>
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default ExistingQuizzes;
