import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';

const GlobalCityAutocomplete = ({ value, onChange, error, helperText }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const fetchCities = async (input) => {
    if (input.length < 2) {
      setOptions([]);
      return;
    }
    setLoading(true);
    setApiError(null);
    const url = `https://wft-geo-db.p.rapidapi.com/v1/geo/cities?namePrefix=${input}&limit=10&sort=-population`;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '18b206986amshb02dd3fe5d124c9p1f191ajsn25400d790d3b',
        'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
      }
    };
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data && data.data && Array.isArray(data.data)) {
        const cityOptions = data.data.map(city => ({
          label: `${city.city}, ${city.country}`,
          value: `${city.city}, ${city.countryCode}`
        }));
        setOptions(cityOptions);
      } else {
        setApiError('Unexpected response format from the API');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      setApiError(`Failed to fetch cities: ${error.message}`);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchCities = debounce(fetchCities, 300);

  useEffect(() => {
    debouncedFetchCities(inputValue);
    return () => {
      debouncedFetchCities.cancel();
    };
  }, [inputValue]);

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.value : null);
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Location"
          error={!!error || !!apiError}
          helperText={helperText || apiError}
          required
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      loading={loading}
      loadingText="Loading cities..."
      noOptionsText={inputValue.length < 2 ? "Type to search cities" : (apiError ? "Error loading cities" : "No cities found")}
    />
  );
};

export default GlobalCityAutocomplete;