import React from 'react';
import { 
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  Stack,
  Fade,
  Divider,
  useTheme
} from '@mui/material';
import { 
  CheckCircle as CheckCircleIcon,
  ArrowBack as ArrowBackIcon,
  Dashboard as DashboardIcon,
  Email as EmailIcon,
  AccessTime as AccessTimeIcon,
  RateReview as RateReviewIcon
} from '@mui/icons-material';

const ApplicationSuccess = ({ onBackToList, applicationDetails }) => {
  const theme = useTheme();

  const steps = [
    {
      icon: <RateReviewIcon />,
      title: 'Application Review',
      description: 'Our team will carefully review your submission'
    },
    {
      icon: <EmailIcon />,
      title: 'Email Updates',
      description: `You'll receive updates about your application status`
    },
    {
      icon: <AccessTimeIcon />,
      title: 'Response Time',
      description: 'We typically respond within 2-3 business days'
    }
  ];

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Fade in timeout={800}>
        <Card 
          elevation={3}
          sx={{
            borderRadius: 2,
            overflow: 'visible',
            position: 'relative'
          }}
        >
          {/* Success Icon */}
          <Box
            sx={{
              position: 'absolute',
              top: -32,
              left: '50%',
              transform: 'translateX(-50%)',
              bgcolor: 'background.paper',
              borderRadius: '50%',
              p: 1,
            }}
          >
            <CheckCircleIcon 
              sx={{ 
                fontSize: 64,
                color: 'success.main',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                }
              }} 
            />
          </Box>

          <CardContent sx={{ pt: 6 }}>
            {/* Header */}
            <Box sx={{ textAlign: 'center', mb: 4, mt: 2 }}>
              <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                Application Submitted!
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Thank you for your application. We'll be in touch soon.
              </Typography>
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* What's Next Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom fontWeight="medium">
                What happens next?
              </Typography>
              <Stack spacing={2}>
                {steps.map((step, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: 'grey.50',
                      transition: 'all 0.2s',
                      '&:hover': {
                        bgcolor: 'grey.100',
                        transform: 'translateY(-2px)',
                      }
                    }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box
                        sx={{
                          color: 'primary.main',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {step.icon}
                      </Box>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          {step.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {step.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Box>

            {/* Action Buttons */}
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              sx={{ mt: 4 }}
              justifyContent="center"
            >
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={onBackToList}
                size="large"
              >
                Back to List
              </Button>
              <Button
                variant="contained"
                startIcon={<DashboardIcon />}
                onClick={() => window.location.href = '/home'}
                size="large"
              >
                Go to Dashboard
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Fade>
    </Container>
  );
};

export default ApplicationSuccess;