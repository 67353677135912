// // C:\Users\youne\OneDrive\Desktop\younes_project\ATS_PROJECT-\front-end\src\views\quiz\quizApi.jsx
// import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;

// const quizApi = {
//   createQuiz: async (quizData) => {
//     const response = await axios.post(
//       `${API_URL}recruiters/api/quizzes/`,
//       quizData,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`, // or however you store your token
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     return response.data;
//   },

//   getRecruiterQuizzes: async () => {
//     const response = await axios.get(`${API_URL}recruiters/api/quizzes/`);
//     return response.data;
//   },

//   getQuizDetails: async (quizId) => {
//     const response = await axios.get(
//       `${API_URL}recruiters/api/quizzes/${quizId}/`
//     );
//     return response.data;
//   },

//   // Candidate endpoints
//   getQuizByCode: async (accessCode) => {
//     const response = await axios.get(
//       `${API_URL}recruiters/api/quizzes/${accessCode}/`
//     );
//     return response.data;
//   },

//   submitQuizResponses: async (quizId, responses) => {
//     const response = await axios.post(
//       `${API_URL}recruiters/api/quizzes/${quizId}/submit_response/`,
//       responses
//     );
//     return response.data;
//   },
// };

// export default quizApi;
// C:\Users\youne\OneDrive\Desktop\younes_project\ATS_PROJECT-\front-end\src\views\quiz\quizApi.jsx
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const quizApi = {
  createQuiz: async (quizData) => {
    try {
      // Changed from "token" to "accessToken"
      const token = localStorage.getItem("accessToken");

      console.log(quizData);
      console.log("API URL:", `${API_URL}recruiters/api/quizzes/`);
      console.log("Token present:", !!token);
      console.log("Token:", token?.substring(0, 50) + "...");

      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.post(
        `${API_URL}recruiters/api/quizzes/`,
        quizData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      // Enhanced error logging
      console.error("Error details:", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        headers: error.response?.headers,
      });

      if (error.response?.status === 401) {
        console.error(
          "Authentication error - token might be invalid or expired"
        );
      }

      throw error;
    }
  },

  getRecruiterQuizzes: async () => {
    const response = await axios.get(`${API_URL}recruiters/api/quizzes/`);
    return response.data;
  },

  getQuizDetails: async (quizId) => {
    const response = await axios.get(
      `${API_URL}recruiters/api/quizzes/${quizId}/`
    );
    return response.data;
  },

  // Candidate endpoints
  getQuizByCode: async (accessCode) => {
    const response = await axios.get(
      `${API_URL}recruiters/api/quizzes/${accessCode}/`
    );
    return response.data;
  },

  submitQuizResponses: async (quizId, responses) => {
    const response = await axios.post(
      `${API_URL}recruiters/api/quizzes/${quizId}/submit_response/`,
      responses
    );
    return response.data;
  },
};

export default quizApi;
