// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Box, Typography, Button, Container, Paper} from '@mui/material';
// import { styled, keyframes } from '@mui/system';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import HomeIcon from '@mui/icons-material/Home';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import { createTheme } from '@mui/material/styles';
// import { ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//     palette: {
//       primary: {
//         main: '#1976d2',
//       },
//       secondary: {
//         main: '#fff',
//       },
//       // other properties
//     },
//     // other customizations
//   });
// const gradient = keyframes`
//   0% { background-position: 0% 50%; }
//   50% { background-position: 100% 50%; }
//   100% { background-position: 0% 50%; }
// `;

// const float = keyframes`
//   0%, 100% { transform: translateY(0) rotate(0deg); }
//   50% { transform: translateY(-20px) rotate(180deg); }
// `;

// const shimmer = keyframes`
//   0% { background-position: -200% 50%; }
//   100% { background-position: 200% 50%; }
// `;

// const Background = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   inset: 0,
//   background: `linear-gradient(to bottom right, ${theme.palette.primary.main}20, transparent, ${theme.palette.secondary.main}20)`,
//   animation: `${gradient} 15s ease infinite`,
//   backgroundSize: '400% 400%',
// }));

// const FloatingShape = styled(Box)({
//   position: 'absolute',
//   width: 32,
//   height: 32,
//   borderRadius: '50%',
//   backgroundColor: 'rgba(255, 255, 255, 0.05)',
//   backdropFilter: 'blur(4px)',
//   animation: `${float} 8s ease-in-out infinite`,
// });

// const GlassCard = styled(Paper)(({ theme }) => ({
//   backdropFilter: 'blur(16px)',
//   backgroundColor: 'rgba(255, 255, 255, 0.1)',
//   borderRadius: theme.shape.borderRadius * 2,
//   padding: theme.spacing(4),
//   border: '1px solid rgba(255, 255, 255, 0.1)',
// }));

// const ShimmerText = styled(Typography)(({ theme }) => ({
//   backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
//   WebkitBackgroundClip: 'text',
//   WebkitTextFillColor: 'transparent',
//   backgroundSize: '200% auto',
//   animation: `${shimmer} 3s linear infinite`,
// }));

// const ActionButton = styled(Button)(({ theme, variant }) => ({
//   padding: theme.spacing(1.5, 3),
//   borderRadius: theme.shape.borderRadius * 2,
//   textTransform: 'none',
//   ...(variant === 'contained' && {
//     background: `${theme.palette.primary.main}`,
//     '&:hover': {
//       boxShadow: `0 0 20px ${theme.palette.primary.main}66`,
//     },
//   }),
//   ...(variant === 'outlined' && {
//     backdropFilter: 'blur(4px)',
//     backgroundColor: 'rgba(255, 255, 255, 0.1)',
//     borderColor: 'rgba(255, 255, 255, 0.1)',
//     color: theme.palette.common.black,
//     '&:hover': {
//       backgroundColor: 'rgba(255, 255, 255, 0.2)',
//     },
//   }),
// }));

// const ModernNotFound = () => {
//   const navigate = useNavigate();

//   return (
//     <ThemeProvider theme={theme}>
//     <Box
//       sx={{
//         minHeight: '100vh',
//         width: '100%',
//         bgcolor: 'background.default',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         p: 2,
//         position: 'relative',
//         overflow: 'hidden',
//       }}
//     >
//       <Background />

//       {[...Array(20)].map((_, i) => (
//         <FloatingShape
//           key={i}
//           sx={{
//             left: `${Math.random() * 100}%`,
//             top: `${Math.random() * 100}%`,
//             animationDelay: `${Math.random() * 5}s`,
//             transform: `scale(${Math.random() * 0.5 + 0.5})`,
//           }}
//         />
//       ))}

//       <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
//         <Box sx={{ textAlign: 'center', mb: 4 }}>
//           <ShimmerText variant="h1" sx={{ fontSize: { xs: '120px', sm: '200px' }, fontWeight: 'bold' }}>
//             404
//           </ShimmerText>
//         </Box>

//         <GlassCard>
//           <Typography variant="h4" color="text.primary" gutterBottom>
//             Page not found
//           </Typography>
//           <Typography variant="body1" color="text.secondary" paragraph>
//             The page you're looking for doesn't exist or has been moved.
//             Check the URL or try navigating back.
//           </Typography>

//           <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, justifyContent: 'center', mt: 4 }}>
//             <ActionButton
//               variant="outlined"
//               startIcon={<ArrowBackIcon />}
//               onClick={() => navigate(-1)}
//             >
//               Go Back
//             </ActionButton>
//             <ActionButton
//               variant="contained"
//               startIcon={<HomeIcon />}
//               onClick={() => navigate('/')}
//             >
//               Home Page
//             </ActionButton>
//             <ActionButton
//               variant="outlined"
//               startIcon={<RefreshIcon />}
//               onClick={() => window.location.reload()}
//             >
//               Reload
//             </ActionButton>
//           </Box>
//         </GlassCard>
//       </Container>
//     </Box></ThemeProvider>
//   );
// };

// export default ModernNotFound;

import React, { useEffect, useState } from "react";
import bgImage from "../../assets/img/404bg3.jpg";
import ButtonAppBar from "components/Navbars/navBar";
import Footer from "views/client/Footer";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0),
  backgroundColor: "white",
  padding: theme.spacing(0.5),
  borderRadius: "50px",
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.08)",
  width: "100%",
  maxWidth: "40%",
  margin: "0 auto",
  position: "absolute", // Change to absolute positioning
  top: "70%", // Adjust this value to move the search box up or down
  left: "63%",
  transform: "translate(-50%, -50%)", // Center the box horizontally and vertically
  zIndex: 2,
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
    position: "relative", // Reset positioning for mobile
    top: "auto",
    left: "auto",
    transform: "none",
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  fontFamily: "Poppins",
  textTransform: "none",
  fontSize: "30px",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 0,
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));
const SearchButton = styled(Button)(({ theme }) => ({
  borderRadius: "100px",
  height: "48px",
  padding: "5% 15%",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
  backgroundColor: "#0085FF",
  fontSize: "13px",
  color: "white",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "0 4px 12px rgba(0, 102, 255, 0.3)",
  },
}));
function PageNotFound() {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const showSnackbar = (message, severity = "info") => {
    setSnackbarState({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState(prev => ({ ...prev, open: false }));
  };

  useEffect(() => {
    setIsAuth(!!localStorage.getItem("accessToken"));
  }, []);

  return (
    <>
      <ButtonAppBar hideWhileScrolling={200} isAuth={isAuth} />
      <div style={{ display: "flex", height: "100vh", width: "100%" }}>
        <div
          style={{
            flex: 1,
            zIndex: -1,
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "transparent",
            transform: "scale(1.1)",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flex: 1,
            paddingRight: "4%",
            paddingBottom: 40,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              color="#0085FF"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              404 error
            </Typography>
            <Typography
              variant="h1"
              color="#0085FF"
              sx={{
                fontWeight: "600",
                fontFamily: "Poppins",
                fontSize: "80px",
              }}
            >
              Ooops!
            </Typography>
            <Typography
              variant="body1"
              color="#00000080"
              sx={{ fontFamily: "Poppins", fontWeight: 200, fontSize: "18px" }}
            >
              Sorry, the page you are looking for doesn’t exist
            </Typography>
            <Typography
              variant="body1"
              color="#00000080"
              sx={{ fontFamily: "Poppins", fontWeight: 200, fontSize: "18px" }}
            >
              or has been removed
            </Typography>
            <SearchBox>
              <StyledTextField
                sx={{ fontFamily: "Poppins", fontSize: "35px " }}
                fullWidth
                placeholder="Search by keywords, job title..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 2 }}>
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                }}
              />
              <SearchButton
                onClick={() => {
                  if (searchTerm) {
                    navigate("/home", { state: { searchTerm } });
                  } else {
                    showSnackbar("Please enter a search term", "warning"); 
                  }
                }}
                variant="contained"
                type="submit"
                sx={{ textTransform: "none", fontFamily: "Poppins" }}
              >
                Search
              </SearchButton>
            </SearchBox>
          </Grid>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={snackbarState.severity === "success" ? 2000 : 3000}
        onClose={handleCloseSnackbar}
        message={
          <span style={{ color: "black" }}>
            {snackbarState.message}
          </span>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(3px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)",
          },
        }}
      />
    </>
  );
}

export default PageNotFound;
