import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Chip,
  Switch,
  Alert, AlertTitle, Autocomplete,
  Snackbar, Backdrop,
  Container,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WorkIcon from "@mui/icons-material/Work";
import { ControlledBoard } from "../Candidates/board";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DataTable from "./DataTable";
import { COLORS } from "colors/color";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CandidateApplicationsList from "views/Candidates/CandidateApplicationsList";
import RecruiterCandidatesApplications from "views/Candidates/RecruiterCandidatesApplications ";
import RecruiterCandidatesApplicationsPDF from "views/Candidates/RecruiterCandidatesApplicationsPDF";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ToggleButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? COLORS.signIn : COLORS.white,
  color: active ? COLORS.white : COLORS.signIn,
  border: `1px solid ${COLORS.signIn}`,
  "&:hover": {
    backgroundColor: active ? COLORS.signIn : COLORS.Button_OnHold,
    // opacity: 0.9,
  },
  padding: "8px 16px",
  fontWeight: "bold",
  textTransform: "none",
  transition: "all 0.3s ease",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: COLORS.Button_OnHold,
  color: COLORS.signIn,
  borderColor: COLORS.signIn,
  border: `1px solid ${COLORS.signIn}`,
  "&:hover": {
    backgroundColor: COLORS.signIn,
    color: "white",
  },
  padding: "10px 20px",
  marginLeft: '2%',
  borderRadius: "8px",
  textTransform: "none",
  fontWeight: "bold",
  fontSize: '10px',
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  transition: "all 0.3s ease",
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
}));

const ProfileImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "220px", // Increased height to accommodate title
}));

const ProfileImage = styled(CardMedia)(({ theme }) => ({
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.7) 100%)",
  },
}));

const JobTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  color: "white",
  fontSize: "3.5rem",
  fontWeight: 700,
  textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  zIndex: 1,
}));

const DeadlineChip = styled(Chip)(({ theme }) => ({

  color: "#000",
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  // color: theme.palette.primary.main,
  fontWeight: 600,
  zIndex: 1,
}));

const CardContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 220px)", // Subtract image wrapper height
  overflow: "hidden",
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(3),
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#c1c1c1",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#a8a8a8",
    },
  },
}));
const RequirementChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: COLORS.Button_OnHold,
}));

const PostDetails = ({ post, onBack, apiUrl }) => {
  const [data, setData] = useState([]);
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("candidates");

  const [steps, setSteps] = useState([]);
  const [stepId, setStepId] = useState();
  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());
  const userId = getUserIdFromAccessToken();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState("");
  const [isScrappingLaunched, setIsScrappingLaunched] = useState(false);
  const [state, statechange] = useState(false);
  const [finishing, setfinishing] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showStopMessage, setShowStopMessage] = useState(false);
  const intervalIdRef = useRef(null);


  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }
  }
  // <<<<<<< HEAD
  //     try {
  //       const decodedToken = parseJwt(accessToken);
  //       console.log("Access token : ", decodedToken);
  //       const userRole = decodedToken.role;
  //       return userRole;
  //     } catch (error) {
  //       console.error("Failed to decode access token", error);
  //       return null;
  //     }
  //   }
  //   function parseJwt(token) {
  //     const base64Url = token.split(".")[1];
  //     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //     const jsonPayload = decodeURIComponent(
  //       atob(base64)
  //         .split("")
  //         .map(function (c) {
  //           return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //         })
  //         .join("")
  //     );
  //     console.log("payload : ", jsonPayload);

  //     return JSON.parse(jsonPayload);
  //   }


  //   useEffect(() => {
  //     if (userRole !== "r") {
  //       console.log("User is not a recruiter. Navigating to home.");
  //       navigate("/home");
  //     }
  //   });
  // =======


  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const [fullStepData, setFullStepData] = useState([]);

  useEffect(() => {
    if (userRole !== "r") {
      console.log("User is not a recruiter. Navigating to home.");
      navigate("/home");
    }
  }, [userRole, navigate]);

  useEffect(() => {
    Load();
  }, [post]);

  useEffect(() => {
    const fetchSteps = async () => {
      console.log("UserId :", userId);
      try {
        const response = await fetch(
          `${apiUrl}recruiters/steps/?recruiter=${userId}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (Array.isArray(data)) {
          const stepTitles = data.map((step) => step.step);
          setSteps(stepTitles);

          const fullStepData = data.map((step) => ({
            id: step.id,
            title: step.step,
          }));
          setFullStepData(fullStepData);

          console.log("Step titles:", stepTitles);
          console.log("Full step data:", fullStepData);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching steps:", error);
      }
    };
    fetchSteps();
  }, [apiUrl, userId]);

  const deleteSteps = async (stepTitle) => {
    if (!stepTitle) return; // Don't do anything if stepTitle is null or undefined

    console.log("Deleting step:", stepTitle);

    const stepToDelete = fullStepData.find((step) => step.title === stepTitle);
    if (stepToDelete) {
      try {
        const response = await fetch(
          `${apiUrl}recruiters/steps/${stepToDelete.id}/delete/?recruiter=${userId}`,
          {
            method: "DELETE",
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Step deleted:", data);

        // Update steps after deletion
        setSteps((prevSteps) => prevSteps.filter((step) => step !== stepTitle));

        // Update fullStepData after deletion
        setFullStepData((prevData) =>
          prevData.filter((step) => step.title !== stepTitle)
        );
      } catch (error) {
        console.error("Error deleting step:", error);
      }
    } else {
      console.error("Step not found for deletion:", stepTitle);
    }
  };
  useEffect(() => {
    console.log('post id :', post.id)
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code && window.opener) {
      window.opener.postMessage({ code: code }, window.location.origin);
      window.close();
    }
  }, []);
  const createStep = (stepName) => {
    const addSteps = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}recruiters/steps/create/`,
          {
            step: stepName,
            recruiter: userId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );

        console.log("Step added successfully:", response.data);

        // Update steps and fullStepData only if the step was successfully added to the database
        if (response.data && response.data.id) {
          setSteps((prevSteps) => {
            // Check if the step already exists to prevent duplication
            if (!prevSteps.includes(stepName)) {
              return [...prevSteps, stepName];
            }
            return prevSteps;
          });

          setFullStepData((prevData) => {
            // Check if the step already exists to prevent duplication
            if (!prevData.some((step) => step.title === stepName)) {
              return [...prevData, { id: response.data.id, title: stepName }];
            }
            return prevData;
          });
        }
      } catch (error) {
        if (error.response) {
          console.error("Error adding step:", error.response.data);
        } else {
          console.error("Error adding step:", error.message);
        }
      }
    };
    addSteps();
  };

  const Scrapping = async () => {
    try {
      // Start the scraping process
      console.log('here is the post id: ', post.id)
      const response = await fetch(`${apiUrl}ai/start_scraping_post/${post.id}/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        return;
      }

      const result = await response.json();
      console.log("Scraping task initiated: ", result);

      if (result.state) {
        // Task was successfully added to the queue, now start polling for status

        // Clear any existing interval before starting a new one
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
        pollScrapingStatus(post.id);
      } else {
        console.error("Failed to start scraping: ", result.message);
      }
    } catch (error) {
      console.error("Error initiating scraping:", error);
    }
  };
  const pollScrapingStatus = async (id) => {
    try {
      console.log('before the 30s');
      await new Promise(resolve => setTimeout(resolve, 30000));
      console.log('after the 30s');
      
      // Store the interval ID in the ref
      intervalIdRef.current = setInterval(async () => {
        try {
          const response = await fetch(`${apiUrl}ai/get_scraping_status/${post.id}/`, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          });

          const result = await response.json();
          console.log("Polling result: ", result);
          
          if (result.state !== 'pending') {
            // Clear the interval when scraping is complete
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            
            setIsScrappingLaunched(false);
            statechange(false);
            setfinishing(true);

            if (result.state) {
              console.log("Scraping succeeded: ", result.message);
            } else {
              console.error("Scraping failed: ", result.message);
            }
          }
        } catch (error) {
          console.error("Error in polling interval:", error);
          // Clear interval on error to prevent continued failing requests
          if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
          }
        }
      }, 5000);
    } catch (error) {
      console.error("Error polling scraping status:", error);
    }
  };

  // Clean up the interval when the component unmounts
  React.useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  const code_outlook = async () => {
    try {
      const response = await fetch(`${apiUrl}ai/code_outlook/${post.id}/`);
      const result = await response.json();
      console.log("code : ", result.user_code);
      setCode(result.user_code);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function openAuthPopup() {
    const authUrl =
      "https://login.microsoftonline.com/common/oauth2/deviceauth";
    const width = window.screen.width / 3;
    const height = window.screen.height;
    const left = window.screen.width - width;
    const top = 0;

    window.open(
      authUrl,
      "authPopup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };
  const hidde_finishing = () => {
    // Clear the interval if it exists
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    setfinishing(false);
    statechange(false);
    setIsScrappingLaunched(false);
    setShowStopMessage(true);
    
  };
  const handleCloseStopMessage = () => {
    setShowStopMessage(false);
  };
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch(() => {
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarOpen(true);
    }
  };
  // const handleSnackbarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackbarOpen(false);
  // };

  async function Load() {
    try {
      const response = await fetch(`${apiUrl}posts/${post.id}/findCandidate/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 401) {
        // Handle unauthorized access
        return;
      }
      const json = await response.json();
      setData(json);
      Load_applications();
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }

  async function Load_applications() {
    try {
      const response = await fetch(
        `${apiUrl}authentication/candidates-scraped-for-post/${post.id}/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.status === 401) {
        return;
      }
      const json = await response.json();
      setApplications(json);
    } catch (error) {
      console.error("Error loading applications:", error);
    }
  }

  // const handleSwitchChange = (event) => {
  //   setIsSwitchChecked(event.target.checked);
  // };

  const updateApplicationStep = async (applicationId, newStep) => {
    console.log("updatedApplications : ", applicationId, newStep);

    try {
      const response = await axios.patch(
        `${apiUrl}authentication/applications/${applicationId}/update-step/`,
        { step: newStep },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        const updatedApplications = applications.map((app) =>
          app.id === applicationId ? { ...app, step: newStep } : app
        );
        setApplications(updatedApplications);
        console.log("updatedApplications : ", updatedApplications);
      }
    } catch (error) {
      console.error("Error updating application step:", error);
    }
  };

  const visualizePost = () => {
    console.log('visualizePost clicked!!')
    navigate(`/viewjob/${post.id}`);
  };
  const copyViewJobUrl = () => {
    const url = `${window.location.origin}/viewjob/${post.id}`;
    navigator.clipboard.writeText(url).then(() => {
      setShowSnackbar(true);
    });
  };
  useEffect(() => {
    let timer;
    if (state) {
      // Wait 3 seconds before showing the loading overlay
      timer = setTimeout(() => {
        setShowLoading(true);
      }, 5000);
    } else {
      setShowLoading(false);
    }

    // Cleanup timer on component unmount or when state changes
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state]);
  useEffect(() => {
    if (finishing) {
      setOpenSnackbar(true);
    }
  }, [finishing]);

  // Add this handler for closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <Container maxWidth="xl">
      <Box mt={3} mb={3} >
        <StyledButton onClick={onBack} startIcon={<ArrowBackIcon />}>
          Back to Posts
        </StyledButton>
        <StyledButton startIcon={<AutoModeIcon />}
          sx={{
            backgroundColor: "#76d7c4", // Blue background color
            '&:hover': {
              backgroundColor: "#000000", // Darker blue on hover
            },
          }}

          onClick={() => {
            statechange(true);
            Scrapping();
          }}
          onMouseUp={() => {
            setTimeout(() => {
              code_outlook();
              setIsScrappingLaunched(true);
            }, 2000);
            setTimeout(() => {
              openAuthPopup();
            }, 5000);
          }}
        >
          Scraping
        </StyledButton>
        {isScrappingLaunched ? (
          <>
            <StyledButton >
              {code}
            </StyledButton>
            <Tooltip title={copied ? "Copied!" : "Copy code"}>
              <IconButton
                onClick={handleCopy}
                size="small"
                sx={{ ml: 2 }}
              >
                {copied ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <ContentCopyIcon />
                )}
              </IconButton>
            </Tooltip> </>
        ) : (
          <>
          </>
        )}

      </Box>

      <div className="col-md-4 text-right mr-2">
        {/* Full screen loading overlay */}
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: 'column',
            gap: 2
          }}
          open={showLoading} // Use showLoading instead of state
        >
          <CircularProgress
            size={60}
            thickness={4}
            sx={{ color: '#4CAF50' }}
          />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            padding: '10px 20px',
            borderRadius: '8px',
            gap: '10px'
          }}>
            <span style={{ fontSize: '1.2rem' }}>Scraping in progress...</span>
            <IconButton
              size="small"
              onClick={hidde_finishing}
              sx={{ color: 'white', marginLeft: '10px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>

        </Backdrop>
          <Snackbar
            open={showStopMessage}
            onClose={handleCloseStopMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseStopMessage}
              severity="warning"
              sx={{
                width: '100%',
                '& .MuiAlert-message': {
                  fontSize: '1rem'
                }
              }}
            >
              <AlertTitle>Scraping Stopped</AlertTitle>
              The scraping process has been stopped.
            </Alert>
          </Snackbar>

        {/* Success Toast */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{
              width: '100%',
              '& .MuiAlert-message': {
                fontSize: '1rem'
              }
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Scraping completed successfully!
          </Alert>
        </Snackbar>
      </div>

      {/* <Box sx={{ height: '60%', overflow: 'hidden' }}> */}
      <Box sx={{ height: "550px", overflow: "hidden" }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={12} md={5} sx={{ height: "100%" }}>
            <ProfileCard>
              <ProfileImageWrapper>
                <ProfileImage image={post.image} title={post.title} />
                <JobTitle variant="h4">{post.title}</JobTitle>
                <DeadlineChip
                  icon={<AccessTimeIcon />}
                  label={`Deadline: ${post.deadline}`}
                />
              </ProfileImageWrapper>
              <CardContentWrapper>
                <ScrollableContent>
                  <Typography
                    variant="body1"
                    sx={{ mb: 3, color: "text.secondary" }}
                  >
                    {post.description}
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Requirements
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {(typeof post.requirements === "string"
                        ? post.requirements.split(",")
                        : post.requirements
                      ).map((req, index) => (
                        <RequirementChip
                          key={index}
                          label={req.trim()}
                          icon={<WorkIcon />}
                        />
                      ))}
                    </Box>
                  </Box>
                </ScrollableContent>
              </CardContentWrapper>
            </ProfileCard>
          </Grid>
          <Grid item xs={12} md={7} sx={{ height: "550px" }}>
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726420063/awacl8mfxyuyifngpskf.png"
                alt=""
                sx={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  maxHeight: "100%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* </Box> */}
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" fontWeight="bold">Scraped Candidates</Typography>
              <Box>
                <ToggleButton
                  active={viewMode === "candidates"}
                  onClick={() => setViewMode("candidates")}
                >
                  Refresh
                </ToggleButton>
                {/* <ToggleButton
                  active={viewMode === "board"}
                  onClick={() => setViewMode("board")}
                >
                  Steps Board
                </ToggleButton> */}
              </Box>
            </Box>
            {viewMode === "candidates" ? (
              <DataTable candidates={applications} post_title={post.title} knob={true} />
            ) : (
              <ControlledBoard
                candidates={applications}
                onUpdateStep={updateApplicationStep}
                deleteSteps={deleteSteps}
                setSteps={setSteps}
                setStepId={setStepId}
                createStep={createStep}
                steps={steps}
                post={post}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PostDetails;
