// C:\Users\youne\OneDrive\Desktop\younes_project\ATS_PROJECT-\front-end\src\views\quiz\QuizResults.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import quizApi from "./quizApi";
const QuizResults = () => {
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQuizResults();
  }, [quizId]);

  const fetchQuizResults = async () => {
    try {
      const [quizData, responsesData] = await Promise.all([
        quizApi.getQuizDetails(quizId),
        quizApi.getQuizResponses(quizId),
      ]);
      setQuiz(quizData);
      setResponses(responsesData);
      setLoading(false);
    } catch (error) {
      setError("Failed to load quiz results");
      setLoading(false);
    }
  };

  const calculateScore = (candidateResponses) => {
    let totalPoints = 0;
    let earnedPoints = 0;

    quiz.questions.forEach((question) => {
      totalPoints += question.points;
      const response = candidateResponses.find(
        (r) => r.question === question.id
      );

      if (response) {
        if (question.question_type === "mcq") {
          const correctAnswer = question.answers.find((a) => a.is_correct);
          if (response.selected_answer === correctAnswer.id) {
            earnedPoints += question.points;
          }
        } else {
          // For text/code questions, use the manually assigned score
          earnedPoints += response.score || 0;
        }
      }
    });

    return {
      score: earnedPoints,
      total: totalPoints,
      percentage: ((earnedPoints / totalPoints) * 100).toFixed(1),
    };
  };

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Quiz Results: {quiz?.title}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          Total Responses: {responses.length}
        </Typography>

        {responses.map((candidateResponses) => (
          <Accordion key={candidateResponses.candidate.id} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Typography sx={{ flexGrow: 1 }}>
                  {candidateResponses.candidate.first_name}{" "}
                  {candidateResponses.candidate.last_name}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Score:{" "}
                  {calculateScore(candidateResponses.responses).percentage}%
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Response</TableCell>
                      <TableCell>Result</TableCell>
                      <TableCell>Points</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quiz.questions.map((question) => {
                      const response = candidateResponses.responses.find(
                        (r) => r.question === question.id
                      );

                      return (
                        <TableRow key={question.id}>
                          <TableCell>{question.question_text}</TableCell>
                          <TableCell>
                            {question.question_type === "mcq"
                              ? question.answers.find(
                                  (a) => a.id === response?.selected_answer
                                )?.answer_text
                              : response?.text_response}
                          </TableCell>
                          <TableCell>
                            {question.question_type === "mcq" ? (
                              question.answers.find((a) => a.is_correct)?.id ===
                              response?.selected_answer ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )
                            ) : (
                              "Manual Review"
                            )}
                          </TableCell>
                          <TableCell>
                            {response?.score || 0}/{question.points}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default QuizResults;
