import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Avatar,
  IconButton,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraIcon from "@mui/icons-material/CameraAlt";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ImageUploadButton from "components/Inputs/ImageUploadButton";

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#f5f5f0",
  minHeight: "100vh",
}));

const WelcomeSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0 4px 11px rgba(0.1, 0.1, 0.1, 0.2)",
}));
const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "right",
  backgroundColor: "#020060",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}));

const ProfileSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}));

const CompanyInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3),
}));
const CompanyLogoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const CompanyLogo = styled(Box)(({ theme }) => ({
  width: 90,
  height: 90,
  borderRadius: "50%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "2px solid #fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}));

const LogoAvatar = styled(Avatar)(({ theme }) => ({
  width: "100%",
  height: "100%",
  //   backgroundColor: "transparent",
  fontSize: "16px",
  fontWeight: "bold",
}));

const CameraIconButton = styled(IconButton)(({ theme }) => ({
  padding: 8,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  marginRight: "10px",
  marginTop: "35px",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: "12px",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const UpdateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#020060",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

  "&:hover": {
    backgroundColor: "#303f9f",
    color: "#fff",
  },
}));
const CompanyDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const AdminProfile = () => {
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    country: "",
    city: "",
    zip_code: "",
    address: "",
    first_name: "",
    last_name: "",
    comp_industry: "",
    comp_description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showSnackbar, setShowSnackbar] = useState(false);

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const userId = getUserIdFromAccessToken();

  useEffect(() => {
    fetchAdminData();
  }, []);
  const fetchAdminData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}authentication/admins/${userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setShowSnackbar(true);
      const { id, image, first_name, last_name, ...filteredData } =
        response.data;
      setFormData(filteredData);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch admin data");
      setLoading(false);
    }
  };
  useEffect(()=>{
console.log('formData',formData)
  },[formData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleImageUpload = (imageUrl) => {
    setFormData((prevData) => ({ ...prevData, company_logo: imageUrl }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}authentication/admins/${userId}/`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setShowSnackbar(true);
      setIsEditing(false);
      setLoading(false);
    } catch (err) {
      setError("Failed to update profile");
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <PageContainer>
      <WelcomeSection>
        <Typography
          sx={{
            color: "#020060",
            fontWeight: "bold",
            fontSize: "17px",
            opacity: "80%",
          }}
        >
          Welcome back {formData.company}!
        </Typography>
        <Section
          sx={{ bgcolor: "#e0e0e0", color: "#020060", fontWeight: "bold" }}
        >
          {formData.company.charAt(0).toUpperCase()}
        </Section>
      </WelcomeSection>
      <Typography
        variant="h4"
        sx={{ color: "#020060", fontWeight: "bold", marginBottom: "2%" }}
      >
        User profile
      </Typography>
      <ProfileSection>
        <CompanyInfo>
          <CompanyDetails>
            <CompanyLogoContainer>
              <CompanyLogo>
                {formData.company_logo ? (
                  <LogoAvatar
                    src={formData.company_logo}
                    alt={formData.company}
                  />
                ) : (
                  <LogoAvatar>{formData.company.charAt(0)}</LogoAvatar>
                )}
                <ImageUploadButton
                  onImageUpload={handleImageUpload}
                  error={false}
                  disabled={!isEditing}
                />
              </CompanyLogo>
            </CompanyLogoContainer>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#020060" }}
            >
              {formData.company}
            </Typography>
          </CompanyDetails>
          <Button
            sx={{
              color: "grey",
              opacity: "80%",
              textTransform: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            endIcon={<DriveFileRenameOutlineOutlinedIcon />}
            onClick={handleEdit}
            disabled={isEditing}
          >
            Edit
          </Button>
        </CompanyInfo>
        <Grid
          container
          sx={{
            paddingRight: "2%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "right",
          }}
        >
          {Object.entries(formData)
            .filter(([key]) => key !== "company_logo") 
            .map(([key, value]) => (
              <Grid item xs={12} sm={6} sx={{ paddingRight: "10%" }} key={key}>
                <Typography
                  sx={{ color: "grey", opacity: "80%", fontSize: "14px" }}
                >
                  {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                    key.replace(/_/g, " ").slice(1)}
                </Typography>
                <StyledTextField
                  fullWidth
                  name={key}
                  value={value}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={!isEditing}
                  multiline={key === "comp_description"}
                  rows={key === "comp_description" ? 4 : 1}
                />
              </Grid>
            ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <UpdateButton
            variant="contained"
            onClick={handleSave}
            disabled={!isEditing || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update profile"}
          </UpdateButton>
        </Box>
      </ProfileSection>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message="Form saved successfully!"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)", // Semi-transparent white background
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(10px)", // Adds the blur effect
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Optional: adds a soft shadow for better visibility
          },
        }}
      />
    </PageContainer>
  );
};

export default AdminProfile;
