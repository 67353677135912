import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Divider,
  Snackbar,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import axios from "axios";
import ApplicationFilters from "./ApplicationFilters";

const LinkCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  background: "linear-gradient(135deg, #f6f8fc 0%, #ffffff 100%)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  border: "1px solid rgba(255, 255, 255, 0.5)",
}));

const UrlBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: "rgba(25, 118, 210, 0.08)",
  color: theme.palette.text.primary,
  fontFamily: "monospace",
  fontSize: "0.9rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.spacing(4),
  boxShadow: "0 6px 20px rgba(0,0,0,0.1)",
  marginBottom: theme.spacing(3),
  overflow: "visible",
  position: "relative",
  transition: "all 0.3s ease-in-out",
  background: "white",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 15px 30px rgba(0,0,0,0.15)",
  },
}));
const WelcomeSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(5),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 4px 11px rgba(0.1, 0.1, 0.1, 0.2)",
}));
const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: 500,
  borderRadius: "20px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  "& .MuiChip-icon": {
    color: theme.palette.text.secondary,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  padding: theme.spacing(1.5, 3),
  fontWeight: 600,
  textTransform: "none",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
  },
}));
const apiUrl = process.env.REACT_APP_API_URL;

const ApplicationCard = ({ application, company }) => {
  const handleResumeClick = async () => {
    console.log(application.cv);
    if (!application.cv) {
      alert("Resume is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = application.cv.replace(/\\/g, "/");
    const fileURL = `${apiUrl}${normalizedResumePath}`;
    console.log('here is resume: ',fileURL);

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleEmailClick = (event) => {
    event.preventDefault();
    // console.log('here is ', post_title);
    const post_title = company
    const email = encodeURIComponent(application.form_data.email);
    const subject = encodeURIComponent(`Updates about your Spontaneous Application for "${post_title}"`);
    const body = encodeURIComponent('Hello,\n\n');

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
    const width = window.screen.width / 3;
    const height = window.screen.height;
    const left = window.screen.width - width;
    const top = 0;

    window.open(
      gmailUrl,
      "GmailPopup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const handleCoverLetterClick = async () => {
    console.log(application.cover_letter);
    if (!application.cover_letter) {
      alert(" cover_letter  is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = application.cover_letter.replace(/\\/g, "/");
    const fileURL = `${apiUrl}${normalizedResumePath}`;
    console.log('here is cover_letter: ',fileURL);

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <StyledCard>
      <CardContent
        sx={{ p: { xs: 3, sm: 4, md: 5 }, pt: { xs: 6, sm: 7, md: 5 } }}
      >
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={application.image}
                alt={`${application.form_data.firstName} ${application.form_data.lastName}`}
                sx={{
                  width: 90,
                  height: 90,
                  mr: 2,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h5" fontWeight="bold">
                  {application.form_data.firstName} {application.form_data.lastName}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  <InfoChip
                    icon={<LocationOnIcon />}
                    label={application.form_data.city || "Location not specified"}
                    size="small"
                  />
                  <InfoChip
                    icon={<EmailIcon />}
                    label={application.form_data.email}
                    size="small"
                    onClick={() =>
                      (window.location.href = `mailto:${application.form_data.email}`)
                    }
                    sx={{ cursor: "pointer" }}
                  />
                  <InfoChip
                    icon={<PhoneIcon />}
                    label={application.form_data.phone}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <SchoolIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={application.form_data.educationLevel}
                    secondary="Education Level"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WorkIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={application.form_data.experienceYears}
                    secondary="Years of Experience"
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Align items vertically centered
                gap: 2, // Adds spacing between the title and button
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Applied On: {format(new Date(application.submitted_at), "PPP HH:mm")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 5,
                }}
              >
                <a
                  href={`mailto:${application.form_data.email}`}
                  style={{ textDecoration: "none" }}
                >
                  <ActionButton
                    variant="contained"
                    color="success"
                    startIcon={<EmailIcon />}
                    onClick={handleEmailClick}
                    sx={{
                      bgcolor: "success.main",
                      color: "#fff",
                      "&:hover": {
                        bgcolor: "success.dark",
                      },
                      minWidth: "100px", // Ensures minimum width for consistency
                    }}
                  >
                    CONTACT
                  </ActionButton>
                </a>
              </Box>
            </Box>


            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Change to column direction
                mt: 2,
                gap: 2, // Increased gap between buttons when stacked
              }}
            >
              <ActionButton
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleResumeClick}
                sx={{
                  bgcolor: "#1976d2",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#115293",
                  },
                  width: "100%", // Full width
                }}
              >
                Resume
              </ActionButton>
              <ActionButton
                variant="outlined"
                startIcon={<SchoolIcon />}
                onClick={handleCoverLetterClick}
                sx={{
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  "&:hover": {
                    bgcolor: "rgba(25, 118, 210, 0.04)",
                  },
                  width: "100%", // Full width
                }}
              >
                Cover Letter
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>

  );
};
const ApplicationsList = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adminId, setAdminId] = useState("");
  const [adminCompany, setAdminCompany] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [filters, setFilters] = useState({
    name: "",
    educationLevel: "",
    experienceYears: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();
  const [copied, setCopied] = useState(false);

  const spontaneousUrl = `/candidature-spontanee/${adminCompany}`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        window.location.origin + spontaneousUrl
      );
      setCopied(true);
      setSnackbar({
        open: true,
        message: "URL copied to clipboard!",
        severity: "success",
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
      setSnackbar({
        open: true,
        message: "Failed to copy URL",
        severity: "error",
      });
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }
    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  useEffect(() => {
    if (applications.length > 0) {
      console.log("applications:", applications);
    }
  }, [applications]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    const filtered = applications.filter((app) => {
      const fullName =
        `${app.form_data.firstName} ${app.form_data.lastName}`.toLowerCase();
      const nameMatch = fullName.includes(filters.name.toLowerCase());
      const educationMatch = filters.educationLevel
        ? app.form_data.educationLevel === filters.educationLevel
        : true;
      const experienceMatch = filters.experienceYears
        ? app.form_data.experienceYears === filters.experienceYears
        : true;
      return nameMatch && educationMatch && experienceMatch;
    });
    setFilteredApplications(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [filters, applications]);

  const fetchApplications = async (admin_id) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}authentication/get-form-submissions/`,
        {
          params: { admin: admin_id },
        }
      );

      setApplications(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching applications:", err);
      setApplications([]);
      setError(err.response?.data?.message || "Failed to load applications");
      showSnackbar(
        "Failed to load applications: " + (err.message || "Unknown error"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminId = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}authentication/recruiter/${userId}/admin/`
      );
      setAdminId(response.data.admin_id);
      setAdminCompany(response.data.admin_company);
      console.log("response.data.company", response.data.admin_company);
      setError(null);
      return response.data.admin_id;
    } catch (err) {
      console.error("Error fetching admin ID:", err);
      setError(err.response?.data?.message || "Failed to load admin ID");
      showSnackbar("Failed to load admin ID", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initFetch = async () => {
      const fetchedAdminId = await fetchAdminId();
      if (fetchedAdminId) {
        fetchApplications(fetchedAdminId);
      }
    };
    initFetch();
  }, []);

  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <ApplicationFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        company={spontaneousUrl}
        onCopyUrl={copyToClipboard}
        copied={copied}
      />

      {error ? (
        <Typography color="error">{error}</Typography>
      ) : filteredApplications.length === 0 ? (
        <Typography>No applications found</Typography>
      ) : (
        filteredApplications.map((application) => (
          <ApplicationCard key={application.id} application={application} company={adminCompany} />
        ))
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(3px)", // Adds the blur effect
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)", // Optional: adds a soft shadow for better visibility
          },
        }}
        message={snackbar.message}
      >
      </Snackbar>
    </Box>
  );
};

export default ApplicationsList;
