import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import { IconButton, useTheme, Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./intern.css";

const DataInternsTable = ({ intern, openDialog, rowEmail }) => {
  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Profil", accessor: "profil" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center items-center">
            <IconButton
              sx={{ color: "#9f9f9f", width: "40px", height: "40px" }}
              onClick={() => handleViewCV(row.original.reference)}
              color="inherit"
              aria-label="view cv"
            >
              <VisibilityIcon style={{ width: "25px", height: "25px" }} />
            </IconButton>
            <IconButton
              sx={{ color: "#9f9f9f", width: "40px", height: "40px" }}
              onClick={() => Open(row.original)}
              color="inherit"
              aria-label="send email"
            >
              <MailOutlineIcon style={{ width: "25px", height: "25px" }} />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [row_id, setRow_id] = useState("");

  const theme = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: intern });

  const handleOpen = (row) => {
    setSelectedRow(row);
    setIsPopupOpen(true);
  };
  const Open = (row) => {
    setRow_id(row.email);
    rowEmail = row;
    console.log("rowId ::", rowEmail);
    openDialog(rowEmail);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    setSelectedRow(null);
  };

  // const handleViewCV = async (reference) => {
  //   let fileURL;
  //   fileURL = `${apiUrl}media/${reference}`;
  //   console.log(fileURL)
  //   window.open(fileURL, "_blank");
  // };
  const handleViewCV = async (reference) => {
    let resume_path = reference;
    if (!resume_path) {
      console.log("Resume path is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = resume_path.replace(/\\/g, "/");
    const fileURL = `${apiUrl}media/${normalizedResumePath}`;

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="content">
      <div className="table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="popup-table-header"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="popup-table-cell">
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Dialog
        fullScreen
        open={isPopupOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div className="popup-table-container">
            <table {...getTableProps()} className="popup-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="popup-table-header"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="popup-table-cell"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DataInternsTable;