import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import Login from "views/Auth/Login";
import AdminA from "./views/admin/AdminA";
import "./views/index.css";
import Home from "views/client/Home";
import PostDetails from "views/Posts/PostDetails";
import InternDetails from "views/interns/InternshipDetails";
import ForgotPassword from "views/Auth/ForgotPassword";
import store from "./Redux/store";
import { Provider } from "react-redux";
import ApplicationForm from "views/client/ApplicationForm ";
import Profile from "views/client/profile";
import SavedPosts from "views/Posts/SavedPosts";
import PostsApplied from "views/Posts/PostsApplied";
import AddPost from "views/client/AddPost";
import ViewJob from "views/client/viewjob";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import CreateRecruiter from "views/admin/createRecruiter";
import ListRecruiter from "views/admin/ListRecruiter";
import Settings from "views/Settings";
import useComponentZoom from "views/UseComponentZoom";
import ZoomableWrapper from "views/ZoomableWraper";
import ApplyJob from "views/client/ApplyJob";
import FormCustomizer from "views/Posts/FormCustomizer";
import ApplicationFormSp from "views/client/DynamicApplicationForm";
import NotFound from "components/PageNotFound/pageNotFound";
import ModernNotFound from "components/PageNotFound/pageNotFound";
import ApplicationsList from "views/user/ApplicationSpontaneeList";
import QuizList from "views/quiz/QuizList";
import QuizCreator from "views/quiz/QuizCreator";
import QuizResults from "views/quiz/QuizResults";
import QuizTaker from "views/quiz/QuizTaker";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/talent" element={<AdminLayout />}>
          {/* Nested routes for /talent */}
          <Route index element={<Navigate to="/talent/dashboard" replace />} />
          <Route path="dashboard" element={<AdminA />} />
          <Route path="form_customizer" element={<FormCustomizer />} />
          <Route path="createRecruiter" element={<CreateRecruiter />} />
          <Route path="ListRecruiter" element={<ListRecruiter />} />
          <Route path="settings" element={<Settings />} />

          <Route path="create-quiz" element={<QuizCreator />} />
          <Route path="take-quiz/:quizId" element={<QuizTaker />} />
          <Route path="quiz-results/:attemptId" element={<QuizResults />} />

          {/* Catch-all route for /talent */}
          <Route path="*" element={<ModernNotFound />} />
        </Route>

        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/application" element={<ApplicationForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/saved_posts" element={<SavedPosts />} />
        <Route path="/post_applied" element={<PostsApplied />} />
        <Route path="/addpost" element={<AddPost />} />
        <Route path="/viewjob/:postid" element={<ViewJob />} />
        <Route path="/applyjob" element={<ApplyJob />} />
        <Route path="/forgote_password" element={<ForgotPassword />} />
        <Route
          path="/candidature-spontanee/:companyName"
          element={<ApplicationFormSp />}
        />
        <Route path="/applications" element={<ApplicationsList />} />

        {/* <Route path="*" element={<ModernNotFound />} />
        <Route path="/talent/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/application" element={<ApplicationForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/saved_posts" element={<SavedPosts />} />
        <Route path="/post_applied" element={<PostsApplied />} />
        <Route path="/addpost" element={<AddPost />} />
        <Route path="/viewjob/:postid" element={<ViewJob />} />
        <Route path="/applyjob" element={<ApplyJob />} />
        <Route path="/forgote_password" element={<ForgotPassword />} /> */}
        <Route
          path="/candidature-spontanee/:companyName"
          element={<ApplicationFormSp />}
        />

        <Route
          path="/post/Postdetails/:postid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
          element={<PostDetails />}
        />
        <Route
          path="/intern/Postdetails/:internid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
          element={<InternDetails />}
        />
        <Route path="/talent/*" element={<AdminLayout />} />
        <Route path="/manager/*" element={<AdminA />}>
          <Route path="form_customizer" element={<FormCustomizer />} />
          <Route path="createRecruiter" element={<CreateRecruiter />} />
          <Route path="ListRecruiter" element={<ListRecruiter />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<ModernNotFound />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
