import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/system";
import dayjs from 'dayjs';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  height: 300,
  marginBottom: theme.spacing(2),
  borderRadius: "20px",
  boxShadow: "0 4px 40px 0px #0000001A",
  position: "relative",
  overflow: "hidden",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    cursor:'pointer',
    // transform: "translateY(-4px)",
    boxShadow: "0 0px 15px 0px #0085FF",
  },
}));

const TimeChip = styled(Chip)(({ theme ,chipColor }) => ({
  position: "absolute",
  top: 42 ,
  right: 30,
  borderRadius: "10px",
  padding: "0px 12px",
  height: "32px",
  backgroundColor: "#fff",
  boxShadow: "0 4px 40px 0px #0000001A",
  color: chipColor,
  "& .MuiChip-label": {
    padding: "0px",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

const PostedTime = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "14px",
  marginTop: "4px",
}));

function JobCard({
  title = "Data scientist",
  company = "PULSE.digital Maroc",
  localisation = "Marrakech · Morocco",
  description = "We are actively looking for a talented Data scientist to join our dynamic team! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis placerat lacus, et egestas metus mattis a. Praesent dapibus mauris...",
  postedTime = "2 days ago",
  deadline = "3 days left",
  post_id,
  refresh,
  recruiter,
}) {
  const [chipColor, setChipColor]= useState('#34A853')
  // function getTimeLeft(deadline) {
  //   const now = dayjs();
  //   const endDate = dayjs(deadline);
  
  //   const daysLeft = endDate.diff(now, 'day');
  //   const weeksLeft = endDate.diff(now, 'week');
  //   const monthsLeft = endDate.diff(now, 'month');
  
  //   if (daysLeft <= 0) {
  //     return "Deadline has passed";
  //   } else if (daysLeft === 1) {
  //     if(daysLeft>=1 && daysLeft<3){
  //       setChipColor('#FF0000')
  //     }
  //     else{
  //       setChipColor('#FFA500')
  //     }
  //     return "1 day left";
  //   } else if (daysLeft < 7) {
  //     return `${daysLeft} days left`;
  //   } else if (weeksLeft === 1) {
  //     return "1 week left";
  //   } else if (weeksLeft < 4) {
  //     return `${weeksLeft} weeks left`;
  //   } else if (monthsLeft === 1) {
  //     return "1 month left";
  //   } else {
  //     return `${monthsLeft} months left`;
  //   }
  // }
  const timeLeft = React.useMemo(() => {
    const now = dayjs();
    const endDate = dayjs(deadline);

    const daysLeft = endDate.diff(now, 'day');

    if (daysLeft <= 0) {
      return "Deadline has passed";
    } else if (daysLeft === 1) {
      if(daysLeft>=1 && daysLeft<3){
        setChipColor('#FF0000');
      }
      else{
        setChipColor('#FFA500');
      }
      return "1 day left";
    } else if (daysLeft < 7) {
      return `${daysLeft} days left`;
    } else if (endDate.diff(now, 'week') === 1) {
      return "1 week left";
    } else if (endDate.diff(now, 'week') < 4) {
      return `${endDate.diff(now, 'week')} weeks left`;
    } else if (endDate.diff(now, 'month') === 1) {
      return "1 month left";
    } else {
      return `${endDate.diff(now, 'month')} months left`;
    }
  }, [deadline]);
  return (
    <StyledCard>
      <CardContent sx={{ p: 3 }}>
        <TimeChip label={timeLeft} chipColor={chipColor} />
        <CardHeader 
          title={
            <Typography
              variant="h5"
              sx={{
                color: "#004F98",
                fontWeight: 600,
                fontSize: { xs: "10px", sm: "24px" },
                mb: 1,
                fontFamily: "Poppins",
              }}
            >
              {title.length > 19
              ? `${title.slice(0, 19)}...`
              : title}
            </Typography>
          }
          subheader={
            <>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
              >
                <LocationOnIcon
                  sx={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.6)" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {localisation}
                </Typography>
              </Box>
              {/* <PostedTime>
                <AccessTimeIcon sx={{ fontSize: "16px" }} />
                <Typography variant="body2">{postedTime}</Typography>
              </PostedTime> */}
            </>
          }
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Divider
            sx={{
              // "&::before, &::after": {
              //   BorderColor: "#0F52BA",
              // },
              width: "90%",
              background: "#004F9833",
              mt: 1,
            }}
          />
        </div>

        <Box sx={{ mb: 3, p: 2 }}>
          <Typography
            variant="h6"
            sx={{
              color: "#004F98",
              // fontWeight: '700px',
              fontSize: { xs: "24px", sm: "22px" },
              mb: 2,
              fontWeight: 700,
              fontFamily: "Poppins",
              // fontSize: "28px",
              textTransform: "none",
            }}
          >
            {company.length > 25
              ? `${company.slice(0, 25)}...`
              : company}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "14px",
              lineHeight: 1.6,
              fontFamily: "Poppins",
            }}
          >
            {description.length > 200
              ? `${description.slice(0, 130)}...`
              : description}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCard);
