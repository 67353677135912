import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PostPage from "./Post";
import FormCustomizer from "./FormCustomizer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#3f51b5",
    },
  },
});

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  margin: "0 auto",
  padding: theme.spacing(3),
  backgroundColor: "#f5f5f5",
  borderRadius: theme.shape.borderRadius,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiTabs-indicator": {
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: "1rem",
  transition: "all 0.2s",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
}));

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && children}
  </div>
);

const TabNavigation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSave2 = (formStructure) => {
    console.log("Saved form structure:", formStructure);
    // Handle the saved form structure (e.g., send to server, update state, etc.)
  };
  return (
    <ThemeProvider theme={theme}>
      <StyledBox>
        <StyledTabs value={value} onChange={handleChange} centered>
          <StyledTab
            icon={<EditNoteIcon />}
            iconPosition="start"
            label="Posts"
          />
          {/* <StyledTab
            icon={<RocketLaunchIcon />}
            iconPosition="start"
            label="Candidature"
          /> */}
        </StyledTabs>
        <ContentPaper elevation={1}>
          <TabPanel value={value} index={0}>
            <Typography variant="h5" color="primary" gutterBottom>
              Posts
            </Typography>
              <PostPage />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <Typography variant="h5" color="secondary" gutterBottom>
              Candidature Spontanée
            </Typography>
            <FormCustomizer initialForm={[]} onSave={handleSave2} /> 
          </TabPanel> */}
        </ContentPaper>
      </StyledBox>
    </ThemeProvider>
  );
};

export default TabNavigation;
