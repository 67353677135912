import Calendar from "views/Calendar/Calendar";
import Chat from "views/Chat/Chat";
import Dashboard from "views/Dashboard.jsx";
import TableList from "views/Candidates/Tables.jsx";
import UserPage from "views/user/User.jsx";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Post from "views/PostView";
import Example from "views/Posts/Post";
import InternPage from "views/interns/internship";
import Switcher from "views/interns/Switcher";
import Settings from "views/Settings";
import TabNavigation from "views/Posts/TabNavigation";
// import Kanban from "views/kanban";
import CandidatesInternsTable from "views/Candidates/listCandidateInterns";
import Dataimport from "views/Candidates/dataimport";
import PostPage from "views/Posts/Post";
import PostPage2 from "views/ScrapedPosts/Post";
import ApplicationsList from "views/user/ApplicationSpontaneeList";
import QuizCreator from "../src/views/quiz/QuizCreator";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-university",
    component: <Dashboard />,
    layout: "/talent",
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: "fas fa-user-alt",
    component: <UserPage />,
    layout: "/talent",
  },

  {
    path: "/post/PostDetails/*",
    name: "Posts",
    icon: "fas fa-file-alt",
    component: <PostPage />,
    layout: "/talent",
  },

  {
    path: "/intern/InternDetails/*",
    name: "Scraping",
    icon: "fas fa-robot",
    component: <PostPage2 />,
    layout: "/talent",
  },
  {
    path: "/listall",
    name: "Users list",
    icon: "fas fa-file-alt",
    component: <CandidatesInternsTable />,
    layout: "/talent",
  },
  {
    path: "/dataimport",
    name: "Import data",
    icon: "fas fa-database",
    component: <Dataimport />,
    layout: "/talent",
  },
  // {
  //   path: "/quiz",
  //   name: "Quiz",
  //   icon: "fas fa-file-alt",
  //   component: <QuizCreator />,
  //   layout: "/talent",
  // },
  {
    path: "/candidature_spontanee_list",
    name: "Spontaneous ",
    icon: "fas fa-sync",
    component: <ApplicationsList />,
    layout: "/talent",
  },

  // {
  //   path: "/intern/InternDetails/*",
  //   name: "INTERNSHIPS",
  //   icon: "fas fa-file-alt",
  //   component: <InternPage />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/tables",
  //   name: "Candidate List",
  //   icon: "fas fa-users",
  //   component: <TableList />,
  //   layout: "/recruiter",
  // },

  {
    path: "/Chat",
    name: "Chat",
    icon: "fas fa-comment",
    component: <Chat />,
    layout: "/talent",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "fas fa-calendar-alt",
    component: <Calendar />,
    layout: "/talent",
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "fas fa-cog",
  //   component: <Settings />,
  //   layout: "/admin",
  //   subitems: [
  //     { path: "/profile", name: "View Profile" },
  //     { path: "/edit-profile", name: "Edit Profile" },
  //   ],
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "fas fa-bell",
  //   component: <Notifications />,
  //   layout: "/admin",
  // },
];

export default routes;
