import React, { useState, useEffect, useRef, useMemo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  Modal,
  Box,
  ButtonBase,
  Button,
  Dialog,
  DialogContent,
  Snackbar,
  Chip,
  Divider,
  styled,
  Avatar,
  Alert,
} from "@mui/material";
import Lottie from "react-lottie";
import successAnimation from "./Animation - 1716461046736.json"; // Update the path to your success animation JSON file
import "./home.css";
import DevOpsLoop from "assets/img/DevOpsLoop.png"; // Adjust the path according to your project structure
import ApplicationForm from "./ApplicationForm ";
import { COLORS } from "colors/color";
import LoginForDialog from "views/Auth/LoginForDialog";
import axios from "axios";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LocationOn,
  Description,
  Assignment,
  WorkOutline,
  Schedule,
} from "@mui/icons-material";
import dayjs from "dayjs";
import LaunchIcon from "@mui/icons-material/Launch";
import bgImage from "../../assets/img/bg.jpg";
import { BsBookmarkHeart } from "react-icons/bs";
import { BsBookmarkHeartFill } from "react-icons/bs";

const TimeChip = styled(Chip)(({ theme, shrinkHeader,chipColor }) => ({
  position: "absolute",
  top: !shrinkHeader ? 200 : 50,
  transition: "all 0.6s ease-in-out",
  right: !shrinkHeader ? 30 : 45,
  borderRadius: "10px",
  padding: "0px 12px",
  height: "32px",
  backgroundColor: !shrinkHeader ? "#FFF" : "rgba(255, 255, 255, 0.05)",
  backdropFilter: shrinkHeader ? "blur(4px)" : "",
  boxShadow: "0 4px 40px 0px #0000001A",
  color: chipColor,
  "& .MuiChip-label": {
    padding: "0px",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

// const SaveButton = styled(IconButton)(({ theme }) => ({
//   position: "absolute",
//   top: 4,
//   right: 4,
//   padding: 4,
//   backgroundColor: "rgba(255, 255, 255, 0.8)",
//   "&:hover": {
//     backgroundColor: "rgba(255, 255, 255, 1)",
//   },
// }));

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: "8px",
// };

const scrollableContentStyle = {
  marginTop: "-21%",
  padding: "9%",
  overflowY: "scroll",
  Height: {
    xs: "auto",
    md: "calc(120vh - 220px)",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#004F9880",
    borderRadius: "8px",
    border: "2px solid transparent",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#0085FF",
  },
};

const CardStyle = styled(Card)({
  boxShadow: "0px 4px 40px 0px #0000001A",
  borderRadius: "20px",
  overflowY: "auto",
  maxHeight: "95vh",
  // position: "relative",
  display: "flex",
  flexDirection: "column",
});

const AnimatedCardMedia = styled(CardMedia)(({ shrink }) => ({
  height: shrink ? "150px" : "250px", // Existing height logic
  width: "100%",
  transition: "height 0.6s ease-in-out",
  objectFit: "cover",
  zIndex:1
}));
const AnimatedAvatar = styled(Avatar)(({ shrink }) => ({
  width: !shrink ? 100 : 164,
  height: !shrink ? 100 : 164,
  transition: "all 0.6s ease-in-out",
  transform: !shrink
    ? "translateY(0%) translateX(7%)"
    : "translateY(-60%) translateX(7%)",
  border: "4px solid white",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(4px)",
}));
const AnimatedSaveButton = styled(IconButton)(({ theme, shrink }) => ({
  position: "absolute",
  right: shrink ? "10px" : "-10px",
  top: shrink ? "-15px" : "-8px",
  padding: 4,
  transform: shrink ? "scale(0.67)" : "scale(1)",
  zIndex: 1000,
  transition: "all 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    transform: shrink ? "scale(0.75)" : "scale(1.1)",
  },
}));



export default function MyCard(props) {
  const [isFixed, setIsFixed] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [Hiddforum, setHiddforum] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());
  const [hasApplied, setHasApplied] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "info",
    action: null,
  });
  const [shrinkHeader, setShrinkHeader] = useState(false);
  const [shrink, setShrink] = useState(false);
  const cardRef = useRef(null);
  

 
  const handleCardScroll = () => {
    if (cardRef.current) {
      const scrollPosition = cardRef.current.scrollTop;
      setShrink(scrollPosition >= 50);
    }
  };
  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const showNotification = (message, severity = "success") => {
    showSnackbar(message);
  };
  const handleClose = () => {
    setShowForm(false);
    setHiddforum(false);
    setShowSuccess(false);
  };

  useEffect(() => {
    if (cardRef.current) {
      console.log('current')
      cardRef.current.scrollTop = 0;
    }
  }, [props.id]);
  useEffect(() => {
    if (shrink) {
      console.log("shrinked");
      props.setIsShrinked(true);
    } else {
      props.setIsShrinked(false);
    }
  }, [shrink]);
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const [loginDialog, setLoginDialog] = useState(false);

  const handleApplyClick = () => {
    localStorage.getItem("accessToken")
      ? setShowForm(true)
      : // setLoginDialog(true)
        (window.location.href = "../login");
  };

  useEffect(() => {
    if (Hiddforum) {
      setShowSuccess(true);
      showNotification("Application submitted successfully!", "success");
      // setTimeout(() => {
      handleClose();
      // }, 3000);
    }
  }, [Hiddforum]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 240) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Hiddforum) {
      setShowSuccess(true);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [Hiddforum]);
  const [applicationList, setApplicationList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const addPostApplied = (post) => {
    setApplicationList((prev) => [...prev, post]);
  };

  const checkSavedStatus = async () => {
    const userId = getUserIdFromAccessToken();
    if (!userId) return;

    try {
      const response = await fetch(
        `${apiUrl}candidates/list_saved_posts/${userId}/`
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.error("No saved posts found");
          setIsSaved(false);
          return;
        }
        throw new Error(`An error occurred: ${response.statusText}`);
      }

      const savedPosts = await response.json();
      // Check if current post ID exists in the saved posts list
      console.log("savedPost.id", savedPosts);
      console.log("props.id", props.id);
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost.post.id === props.id
      );
      setIsSaved(isPostSaved);
    } catch (error) {
      console.error("Error checking saved posts:", error);
      setIsSaved(false);
    }
  };

  useEffect(() => {
    console.log("saved?", isSaved);
  }, [isSaved, props.id]);
  useEffect(() => {
    checkSavedStatus();
  }, [props.id]);
  const handleSaveIconClick = async () => {
    if (isSaved) {
      // Show confirmation snackbar when trying to unsave
      setSnackbarState({
        open: true,
        message: "Do you want to unsave this post?",
        severity: "warning",
        action: (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                confirmUnsave();
                setSnackbarState((prev) => ({ ...prev, open: false }));
              }}
              sx={{ color: "#1976d2", mr: 1 }}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              size="small"
              onClick={() =>
                setSnackbarState((prev) => ({ ...prev, open: false }))
              }
              sx={{ color: "#666" }}
            >
              No
            </Button>
          </>
        ),
      });
    } else {
      // Regular save functionality
      try {
        const userId = getUserIdFromAccessToken();
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}candidates/save_post/${userId}/${props.id}/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setIsSaved(true);

          setSnackbarState({
            open: true,
            message: "Post saved successfully",
            severity: "success",
            action: null,
          });
          setTimeout(() => {
            setSnackbarState((prev) => ({ ...prev, open: false }));
          }, 2000);
          props.refresh();
        } else {
          throw new Error(data.error);
        }
      } catch (error) {
        console.error("Error saving the post:", error);
        // setSnackbarState({
        //   open: true,
        //   message: 'Failed to save post. Please try again.',
        //   severity: 'error',
        //   action: null
        // });
      }
    }
  };

  const confirmUnsave = async () => {
    try {
      const userId = getUserIdFromAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}candidates/save_post/${userId}/${props.id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to unsave post");
      }

      setIsSaved(false);
      props.refresh();

      setSnackbarState({
        open: true,
        message: "Post unsaved successfully",
        severity: "success",
        action: null,
      });

      // setTimeout(() => {
      //   setSnackbarState(prev => ({ ...prev, open: false }));
      // }, 2000);
    } catch (error) {
      console.error("Error unsaving the post:", error);
      // setSnackbarState({
      //   open: true,
      //   message: 'Failed to unsave post. Please try again.',
      //   severity: 'error',
      //   action: null
      // });
    }
  };
  const postApplied = async () => {
    const userId = getUserIdFromAccessToken();
    try {
      const response = await fetch(
        `${apiUrl}candidates/list_applied_posts/${userId}/`
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.error("Candidate not found");
          setApplicationList([]);
          return { error: "Candidate not found" };
        } else {
          throw new Error(`An error occurred: ${response.statusText}`);
        }
      }

      const json = await response.json();

      console.log("lists:", json);
      setApplicationList(json);
      console.log("application list is :", json);
      setIsClicked(false);
      return json;
    } catch (error) {
      console.error("Error loading data", error);
      return { error: error.message };
    }
  };

  useEffect(() => {
    postApplied();
  }, [isClicked]);

  useEffect(() => {
    hasAlreadyApplied();
  }, []);

  const hasAlreadyApplied = async () => {
    const userId = getUserIdFromAccessToken();
    try {
      const response = await axios.get(
        `${apiUrl}candidates/check-applied/${userId}/`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("has applied ? :", response.data.has_applied);
      setHasApplied(response.data.has_applied);
      
    } catch (error) {
      console.error("Error :", error.response.data);
    }
  };
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Start the animation after the component mounts
    setAnimate(true);
  }, []);

  const boxShadowStyle = {
    animation: animate ? "shadowFade 1s forwards" : "none",
    boxShadow: "0 -18px 10px rgba(255, 255, 255, 0)",
  };

  const [chipColor, setChipColor]= useState('#34A853')

  const timeLeftInfo = useMemo(() => {
    const now = dayjs();
    const endDate = dayjs(props.deadline);
    const daysLeft = endDate.diff(now, 'day');
    
    let text;
    let color = '#34A853'; // default color

    if (daysLeft <= 0) {
      text = "Deadline has passed";
      color = '#FF0000';
    } else if (daysLeft === 1) {
      text = "1 day left";
      color = '#FF0000';
    } else if (daysLeft < 7) {
      text = `${daysLeft} days left`;
      if (daysLeft < 3) {
        color = '#FF0000';
      } else {
        color = '#FFA500';
      }
    } else if (endDate.diff(now, 'week') === 1) {
      text = "1 week left";
      color = '#34A853';
    } else if (endDate.diff(now, 'week') < 4) {
      text = `${endDate.diff(now, 'week')} weeks left`;
      color = '#34A853';
    } else if (endDate.diff(now, 'month') === 1) {
      text = "1 month left";
      color = '#34A853';
    } else {
      text = `${endDate.diff(now, 'month')} months left`;
      color = '#34A853';
    }

    return { text, color };
  }, [props.deadline]);

  // Use effect to update chip color
  useEffect(() => {
    setChipColor(timeLeftInfo.color);
  }, [timeLeftInfo]);
  const SectionTitle = ({ text }) => (
    <Typography
      variant="h6"
      sx={{
        textTransform: "none",
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "1.125rem",
        color: "#004F98",
        mb: 1,
      }}
    >
      {text}
    </Typography>
  );
  return (
    <>
      {/* <<<<<<< HEAD */}
      <CardStyle>
        <Box position="relative">
          <AnimatedCardMedia
            component="img"
            image={bgImage}
            alt="Job Post Image"
            shrink={shrink}
            sx={{
              height: shrink ? "100px" : "250px",
              width: "100%",
              transition: "height 0.6s ease-in-out", // Add transition for smooth shrinking
              objectFit: "cover",
            }}
          />
          {/* Gradient overlay */}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50%", // Adjust height to control how much of the image the gradient covers
              background:
                "linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 99%)",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            transform: "translateY(-50%) translateX(7%)",
            position: "relative",
            // transform: 'translateX(-10%)',
            zIndex: 2,
            paddingBottom: "-10%",
          }}
        >
          <AnimatedAvatar
            // src="/api/placeholder/100/100"
            src={props.company_logo}
            alt={props.company}
            shrink={shrink}
            sx={{
              width: !shrink ? 164 : 80,
              height: !shrink ? 164 : 80,
              border: "4px solid white",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(4px)",
            }}
          />
          <Typography
            component="a"
            href="https://pulse.digital/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              zIndex: 10000,
              fontSize: shrink ? "1.2rem" : "1.8rem",
              color: "#fff",
              transform: shrink ? "translateY(0)" : "translateY(24px)", // Smooth transition effect
              pl: 2,
              fontWeight: 500,
              fontFamily: "Poppins",
              transition:
                "transform 0.6s ease-in-out, font-size 0.6s ease-in-out", // Apply transition here
              textDecoration: "none",
              "&:hover": {
                color: "#ddd",
              },
            }}
          >
            {props.company} <LaunchIcon />
          </Typography>
          <Box
            sx={{
              position: "absolute",
              right: 59,
              top: -45,
              zIndex: 1000,
              transition: "all 0.6s ease-in-out",
              transform: shrink
                ? " translateY(5px)"
                : "translateX(0) translateY(0)",
            }}
          >
            <AnimatedSaveButton onClick={handleSaveIconClick} size="small">
              {isSaved ? (
                <BsBookmarkHeartFill
                  size={shrink ? 20 : 30}
                  style={{
                    transition: "all 0.6s ease-in-out",
                    cursor: "pointer",
                    color: "#fff",
                    filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.2))",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    backdropFilter: "blur(1px)",
                  }}
                />
              ) : (
                <BsBookmarkHeart
                  size={shrink ? 20 : 30}
                  style={{
                    transition: "all 0.6s ease-in-out",
                    cursor: "pointer",
                    color: "#fff",
                    filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.2))",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    backdropFilter: "blur(1px)",
                  }}
                />
              )}
            </AnimatedSaveButton>
          </Box>
        </Box>

        <CardContent
          sx={scrollableContentStyle}
          ref={cardRef}
          onScroll={handleCardScroll}
        >
          {/* Title Section */}
          <Box sx={{ mb: 4 }}>
            <div className="column">
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // gap: "8px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "#1a365d",
                    mb: 2,
                  }}
                >
                  {props.title}
                </Typography>
              </div>
            </div>

             <TimeChip
          label={timeLeftInfo.text}
          shrinkHeader={shrink}
          chipColor={chipColor}
        />
            <Chip
              icon={<LocationOn />}
              label={props.localisation}
              sx={{
                backgroundColor: "#e3f2fd",
                color: "#1976d2",
                "& .MuiChip-icon": {
                  color: "#1976d2",
                },
              }}
            />
          </Box>

          <Divider
            sx={{
              width: "90%",
              background: "#004F9833",
              my: 3,
              zIndex: -1,
            }}
          />

          {/* Description Section */}
          <Box>
            <SectionTitle icon={Description} text="Post Description" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "#4a5568",
                lineHeight: 1.8,
                mb: 3,
                whiteSpace: "pre-line", // Preserve line breaks
              }}
            >
              {props.description}
            </Typography>
          </Box>

          {/* Requirements Section */}
          <Box>
            <SectionTitle icon={Assignment} text="Requirements" />
            <Box
              sx={{
                backgroundColor: "#f8fafc",
                borderRadius: 2,
                p: 3,
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "#4a5568",
                  lineHeight: 1.8,
                }}
              >
                {props.requirements}
              </Typography>
            </Box>
          </Box>

          {/* Mode and Deadline Section */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              alignItems: "space-between",
            }}
          >
            <Box>
              <SectionTitle icon={WorkOutline} text="Mode" />
              <Chip
                label={props.mode}
                sx={{
                  backgroundColor: "#e8f5e9",
                  color: "#2e7d32",
                  fontFamily: "Poppins",
                }}
              />
            </Box>
            <Box>
              <SectionTitle icon={Schedule} text="Deadline" />
              <Chip
                label={props.deadline}
                sx={{
                  backgroundColor: "#fff3e0",
                  color: "#ed6c02",
                  fontFamily: "Poppins",
                }}
              />
            </Box>
          </Box>
        </CardContent>

        {/* Fixed Button Section */}
        <style>
          {`
          @keyframes shadowFade {
            0% {
              box-shadow: 0 -25px 20px rgba(255, 255, 255, 0); /* Start with transparent shadow */
            }
            100% {
              box-shadow: 0 -25px 20px rgba(255, 255, 255, 1); /* End with white shadow */
            }
          }
        `}
        </style>
        <Box
          style={boxShadowStyle}
          className="animated-box"
          sx={{
            // boxShadow: "0 -8px 20px #fff",
            // background: 'linear-gradient(to top, white, #f8f8f8)',
            pb: 2,
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid transparent",
          }}
        >
          {applicationList &&
          applicationList.some(
            (application) => application.post.id === props.id
          ) ? (
            <Button
              disabled
              variant="contained"
              sx={{
                margin: "1% ",
                padding: "3%",
                width: "50%",
                height: "100%",
                color: COLORS.white,
                // border: "1px solid",
                borderRadius: "30px",
                textTransform: "none",
                fontFamily: "Poppins",
                // borderColor: COLORS.white,
                backgroundColor: COLORS.button_color,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  // borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Already applied to this post
            </Button>
          ) : (
            <Button
              onClick={handleApplyClick}
              disabled={props.userRole === "r"}
              variant="contained"
              sx={{
                margin: "1% ",
                padding: "3%",
                width: "50%",
                height: "100%",
                color: COLORS.white,
                // border: "1px solid",
                borderRadius: "30px",
                textTransform: "none",
                fontFamily: "Poppins",
                // borderColor: COLORS.white,
                backgroundColor: COLORS.button_color,
                ":hover": {
                  bgcolor: COLORS.title_color,
                  // borderColor: COLORS.signIn,
                  color: COLORS.white,
                },
              }}
            >
              Apply to this offer
            </Button>
          )}
        </Box>
      </CardStyle>
      <Modal
        open={showForm}
        onClose={handleClose}
        aria-labelledby="application-form-modal-title"
        aria-describedby="application-form-modal-description"
      >
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "30vh",
              top: "8vh",
              zIndex: 1,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <ApplicationForm
            setHiddforum={setHiddforum}
            post_id={props.id}
            post_title={props.title}
            postApplied={postApplied}
            setIsClicked={setIsClicked}
            hasApplied={hasApplied}
            setHasApplied={setHasApplied}
            addPostApplied={addPostApplied}
          />
        </Box>
      </Modal>
      <Dialog open={loginDialog}>
        <DialogContent>
          <LoginForDialog />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={snackbarState.severity === "success" ? 2000 : 3000}
        // onClose={() => setSnackbarState((prev) => ({ ...prev, open: false }))}
        // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={
          <span style={{ color: "black" }}>
            {snackbarState.message}
            {snackbarState.action && (
              <span style={{ marginLeft: 8 }}>{snackbarState.action}</span>
            )}
          </span>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(3px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)",
          },
        }}
      />
    </>
  );
}
