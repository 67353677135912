import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography, Paper, TextField, FormControlLabel, Switch, Button, IconButton, Select, MenuItem, List, ListItem, Collapse, Snackbar, createTheme } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'colors/color';
import ExistingFormDisplay from './ExistingFormDisplay';
import axios from 'axios';
import { Add as AddIcon, Save as SaveIcon, Delete as DeleteIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, DragIndicator as DragIndicatorIcon, Edit as EditIcon } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: { main: '#2c3e50' },
    secondary: { main: '#000' },
    background: { default: 'rgba(0, 0, 0, 0)', paper: '#ffffff' },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: { fontWeight: 700, letterSpacing: '0.02em' },
  },
  shape: { borderRadius: 8 },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '10px 20px',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});

const FieldTypes = {
  text: 'Text',
  select: 'Select',
  radio: 'Radio',
  file: 'File Upload',
  number: 'Number',
  date: 'Date',
  checkbox: 'Checkbox',
  textarea: 'Textarea'
};

const staticFields = [
  {
    name: "gender", type: "radio", label: "Genre", options: [
      { value: "male", label: "Homme" },
      { value: "female", label: "Femme" }    ]
  },
  { name: "firstName", type: "text", label: "Prénom", required: true },
  { name: "lastName", type: "text", label: "Nom", required: true },
  { name: "email", type: "text", label: "Email", required: true },
  { name: "phone", type: "text", label: "Téléphone", required: true },
  {
    name: "city", type: "select", label: "Ville/Commune", options: [
      { value: "marrakech", label: "Marrakech" },
      { value: "casablanca", label: "Casablanca" },
      { value: "rabat", label: "Rabat" }
    ]
  },
  {
    name: "experienceYears", type: "select", label: "Années d'expérience", options: [
      { value: "0-2", label: "0 - 2 ans" },
      { value: "3-5", label: "3 - 5 ans" },
      { value: "5+", label: "5+ ans" }
    ]
  },
  {
    name: "educationLevel", type: "select", label: "Niveau d'études", options: [
      { value: "bac", label: "Bac" },
      { value: "bac+2", label: "Bac+2" },
      { value: "bac+3", label: "Bac+3" },
      { value: "bac+5", label: "Bac+5 ou plus" }
    ]
  },
  { name: "resume", type: "file", label: "CV", required: true },
  { name: "Cover letter", type: "file", label: "Cover Letter", required: false }
];

const FormCustomizer = ({ initialForm, onSave }) => {
  const [expandedField, setExpandedField] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  // const [showSnackbar2, setShowSnackbar2] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [adminData, setAdminData] = useState();
  const [existingForm, setExistingForm] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formId, setFormId] = useState(null);

  const navigate = useNavigate();
  const userId = getUserIdFromAccessToken();

  const [formData, setFormData] = useState(() => {
    const defaultData = {
      admin: userId,
      title: '',
      description: '',
      form_structure: [...staticFields],
      is_active: true
    };
    return initialForm ? { ...defaultData, ...initialForm } : defaultData;
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };
  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode && existingForm) {
      setFormData(existingForm);
    }
  };
  const updateFormData = (updates) => {
    setFormData(prevData => ({ ...prevData, ...updates }));
  };
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userId = decodedToken.user_id;
      // fetchAdminInfo();
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const addField = (fieldType) => {
    const newField = {
      type: fieldType,
      name: `field_${formData.form_structure.length}`,
      label: `New ${FieldTypes[fieldType]} field`,
      options: ['select', 'radio', 'checkbox'].includes(fieldType) ? ['Option 1'] : undefined,
      required: false
    };
    updateFormData({
      form_structure: [...formData.form_structure, newField]
    });
    setExpandedField(formData.form_structure.length);
  };

  const updateField = (index, updates) => {
    const newStructure = formData.form_structure.map((field, i) =>
      i === index ? { ...field, ...updates } : field
    );
    updateFormData({ form_structure: newStructure });
  };

  const removeField = (index) => {
    if (index < staticFields.length) {
      alert("Cannot remove static fields");
      return;
    }
    const newStructure = formData.form_structure.filter((_, i) => i !== index);
    updateFormData({ form_structure: newStructure });
    if (expandedField === index) setExpandedField(null);
  };
  const handleSave = async () => {
    try {
      let response;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      };

      if (formId) {
        response = await axios.put(`${apiUrl}authentication/application-forms/${formId}/`, formData, { headers });
      } else {
        response = await axios.post(`${apiUrl}authentication/application-forms/`, formData, { headers });
      }

      showSnackbar('Form saved successfully!', 'success');

      if (!formId) {
        setFormId(response.data.id);
      }

      setFormData({
        ...response.data,
        form_structure: response.data.form_structure || [...staticFields],
      });

      setExistingForm({
        ...response.data,
        form_structure: response.data.form_structure || [...staticFields],
      });

      handleGetApps();

      if (typeof onSave === 'function') {
        onSave(response.data);
      }

    } catch (error) {
      console.error('Error saving form:', error);
      let errorMessage = 'An error occurred while saving the form.';
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      showSnackbar(errorMessage, 'error');
    }
  };

  const fetchAdminInfo = async () => {
    try {
      const response = await axios.get(`${apiUrl}authentication/admins/${userId}/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setAdminData(response.data);
    } catch (error) {
      console.error('Error fetching admin info:', error);
      if (error.response?.status === 401) {
        navigate('/login');  // Redirect if unauthorized
      } else {
        showSnackbar('An error occurred while fetching admin data.', 'error');
      }
    }
  };

  const handleGetApps = async () => {
    try {
      const response = await axios.get(`${apiUrl}authentication/application-forms/`, {
        params: { admin: userId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      const fetchedData = Array.isArray(response.data) ? response.data : [response.data];
      setFetchedData(fetchedData);
      console.log('data fetched: ', fetchedData);

      if (fetchedData.length > 0) {
        setExistingForm(fetchedData[0]);
        setFormId(fetchedData[0].id);
        setFormData(fetchedData[0]);
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
      let errorMessage = 'An error occurred while fetching forms.';
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      showSnackbar(errorMessage, 'error');
    }
  };

  useEffect(() => {
    fetchAdminInfo();
    handleGetApps();
  }, []);


  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(formData.form_structure);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateFormData({ form_structure: items });
  };

  const toggleFieldExpansion = (index) => {
    setExpandedField(expandedField === index ? null : index);
  };

  const addOption = (fieldIndex) => {
    const field = formData.form_structure[fieldIndex];
    const newOptions = [...(field.options || []), `Option ${(field.options || []).length + 1}`];
    updateField(fieldIndex, { options: newOptions });
  };

  const updateOption = (fieldIndex, optionIndex, newValue) => {
    const field = formData.form_structure[fieldIndex];
    const newOptions = field.options.map((opt, idx) => idx === optionIndex ? newValue : opt);
    updateField(fieldIndex, { options: newOptions });
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const field = formData.form_structure[fieldIndex];
    const newOptions = field.options.filter((_, idx) => idx !== optionIndex);
    updateField(fieldIndex, { options: newOptions });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 1000, margin: 'auto', padding: 4, backgroundColor: 'background.default', minHeight: '100vh' }}>
        <Typography variant="h4" align="center" color="primary" sx={{ mb: 6 }}>
          Application Form Management
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                onChange={toggleEditMode}
                color="primary"
              />
            }
            label={editMode ? "Edit Mode" : "View Mode"}
          />
          {!editMode && existingForm && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={toggleEditMode}
              sx={{
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.white,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Edit Form
            </Button>
          )}
          {editMode && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              sx={{
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.white,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              {formId ? 'Update Form' : 'Save Form'}
            </Button>
          )}
        </Box>

        {existingForm && !editMode ? (
          <ExistingFormDisplay formData={existingForm} />
        ) : (
          <>
            <Paper elevation={0} sx={{ padding: 4, mb: 4, borderRadius: 4, background: 'linear-gradient(145deg, #ffffff, #f0f0f0)' }}>
              <Box sx={{ display: 'flex', gap: 3, mb: 4 }}>
                <TextField
                  fullWidth
                  label="Form Title"
                  value={formData.title}
                  onChange={(e) => updateFormData({ title: e.target.value })}
                  variant="outlined"
                  sx={{ flex: 2 }}
                  disabled={!editMode}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_active}
                      onChange={(e) => updateFormData({ is_active: e.target.checked })}
                      color="secondary"
                      disabled={!editMode}
                    />
                  }
                  label="Active"
                  sx={{ flex: 1 }}
                />
              </Box>
              <TextField
                fullWidth
                label="Form Description"
                value={formData.description}
                onChange={(e) => updateFormData({ description: e.target.value })}
                variant="outlined"
                multiline
                rows={3}
                sx={{ mb: 4 }}
                disabled={!editMode}
              />
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Recruiter ID: {formData.recruiter}
              </Typography>
              {formData.created_at && (
                <Typography variant="body2" color="text.secondary">
                  Created: {new Date(formData.created_at).toLocaleString()}
                </Typography>
              )}
              {formData.updated_at && (
                <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                  Last updated: {new Date(formData.updated_at).toLocaleString()}
                </Typography>
              )}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="fields">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef} sx={{ mb: 4 }}>
                      {formData.form_structure.map((field, index) => (
                        <Draggable
                          key={field.name}
                          draggableId={field.name}
                          index={index}
                          isDragDisabled={!editMode || (index < staticFields.length && field.type === 'file')}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                mb: 2,
                                borderRadius: 2,
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  transform: editMode ? 'translateY(-5px)' : 'none',
                                  boxShadow: editMode ? '0 6px 12px rgba(0,0,0,0.15)' : '0 4px 6px rgba(0,0,0,0.1)',
                                }
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', p: 2 }}>
                                {editMode && (index >= staticFields.length || field.type !== 'file') && <DragIndicatorIcon sx={{ mr: 2, color: 'text.secondary' }} />}
                                <TextField
                                  fullWidth
                                  value={field.label}
                                  onChange={(e) => updateField(index, { label: e.target.value })}
                                  variant="outlined"
                                  size="small"
                                  sx={{ mr: 2 }}
                                  disabled={!editMode}
                                />
                                {!(index < staticFields.length && field.type === 'file') && (
                                  <Select
                                    value={field.type}
                                    onChange={(e) => updateField(index, { type: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: 150, mr: 2 }}
                                    disabled={!editMode}
                                  >
                                    {Object.entries(FieldTypes).map(([type, label]) => (
                                      <MenuItem key={type} value={type}>{label}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                                {editMode && (index >= staticFields.length || field.type !== 'file') && (
                                  <IconButton onClick={() => removeField(index)} color="secondary">
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                                {editMode && (
                                  <IconButton
                                    onClick={() => toggleFieldExpansion(index)}
                                    disabled={!editMode || (index < staticFields.length && field.type === 'file')}
                                  >
                                    {expandedField === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                )}
                              </Box>
                              <Collapse in={expandedField === index} timeout="auto" unmountOnExit>
                                {/* Rest of the existing code remains the same */}
                              </Collapse>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
            {editMode && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => addField('text')}
                  sx={{
                    height: "100%",
                    color: COLORS.signIn,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.Button_OnHold,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Add Field
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.signIn,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  {formId ? 'Update Form' : 'Save Form'}
                </Button>
              </Box>
            )}
          </>
        )}

        {adminData && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/candidature-spontanee/${adminData.company}`)}
            sx={{
              mt: 4,
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.signIn,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            View Application Page
          </Button>
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          },
        }}
      />
    </ThemeProvider>
  );
};

export default FormCustomizer;