import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import { Checklist, Language, WorkspacePremium } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import DescriptionIcon from "@mui/icons-material/Description";
import SchoolIcon from "@mui/icons-material/School";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { styled } from "@mui/material/styles";
import { COLORS } from "colors/color";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { GraduationCap } from 'lucide-react';
import { Download } from "bootstrap-icons-react";
const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.spacing(4),
  boxShadow: "0 10px 40px rgba(0,0,0,0.1)",
  marginBottom: theme.spacing(6),
  overflow: "visible",
  position: "relative",
  transition: "all 0.3s ease-in-out",
  background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 20px 60px rgba(0,0,0,0.15)",
  },
}));
const apiUrl = process.env.REACT_APP_API_URL;


const ScoreTriangle = styled(Box)(({ theme, score }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: 0,
  height: 0,
  borderStyle: "solid",
  borderWidth: "0 340px 200px 0",
  borderColor: `transparent ${getScoreColor(score)} transparent transparent`,
  zIndex: 2,
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
}));

const ScoreContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 15,
  right: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  zIndex: 3,
}));

const InfoIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: -10,
  right: -10,
  padding: 4,
  color: "#fff",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const ScoreText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "3rem",
  color: "#fff",
}));

const ScoreComment = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  color: "#fff",
  textAlign: "right",
  maxWidth: "180px",
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: 500,
  borderRadius: "20px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  "& .MuiChip-icon": {
    color: theme.palette.text.secondary,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  padding: theme.spacing(1.5, 3),
  fontWeight: 600,
  textTransform: "none",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
  },
}));

const getScoreColor = (score) => {
  if (score >= 75) return "#4caf50";
  if (score >= 45) return "#0befef";
  return "#f9510e";
};

const getScoreComment = (score) => {
  if (score >= 75) return "Excellent match!";
  if (score >= 45) return "Good potential";
  return "Consider carefully";
};

const CandidateCard = ({ candidate, post_title, resume_path, coverLetter }) => {
  // useEffect(() => {
  //   const calculateAge = (dateOfBirth) => {
  //     // //console.log('dateOfBirth',dateOfBirth)
  //     const today = new Date();
  //     const birthDate = new Date(dateOfBirth);
  //     let age = today.getFullYear() - birthDate.getFullYear();
  //     const monthDifference = today.getMonth() - birthDate.getMonth();

  //     if (
  //       monthDifference < 0 ||
  //       (monthDifference === 0 && today.getDate() < birthDate.getDate())
  //     ) {
  //       age--;
  //     }

  //     return age;
  //   };

  //   const age = calculateAge(candidate.candidate.date_of_birth);
  //   setAge(age);
  // }, []);
  if (!candidate) return null;

  const handleEmailClick = (event) => {
    event.preventDefault();
    console.log('here is ', post_title);
    const email = encodeURIComponent(candidate.email);
    const subject = encodeURIComponent(`Updates about "${post_title}" offer`);
    const body = encodeURIComponent('Hello,\n\n');

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
    const width = window.screen.width / 3;
    const height = window.screen.height;
    const left = window.screen.width - width;
    const top = 0;

    window.open(
      gmailUrl,
      "GmailPopup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };


  const handleResumeClick = async () => {
    console.log(resume_path);
    if (!resume_path) {
      alert("Resume  is not available");
      return;
    }

    // Ensure the resume_path uses forward slashes
    const normalizedResumePath = resume_path.replace(/\\/g, "/");
    const fileURL = `${apiUrl}media/${normalizedResumePath}`;
    console.log('here is resume: ',fileURL);

    try {
      const response = await fetch(`${apiUrl}ai/search_resume/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resume_url: fileURL }),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/pdf")) {
          // Create a blob from the PDF content
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          // Open the PDF in a new tab
          window.open(url, "_blank");
        } else {
          console.log("Unexpected response from server");
        }
      } else {
        if (contentType && contentType.includes("text/html")) {
          // Handle HTML response (404 page)
          const htmlContent = await response.text();
          // Create a new window and write the HTML content to it
          const newWindow = window.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          const data = await response.json();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  return (
    <StyledCard>
      <Tooltip title="AI-generated match score" arrow placement="top-end">
        <Box>
          <ScoreTriangle score={candidate.score_matching} />
          <ScoreContent>
            <Tooltip
              title="This score represents the AI-evaluated match between the job description and the candidate's resume. It considers factors such as skills, experience, and qualifications to determine the overall fit for the position."
              arrow
              placement="bottom"
            >
              <InfoIcon size="small">
                <InfoOutlinedIcon fontSize="small" />
              </InfoIcon>
            </Tooltip>
            <ScoreText>{candidate.score_matching}%</ScoreText>
            <ScoreComment>
              {getScoreComment(candidate.score_matching)}
            </ScoreComment>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <SmartToyIcon sx={{ fontSize: 14, mr: 0.5, color: "#fff" }} />
              <Typography
                variant="caption"
                sx={{ color: "#fff", fontSize: "0.7rem" }}
              >
                AI Evaluation
              </Typography>
            </Box>
          </ScoreContent>
        </Box>
      </Tooltip>
      <CardContent className="p-5 pt-8 md:p-8 md:pt-10">
        <Grid container spacing={6} alignItems="flex-start">
          {/* Left Column */}
          <Grid item xs={12} sm={6} md={4}>
            <Box className="flex items-center mb-2">
              <Typography variant="h5" fontWeight="bold" className="mr-2">
                {candidate.name}, {candidate.age}
              </Typography>
              {/* <Typography variant="h6" color="text.secondary">
                {candidate.name}
              </Typography> */}
            </Box>
            <Typography variant="body1" color="text.secondary" className="mb-3">
              {candidate.profil}
            </Typography>
            <Box className="flex flex-wrap gap-2 mb-6">
              <InfoChip
                icon={<LocationOnIcon />}
                label={candidate.phone || "phone"}
                size="small"
              />
              <InfoChip
                icon={<EmailIcon />}
                label={candidate.email}
                size="small"
                onClick={() => (window.location.href = `mailto:${candidate.email}`)}
                className="cursor-pointer"
              />
              <InfoChip
                icon={<LinkedInIcon />}
                label={candidate.linked_in || "LinkedIn not specified"}
                size="small"
              />
            </Box>

            {/* Languages */}
            <Box className="mb-2 mt-4">
              <Typography variant="h6" className="mb-3 font-semibold flex items-center">
                <Language className="w-6 h-6 mr-4" /> {/* Increased right margin */}
                Languages
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {candidate.language
                  ? candidate.language
                    .replace(/^\[|\]$/g, '')
                    .split(',')
                    .map(skill => skill.trim().replace(/^'|'$/g, ''))
                    .map((skill, index) => (
                      <Chip key={index} label={skill} className="mr-3" />
                    ))
                  : <Typography className="text-gray-500">No Languages listed</Typography>}
              </Box>
            </Box>

            {/* Education */}
            <Box className=" mt-4">
              <Typography variant="h6" className="mb-3 font-semibold flex items-center">
                <GraduationCap className="w-6 h-6 mr-4" /> {/* Increased right margin */}
                Education
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {candidate.education ? (
                  <Chip label={candidate.education} />
                ) : (
                  <Typography className="text-gray-500">No Education listed</Typography>
                )}
              </Box>
            </Box>
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} sm={6} md={4}>
            {/* Skills */}
            <Box className="mb-6">
              <Typography variant="h6" className="mb-3 font-semibold flex items-center">
                <Checklist className="w-6 h-6 mr-4" /> {/* Increased right margin */}
                SKILLS
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {candidate.skills
                  ? candidate.skills
                    .replace(/^\[|\]$/g, '')
                    .split(',')
                    .map(skill => skill.trim().replace(/^'|'$/g, ''))
                    .map((skill, index) => (
                      <Chip key={index} label={skill} className="mr-3" />
                    ))
                  : <Typography className="text-gray-500">No skills listed</Typography>}
              </Box>
            </Box>

            {/* Certificates */}
            <Box className="mt-4">
              <Typography variant="h6" className="mb-3 font-semibold flex items-center">
                <WorkspacePremium className="w-6 h-6 mr-4" /> {/* Increased right margin */}
                CERTIFICATES
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {candidate.certificates
                  ? candidate.certificates
                    .replace(/^\[|\]$/g, '')
                    .split(',')
                    .map(skill => skill.trim().replace(/^'|'$/g, ''))
                    .map((skill, index) => (
                      <Chip key={index} label={skill} className="mr-3" />
                    ))
                  : <Typography className="text-gray-500">No Certificates listed</Typography>}
              </Box>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={6} md={4} sx={{ mt: 15 }}>
            
            <Divider className="my-4" />
            <Box className="grid grid-cols-2 gap-4">
              <a
                href={`mailto:${candidate.email}`}
                style={{ textDecoration: 'none' }}
              >
                <ActionButton
                  variant="contained"
                  color="success"
                  startIcon={<EmailIcon />}
                  onClick={handleEmailClick}
                  className="bg-signIn text-white border-grey hover:bg-white hover:text-signIn"
                >
                  CONTACT
                </ActionButton>
              </a>

              <ActionButton sx={{ ml: 5 }}
                variant="contained"
                startIcon={<Download />}
                onClick={handleResumeClick}
                className="bg-signIn text-white border-grey hover:bg-white hover:text-signIn"
              >
                Resume
              </ActionButton>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 8, ml: 18 }}>
              Applied on: {new Date(candidate.date).toLocaleDateString()}
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
    </StyledCard >
  );
};

const CandidateCards = ({ candidates, post_title }) => {
  if (!Array.isArray(candidates)) {
    console.error("Candidates prop is not an array:", candidates);
    return null;
  }

  return (
    <Box>
      {candidates.map((candidate, index) => (
        <CandidateCard
          key={index}
          candidate={candidate}
          post_title={post_title}
          resume_path={candidate.resume}
          coverLetter={candidate.cover_letter}
        />
      ))}
    </Box>
  );
};

export default CandidateCards;