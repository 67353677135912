import React from "react";
import Chat from "../Chat/Chat";
import CreateRecruiter from "../../views/admin/createRecruiter";
import ListRecruiter from "../../views/admin/ListRecruiter";
import Settings from "views/Settings";
import FormCustomizer from "views/Posts/FormCustomizer";
import AdminProfile from "./adminProfile";
const adminRoutes = [
  {
    path: "chat",
    name: "Chat",
    icon: "fas fa-comment",
    component: <Chat />,
  },
  {
    path: "/ListRecruiter",
    name: "Our Recruiters",
    component: <ListRecruiter />,
  },
  {
    path: "/AdminProfile",
    name: "Admin Profile",
    icon: "fas fa-users",
    component: <AdminProfile />,
    layout: "/manager",
  },
  {
    path: "settings",
    name: "Settings",
    icon: "fas fa-cog",
    component: <Settings/>,
   
  },
  {
    path: "/candidature_spontanee",
    name: "Spontaneous application",
    icon: "fas fa-cog",
    component: <FormCustomizer />,
  },
];

export default adminRoutes;
