import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import axios from "axios";
import { Toast } from "primereact/toast";
import { COLORS } from "colors/color";
import TextDivider from "components/Deviders/textDevider";
import { GoogleLogin } from "react-google-login";
import bgImage from "../../assets/img/bgLogin.png";
import logoImage from "../../assets/img/logoSH2.png";
import linkedinLogo from "../../assets/img/linkedin.png";
import googleLogo from "../../assets/img/google.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const LoginPage = (props) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [authMode, setAuthMode] = useState("signin");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [password2, setPassword2] = useState("");
  const passwordsMatch = password === password2;
  const toast = useRef(null);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const clientId =
    "1065255482933-3pgtuvdlckvr8s4uo1ecko09gm2blo9s.apps.googleusercontent.com";

  useEffect(() => {
    if (location.state?.authMode === "signup") {
      setAuthMode("signup");
    }
  }, [location.state]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (window.location.href.includes("code=")) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      fetchCallbackData(code);
    }
  }, []);

  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const toggleAuthMode = () =>
    setAuthMode(authMode === "signin" ? "signup" : "signin");

  // const connexion = async () => {
  //   try {
  //     const response = await axios.post(`${apiUrl}authentication/login/`, {
  //       email: login,
  //       password: password,
  //     });
  //     localStorage.setItem("accessToken", response.data.access_token);
  //     localStorage.setItem("refreshToken", response.data.refresh_token);

  //     const userId = getUserIdFromAccessToken();
  //     if (userId) {
  //       props.setUserId(userId);
  //     }

  //     if (response.data.user.role === "r") {
  //       redirectToDashboard();
  //     } else if (response.data.user.role === "a") {
  //       redirectToAdminA();
  //     } else {
  //       redirectToHome();
  //     }

  //     setLogin("");
  //     setPassword("");
  //     // toast.current.show({
  //     //   severity: "success",
  //     //   summary: "Successful",
  //     //   detail: "Logged in",
  //     //   life: 3000,
  //     // });
  //     setSnackbar({
  //       open: true,
  //       message: "Successful",
  //       severity: "error",
  //     });
  //   } catch (err) {
  //     // toast.current.show({
  //     //   severity: "error",
  //     //   summary: "Error",
  //     //   detail: "Login failed. Please check your credentials.",
  //     //   life: 3000,
  //     // });
  //     setSnackbar({
  //       open: true,
  //       message: "Login failed. Please check your credentials.",
  //       severity: "error",
  //     });
  //   }
  // };
  // Frontend (React)
  const connexion = async () => {
    try {
      // Input validation
      if (!login || !password) {
        setSnackbar({
          open: true,
          message: "Email and password are required",
          severity: "error",
        });
        return;
      }

      const response = await axios.post(`${apiUrl}authentication/login/`, {
        email: login,
        password: password,
      });

      // Handle successful login
      if (response.data.status === "success") {
        localStorage.setItem("accessToken", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);

        const userId = getUserIdFromAccessToken();
        if (userId) {
          props.setUserId(userId);
        }

        // Handle role-based navigation
        switch (response.data.user.role) {
          case "r":
            redirectToDashboard();
            break;
          case "a":
            redirectToAdminA();
            break;
          default:
            redirectToHome();
        }

        setLogin("");
        setPassword("");

        setSnackbar({
          open: true,
          message: "Successfully logged in",
          severity: "success",
        });
      }
    } catch (err) {
      if (err.response?.status === 429 && err.response?.data?.time_left) {
        const timeLeftMinutes = err.response.data.time_left;
        setSnackbar({
          open: true,
          message: `Too many attempts. Please try again in ${timeLeftMinutes} minute(s).`,
          severity: "warning",
        });
        return;
      }
      // Handle different error scenarios based on backend response
      const errorMessage =
        err.response?.data?.detail || "An unexpected error occurred";
      const errorCode = err.response?.data?.code || "UNKNOWN_ERROR";

      let snackbarMessage = "";
      let severity = "error";

      switch (errorCode) {
        case "MISSING_FIELDS":
          snackbarMessage = "Please fill in all required fields";
          break;
        case "INVALID_EMAIL_FORMAT":
          snackbarMessage = "Please enter a valid email address";
          break;
        case "INVALID_CREDENTIALS":
          snackbarMessage = "Invalid email or password";
          break;
        case "ACCOUNT_INACTIVE":
          snackbarMessage =
            "Please verify your email address to activate your account";
          severity = "warning";
          break;
        case "ACCOUNT_LOCKED":
          snackbarMessage =
            "Your account has been locked. Please contact support";
          severity = "warning";
          break;
        case "TOKEN_GENERATION_FAILED":
          snackbarMessage = "Authentication failed. Please try again";
          break;
        case "NETWORK_ERROR":
          snackbarMessage =
            "Unable to connect to the server. Please check your internet connection";
          break;
        default:
          snackbarMessage = errorMessage;
      }

      setSnackbar({
        open: true,
        message: snackbarMessage,
        severity: severity,
      });
    }
  };
  // const registration = async () => {
  //   try {
  //     await axios.post(`${apiUrl}authentication/signup/`, {
  //       username: name,
  //       email: email,
  //       password: password,
  //       gender: "m",
  //     });

  //     setName("");
  //     setPassword2("");
  //     setEmail("");

  //     setSnackbar({
  //       open: true,
  //       message: "Success",
  //       severity: "error",
  //     });
  //     toggleAuthMode();
  //   } catch (err) {

  //     setSnackbar({
  //       open: true,
  //       message: "Registration failed. Please try again.",
  //       severity: "error",
  //     });
  //   }
  // };
  const [errors, setErrors] = useState([]);

  const registration = async () => {
    try {
      const response = await axios.post(`${apiUrl}authentication/signup/`, {
        username: firstname + lastname,
        first_name: firstname,
        last_name: lastname,
        gender: gender,
        email: email,
        password: password

      });
      console.log("res", response);

      // Clear form and show success message
      setFirstName("");
      setLastName("");
      setPassword("");
      setPassword2("");
      setEmail("");
      setGender("");
      setErrors([]);

      setSnackbar({
        open: true,
        message:
          "Registration successful! Please check your email for verification.",
        severity: "success",
      });

      toggleAuthMode();
    } catch (err) {
      // Handle validation errors
      if (err.response?.data) {
        const errorData = err.response.data;

        if (errorData.details) {
          // Password validation errors
          setErrors(errorData.details);
          setSnackbar({
            open: true,
            message: "Please fix the password issues below",
            severity: "error",
          });
        } else if (errorData.error) {
          // Other validation errors (e.g., email exists)
          setErrors([errorData.error]);
          setSnackbar({
            open: true,
            message: errorData.error,
            severity: "error",
          });
        }
      } else {
        // Unexpected errors
        setSnackbar({
          open: true,
          message: "An unexpected error occurred. Please try again.",
          severity: "error",
        });
      }
    }
  };
  const onGoogleSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    fetchGoogleData(res);
  };

  const onGoogleFailure = (res) => {
    console.error("Login failed: res:", res);
  };

  const linkedinAuth = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86dpwd87p8nanb&redirect_uri=${window.location.origin}/login&state=1234&scope=openid%20email%20profile`;
  };

  const fetchGoogleData = async (res) => {
    try {
      const response = await axios.get(
        `${apiUrl}authentication/google/userinfo/`,
        { withCredentials: true, params: res.profileObj }
      );
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        props.setUserId(userId);
      }

      if (response.data.user.role === "r") {
        redirectToDashboard();
      } else if (response.data.user.role === "a") {
        redirectToAdminA();
      } else {
        redirectToHome();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCallbackData = async (code) => {
    try {
      const params = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: `${apiUrl}login`,
        client_id: "86dpwd87p8nanb",
        client_secret: "vZYhlaKOFW1tqXhk",
      };
      const response = await axios.get(
        `${apiUrl}authentication/linkedin-callback/`,
        { params: params }
      );
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        props.setUserId(userId);
      }

      if (response.data.user.role === "r") {
        redirectToDashboard();
      } else if (response.data.user.role === "a") {
        redirectToAdminA();
      } else {
        redirectToHome();
      }
    } catch (error) {
      console.error("Error fetching callback data:", error);
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return null;

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const redirectToAdminA = () => navigate("/manager/createRecruiter");
  const redirectToDashboard = () => navigate("/talent/dashboard");
  const redirectToHome = () => navigate("/home");

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ height: "100vh", display: "flex" }}
    >
      <Toast ref={toast} />
      <Grid
        container
        sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}
      >
        <Grid
          item
          xs={12}
          md={13}
          lg={8}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            bgcolor: "white",
            borderRadius: "20px",
            overflow: "hidden",
            height: { md: "90vh" },
          }}
        >
          {/* Left side - Image and slogan */}
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 45%" },
              p: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              bgcolor: "#1976d2",
              color: "white",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "20px",
              height: { xs: "40vh", md: "100%" },
            }}
          >
            {/* Logo Container */}
            <Box
              sx={{
                flex: "1",
                display: "flex",
                alignItems: "flex-start", // Aligns the logo to the top
                justifyContent: "flex-start", // Aligns the logo to the left
                pt: 0, // Removes any top padding
                pl: 0,
              }}
            >
              <img
                src={logoImage}
                alt="SMARTHIRE"
                style={{
                  maxWidth: "90px",
                  height: "auto",
                }}
              />
            </Box>

            {/* Text Container */}
            <Box
              sx={{
                mt: "auto", // Push to bottom
                mb: 0,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  textTransform: "none",
                  fontSize: { xs: "1rem", md: "2.25rem" },
                  lineHeight: 1.2,
                  fontFamily: "Poppins",
                  pr: 8,
                }}
              >
                Elevate your chances of recruitment with our AI-Powered
                recruiter
              </Typography>
            </Box>
          </Box>

          {/* Right side - Login/Signup form */}
          <Box sx={{ p: 5, maxWidth: 700, margin: "auto" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: COLORS.title_color,
                fontSize: "30px",
                fontFamily: "Poppins",
              }}
            >
              {authMode === "signin" ? "Welcome back!" : "Create Account"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "14px", fontFamily: 'Poppins' }}
            >
              {authMode === "signin"
                ? "Please enter your information"
                : "Please fill in your details"}
            </Typography>

            <Box component="form" noValidate>
              {authMode === "signin" ? (
                // Sign In Form
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Enter your email"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 1, width: 450, fontFamily: 'Poppins' }}
                    InputProps={{ sx: { borderRadius: 20, fontFamily: 'Poppins' } }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 1, width: 450, fontFamily: 'Poppins' }}
                    InputProps={{
                      sx: { borderRadius: 20, fontFamily: 'Poppins' },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    href="#"
                    variant="body2"
                    sx={{
                      display: "block", mt: 1, mb: 2, color: "#1976d2", fontFamily: 'Poppins',
                      textTransform: 'none',
                    }}
                  >
                    Forgot your password?
                  </Link>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={connexion}
                    sx={{
                      mb: 1,
                      p: 1.3,
                      bgcolor: COLORS.button_color,
                      color: "white",
                      borderRadius: 20,
                      fontWeight: "bold",
                      "&:hover": { bgcolor: "#1565c0" },
                      fontFamily: 'Poppins',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: '400px',
                    }}
                  >
                    Sign in
                  </Button>
                </>
              ) : (
                // Sign Up Form
                <>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={4.5}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          placeholder="Enter your First name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          variant="outlined"
                          sx={{ mb: 1 }}
                          InputProps={{ sx: { borderRadius: 20 } }}
                        />
                      </Grid>
                      <Grid item xs={4.5}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          placeholder="Enter your Last name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          variant="outlined"
                          sx={{ mb: 1 }}
                          InputProps={{ sx: { borderRadius: 20 } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                          <InputLabel>Gender</InputLabel>
                          <Select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            label="Gender"
                            sx={{ borderRadius: 20 }}
                          >
                            <MenuItem value="m">Mr</MenuItem>
                            <MenuItem value="f">Ms</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      sx={{ mb: 1 }}
                      InputProps={{ sx: { borderRadius: 20 } }}
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      sx={{ mb: 1 }}
                      InputProps={{
                        sx: { borderRadius: 20 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Confirm your password"
                      type={showPassword ? "text" : "password"}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      error={password2 !== "" && !passwordsMatch}
                      helperText={
                        password2 !== "" && !passwordsMatch
                          ? "Passwords do not match"
                          : ""
                      }
                      variant="outlined"
                      sx={{ mb: 1 }}
                      InputProps={{
                        sx: { borderRadius: 20 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={registration}
                    disabled={!passwordsMatch}
                    sx={{
                      mb: 1,
                      p: 1.3,
                      bgcolor: COLORS.button_color,
                      color: "white",
                      borderRadius: 20,
                      fontWeight: "bold",
                      "&:hover": { bgcolor: "#1565c0" },
                      fontFamily: 'Poppins',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: '400px',
                    }}
                  >
                    Sign up
                  </Button>
                </>
              )}

              <Button
                fullWidth
                variant="outlined"
                onClick={toggleAuthMode}
                sx={{
                  p: 1.3,
                  color: COLORS.button_color,
                  borderColor: COLORS.button_color,
                  borderRadius: 20,
                  // fontWeight: "bold",
                  fontFamily: 'Poppins',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: '400px',
                }}
              >
                {authMode === "signin"
                  ? "Not registered yet? Sign up"
                  : "Already have an account? Sign in"}
              </Button>

              <Typography
                variant="body2"
                align="center"
                sx={{ my: 2, color: "rgba(0.0.0.0.3) " }}
              >
                <TextDivider>Or continue with</TextDivider>
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GoogleLogin
                    clientId={clientId}
                    onSuccess={onGoogleSuccess}
                    onFailure={onGoogleFailure}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={false}
                    render={({ onClick }) => (
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={onClick}
                        startIcon={
                          <img
                            src={googleLogo}
                            alt="Google Logo"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }} // Adjust size and spacing as needed
                          />
                        }
                        sx={{
                          p: 1.3,
                          borderRadius: 20,
                          color: COLORS.button_color,
                          borderColor: COLORS.button_color,
                          fontFamily: 'Poppins',
                          textTransform: 'none',

                        }}
                      >
                        Sign in with Google
                      </Button>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={linkedinAuth}
                    startIcon={
                      <img
                        src={linkedinLogo}
                        alt="Linkedin Logo"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                        }} // Adjust size and spacing as needed
                      />
                    }
                    sx={{
                      p: 1.3,
                      color: COLORS.button_color,
                      borderColor: COLORS.button_color,
                      borderRadius: 20,
                      fontFamily: 'Poppins',
                      textTransform: 'none',

                    }}
                  >
                    Sign in with LinkedIn
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(3px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)",
          },
        }}
        message={snackbar.message}
      ></Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
