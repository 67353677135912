import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.jsx";

const Wrapper = styled.div`
  display: flex;
`;

const MainPanel = styled.div`
  flex: 1; 
  min-height: 100vh;
  margin-left: 37vh; 
  transition: all 0.3s ease;
  overflow-x: hidden;
  position: relative;
  color: '#9CA986'
`;

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 220px; 
  right: 0;
  z-index: 1030;
`;

const ContentWrapper = styled.div`
  padding-top: 30px; 
  padding-left: 20px;
  padding-right: 20px;
`;

var ps;

function Dashboard(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, []);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const handleActiveClick = (color) => {
    setActiveColor(color);
  };

  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };

  return (
    <Wrapper
    style={{backgroundColor:'#F4F5F7'}}>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
      />
      <MainPanel ref={mainPanel} style={{backgroundColor:'#F4F5F7'}}>
        <NavbarWrapper>
          <DemoNavbar {...props} />
        </NavbarWrapper>
        <ContentWrapper>
          <Routes>
            {routes.map((prop, key) => (
              <Route
                path={prop.path}
                element={prop.component}
                key={key}
                exact
              />
            ))}
          </Routes>
        </ContentWrapper>
        <Footer fluid />
      </MainPanel>
    </Wrapper>
  );
}

export default Dashboard;