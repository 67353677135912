import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Avatar,
  Divider,
  Snackbar,
  CircularProgress,
  Container,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import { useParams } from "react-router-dom";
import FileUpload from "components/File Upload/FileUpload";
import ErrorDisplay from "components/Error display/NoFormStructure";
import { CloudUpload } from "bootstrap-icons-react";
import { AttachFile, DriveFileRenameOutline, Note } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { CameraIcon } from "lucide-react";
const UploadCard = styled(Card)(({ theme, isDragging, isUploaded }) => ({
  width: "100%",
  height: "100%",
  minHeight: "200px",
  borderRadius: "16px",
  boxShadow: "none",
  transition: "all 0.3s ease",
  backgroundColor: isUploaded
    ? "rgba(255, 255, 255, 0.3)"
    : "rgba(255, 255, 255, 0.2)",
  backdropFilter: "blur(10px)",
  border: "2px dashed",
  borderColor: isDragging
    ? theme.palette.primary.main
    : isUploaded
      ? "rgba(0, 0, 0, 0.4)"
      : "rgba(224, 224, 224, 0.6)",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  transform: isDragging ? "scale(1.02)" : "scale(1)",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
    backgroundColor: "rgba(255, 255, 255, 0.35)",
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const IconWrapper = styled(Box)(({ theme, isUploaded }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 48,
    marginBottom: theme.spacing(2),
    color: isUploaded ? "rgba(0, 0, 0, 0.7)" : "rgba(158, 158, 158, 0.8)",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: "visible",
  background: "#ffffff",
  boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  border: "1px solid #f0f0f0",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 40px 0 rgba(0, 0, 0, 0.15)",
  },
}));

const CompanyInfoCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  overflow: "visible",
  background: "#ffffff",
  boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  border: "1px solid #f0f0f0",
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(2),
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "110%",
    height: "110%",
    borderRadius: "50%",
    background: "#f5f5f5",
    zIndex: -1,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  border: "4px solid #fff",
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: 600,
  color: "#333",
  marginBottom: theme.spacing(3),
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-8px",
    left: 0,
    width: "40px",
    height: "4px",
    backgroundColor: "#333",
    borderRadius: "2px",
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  "& > svg": {
    marginRight: theme.spacing(1),
    color: "#555",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
    },
    "&.Mui-focused": {
      boxShadow: "0 0 0 2px #333",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ccc",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  padding: "12px 30px",
  background: "#333",
  color: "#fff",
  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    background: "#555",
    transform: "scale(1.05)",
    boxShadow: "0 6px 10px 4px rgba(0, 0, 0, .15)",
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: "10px 20px",
  background: "#f5f5f5",
  color: "#333",
  border: "2px dashed #ccc",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    background: "#e0e0e0",
    borderColor: "#999",
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: "5px 10px",
  background: "#f0f0f0",
  borderRadius: "5px",
  fontSize: "0.875rem",
  color: "#555",
}));


const FileUpload2 = React.forwardRef(({ field, handleFileChange }, ref) => {
  const inputRef = useRef();

  const handleInputChange = (event) => {
    const files = Array.from(event.target.files);
    handleFileChange(field.name, files);
  };

  React.useImperativeHandle(ref, () => ({
    removeFile: () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }));

  return (
    <Box>
      <Typography variant="subtitle1">{field.label}</Typography>
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleInputChange}
        multiple={field.multiple}
        accept={field.accept}
        name={field.name}
      />
      <Box
        onClick={() => inputRef.current.click()}
        sx={{
          p: 2,
          border: '2px dashed #ccc',
          borderRadius: 1,
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
            bgcolor: 'rgba(0, 0, 0, 0.04)'
          },
          textAlign: 'center'
        }}
      >
        <Typography>Click to upload or drag files here</Typography>
      </Box>
    </Box>
  );
});
const FileUploadCard = ({ type, isUploaded, register, handleFileUpload }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const syntheticEvent = {
        target: {
          files: files,
        },
      };
      handleFileUpload(
        syntheticEvent,
        type === "resume" ? "resume" : "coverLetter"
      );
    }
  };

  const isResume = type === "resume";
  const Icon = isResume ? AttachFile : Note;
  const title = isResume ? "Resume - CV" : "Cover Letter";
  const subtitle = isResume
    ? "Accepted formats: PDF, DOCX, JPEG"
    : "Please express your motivation ";

  return (
    <Grid item xs={6}>
      <HiddenInput
        id={`${type}-upload`}
        type="file"
        {...register(type === "resume" ? "resume" : "cover_letter", {
          onChange: (e) =>
            handleFileUpload(e, type === "resume" ? "resume" : "coverLetter"),
        })}
      />
      <label
        htmlFor={`${type}-upload`}
        style={{ display: "block", height: "100%" }}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <UploadCard isDragging={isDragging} isUploaded={isUploaded}>
          <CardContent sx={{ padding: "24px", textAlign: "center" }}>
            <IconWrapper isUploaded={isUploaded}>
              <Icon />
            </IconWrapper>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: isUploaded
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.7)",
                mb: 1,
              }}
            >
              {isDragging
                ? "Drop here"
                : isUploaded
                  ? `${title} Uploaded`
                  : `Upload your ${title}`}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                mt: 1,
              }}
            >
              {isDragging ? "Release to upload" : subtitle}
            </Typography>
          </CardContent>
        </UploadCard>
      </label>
    </Grid>
  );
};

const ApplicationFormSp = () => {
  const [formData, setFormData] = useState({});
  const [formStructure, setFormStructure] = useState([]);
  const [adminId, setAdminId] = useState(null);
  const [description, setDescription] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { companyName } = useParams();
  const [files, setFiles] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fileUploadKey, setFileUploadKey] = useState(0);
  const fileUploadRef = useRef();
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [coverLetterUploaded, setCoverLetterUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ resumes: null });
  const [uploadedFilesList, setUploadedFilesList] = useState({ resumes: null });
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [uploadedResume, setUploadedResume] = useState();
  const [uploadedCover, setUploadedCover] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const fileInputRef = useRef(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [moduleExists, setModuleExists] = useState(true);
  const { register } = useForm();



  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };
  const CompanyInfo = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  }));
  
  const CompanyLogoContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
  }));
  
  const CompanyLogo = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    borderRadius: "50%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "2px solid #fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  }));
  
  const LogoAvatar = styled(Avatar)(({ theme }) => ({
    width: "100%",
    height: "100%",
    fontSize: "16px",
    fontWeight: "bold",
  }));

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchFormStructure();
    fetchAdminData();
  }, []);

  useEffect(() => {
    // Check for admin module availability when adminData changes
    if (adminData) {
      setModuleExists(!!adminData);
      setError(adminData ? null : 'Module not available for this admin.');
    }
  }, [adminData]);

  const fetchFormStructure = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}authentication/application-forms-by-company/`,
        {
          params: { company: companyName },
        }
      );
      if (response.data && response.data.length > 0) {
        setFormStructure(response.data[0].form_structure);
        setAdminId(response.data[0].admin);
        setDescription(response.data[0].description)
      } else {
        setError("No form structure found for this company");
      }
    } catch (error) {
      console.error("Error fetching form structure:", error);
      setError("Failed to fetch form structure");
    }
  };

  const preset_key = "tpjsjr7k";
  const cloud_name = "dwckvxlca";
  function handleFile(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset_key);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      )
      .then((res) => {
        const imageUrl = res.data.secure_url;
        // console.log('here the imageUrl: ',imageUrl);
        setImageUpload(imageUrl);
        // console.log('here the image: ',imageUpload);
        setFormData((prev) => ({ ...prev, image: imageUrl }));
      })
      .catch((err) => console.log(err));
  };
  function handleImageClick() {
    fileInputRef.current.click();
  };
  const handleEdit = () => {
    setIsEditing(true);
  };


  const fetchAdminData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}authentication/get-admin-by-company/`,
        {
          params: { company: companyName },
        }
      );
      setAdminData(response.data);
      // console.log('here is the logo: ', adminData.company_logo);
    } catch (err) {
      console.error("Error fetching admin data:", err);
      setError("No form structure for this company");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (fieldName, uploadedFiles) => {
    console.log(`Files uploaded for ${fieldName}:`, uploadedFiles); // Log to check files

    // Store full file objects in `files` for later use in `FormData`
    setFiles(prevFiles => ({
      ...prevFiles,
      [fieldName]: uploadedFiles
    }));

    // Store only the file names in `formData`
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: uploadedFiles.map(file => file.name)  // Just file names
    }));
  };

  const handleFileUpload = (event, fileType) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (fileType === "resume") {
        setResumeUploaded(true);
        setUploadedResume(file);
      } else if (fileType === "cover_letter") {
        setCoverLetterUploaded(true);
        setUploadedCover(file);
      }
    }
  };



  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();

    // Add non-file fields to `form_data`
    const formDataObject = {};
    formDataToSend.append('admin', adminId);
    formDataToSend.append('image', imageUpload);
    Object.keys(formData).forEach(key => {
      formDataObject[key] = formData[key];
    });
    formDataToSend.append('form_data', JSON.stringify(formDataObject));

    // Add `admin` ID or other necessary fields

    // Add files if they exist
    if (uploadedResume) {
      formDataToSend.append('cv', uploadedResume);
    }
    else {
      alert("Please upload your resume.");
      return;
    }
    if (uploadedCover) {
      formDataToSend.append('cover_letter', uploadedCover);
      console.log("cover to send:", uploadedCover);
    }

    console.log("FormData to send:", formDataToSend);

    try {
      const response = await axios.post(
        `${apiUrl}authentication/form-submissions/`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        showSnackbar('Form saved successfully!', 'success');
        setFormData({});
        setFiles({});
        setFileUploadKey(prevKey => prevKey + 1);
        if (fileUploadRef.current && fileUploadRef.current.removeFile) {
          fileUploadRef.current.removeFile();
        }
        setResumeUploaded(false);
        setCoverLetterUploaded(false);
      }
    } catch (err) {
      console.error("Error sending application data:", err);
      const errorMessage = err.response?.data?.error === "This email has already been submitted"
        ? 'This email has already been submitted. Please use a different email.'
        : 'Failed to submit form';
      showSnackbar(errorMessage, 'error');
    }
  };


  const renderField = (field) => {
    switch (field.type) {
      case "text":
      case "email":
      case "number":
      case "date":
        return (
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type}
              value={formData[field.name] || ""}
              onChange={handleChange}
              variant="outlined"
              required={field.required}
              InputLabelProps={field.type === "date" ? { shrink: true } : undefined}
            />
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" required={field.required}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleChange}
                label={field.label}
              >
                {field.options.map((option) => (
                  <MenuItem
                    key={option.value || option}
                    value={option.value || option}
                  >
                    {option.label || option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case "radio":
        return (
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" required={field.required}>
              <Typography variant="subtitle1">{field.label}</Typography>
              <RadioGroup
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleChange}
                row // This makes the radio buttons display horizontally
              >
                {field.options.map((option) => (
                  <FormControlLabel
                    key={option.value || option}
                    value={option.value || option}
                    control={<Radio />}
                    label={option.label || option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

        );
      default:
        return null;
    }
  };

  const renderFileUploads = () => {
    return formStructure
      .filter((field) => field.type === "file")
      .map((field, index) => (
        <Grid item xs={12} key={index}>
          <FileUpload2
            key={`${fileUploadKey}-${index}`}
            ref={fileUploadRef}
            field={field}
            handleFileChange={handleFileChange}
          />
          {files[field.name] && files[field.name].length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Selected Files:</Typography>
              {files[field.name].map((file, fileIndex) => (
                <Box
                  key={fileIndex}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 1,
                    p: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1
                  }}
                >
                  <Typography variant="body2">
                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                  </Typography>
                  <button
                    onClick={() => handleFileChange(
                      field.name,
                      files[field.name].filter((_, idx) => idx !== fileIndex)
                    )}
                    style={{
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                      color: 'red'
                    }}
                  >
                    ×
                  </button>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      ));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: "#333" }} />
      </Box>
    );
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {adminData && (
        <CompanyInfoCard elevation={0}>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <AvatarWrapper>
                  <StyledAvatar
                    src={adminData.company_logo}
                    alt={adminData.company}
                  />
                </AvatarWrapper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#333" }}
                >
                  {adminData.company}
                </Typography>
                <InfoItem>
                  <LocationOnIcon />
                  <Typography variant="body1">
                    {adminData.address}, {adminData.city}, {adminData.country}
                  </Typography>
                </InfoItem>
                <InfoItem>
                  <BusinessIcon />
                  <Typography variant="body1">
                    Industry: {adminData.comp_industry}
                  </Typography>
                </InfoItem>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, fontStyle: "italic", color: "#555", fontWeight: "bold" }}
                >
                  {description}
                </Typography>
                <Divider sx={{ my: 2, backgroundColor: "#ccc" }} />
                <InfoItem>
                  <PersonIcon />
                  <Typography variant="body2">
                    Admin: {adminData.first_name} {adminData.last_name}
                  </Typography>
                </InfoItem>
                <InfoItem>
                  <EmailIcon />
                  <Typography variant="body2">
                    Contact: {adminData.email}
                  </Typography>
                </InfoItem>
              </Grid>
            </Grid>
          </CardContent>
        </CompanyInfoCard>
      )}

      <StyledCard>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <FormSectionTitle>Application Form</FormSectionTitle>
            <CompanyInfo>
              <CompanyLogoContainer>
                <CompanyLogo>
                  <LogoAvatar src={formData.image} alt={formData.first_name} />
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                  <IconButton
                    onClick={handleImageClick}
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "white",
                      "&:hover": { backgroundColor: "#f0f0f0" },
                    }}
                  >
                    <CameraIcon />
                  </IconButton>
                </CompanyLogo>
              </CompanyLogoContainer>
            </CompanyInfo>
            <Grid container spacing={3}>
              
              {formStructure
                .filter((field) => field.type !== "file")
                .map((field, index) => renderField(field))}
            </Grid>
            <Box sx={{ mt: 4 }}>
              {/* <Typography variant="h6" gutterBottom>
                File Uploads
              </Typography> */}
              <Grid
                container
                spacing={5}
                sx={{ paddingLeft: "3%" }}
              >
                <Grid
                  container
                  spacing={5}
                  sx={{ marginTop: "2%", paddingLeft: "3%" }}
                >
                  <FileUploadCard
                    type="resume"
                    isUploaded={resumeUploaded}
                    register={register}
                    handleFileUpload={(e) => handleFileUpload(e, "resume")}
                  />
                  <FileUploadCard
                    type="cover_letter"
                    isUploaded={coverLetterUploaded}
                    register={register}
                    handleFileUpload={(e) => handleFileUpload(e, "cover_letter")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <StyledButton type="submit" variant="contained" size="large">
                Submit Application
              </StyledButton>
            </Box>
          </form>
        </CardContent>
      </StyledCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "black",
            borderRadius: "50px",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)",
          },
        }}
      />
    </Container>
  );
};

export default ApplicationFormSp;
