import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, Select, MenuItem, Grid } from '@mui/material';

const SalaryInputWithCurrency = ({ salary, setSalary, currency, setCurrency }) => {
  const currencies = [
    { value: 'MAD', label: 'Moroccan Dirham' },
    { value: 'USD', label: 'US Dollar' },
    { value: 'EUR', label: 'Euro' },
    { value: 'GBP', label: 'British Pound' },
  ];

  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={4} >
        <FormControl style={{width:'90%'}}>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            id="currency-select"
            value={currency}
            label="Currency"
            onChange={(event) => setCurrency(event.target.value)}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel htmlFor="salary-input">Salary</InputLabel>
          <OutlinedInput
            id="salary-input"
            value={salary}
            onChange={(event) => setSalary(event.target.value)}
            startAdornment={
              <InputAdornment position="start">{currency}</InputAdornment>
            }
            label="Salary"
            type="number"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SalaryInputWithCurrency;