import { useEffect } from 'react';

export default function useComponentZoom(selector, zoomLevel) {
  useEffect(() => {
    function adjustZoom() {
      const elements = document.querySelectorAll(selector);
      elements.forEach(el => {
        el.style.transform = `scale(${zoomLevel})`;
        el.style.transformOrigin = 'top left';
        el.style.width = `${100 / zoomLevel}%`;
        el.style.height = `${100 / zoomLevel}%`;
      });
    }
    
    adjustZoom();
    window.addEventListener('resize', adjustZoom);
    
    return () => window.removeEventListener('resize', adjustZoom);
  }, [selector, zoomLevel]);
}