import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
  IconButton,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';

const UrlBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: 'rgba(25, 118, 210, 0.08)',
  color: theme.palette.text.primary,
  fontFamily: 'monospace',
  fontSize: '0.9rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const ApplicationFilters = ({
  filters = { name: '', educationLevel: '', experienceYears: '' },
  onFilterChange = () => {},
  company = '',
  onCopyUrl = () => {},
  copied = false
}) => {
  return (
    <Box
      sx={{
        mb: 3,
        p: 3,
        backgroundColor: 'background.paper',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(135deg, #f6f8fc 0%, #ffffff 40%)',
      }}
    >
      {/* URL Section */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LinkIcon sx={{ color: 'primary.main' }} />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                color: 'text.primary'
              }}
            >
              Spontaneous Application URL:
            </Typography>
          </Box>
          
          <UrlBox>
            <Typography
              sx={{
                wordBreak: 'break-all',
                flex: 1
              }}
            >
              {window.location.origin + company}
            </Typography>
            <IconButton
              onClick={onCopyUrl}
              size="small"
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.12)',
                },
              }}
            >
              {copied ? (
                <CheckIcon sx={{ color: 'success.main' }} />
              ) : (
                <ContentCopyIcon sx={{ color: 'primary.main' }} />
              )}
            </IconButton>
          </UrlBox>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Filters Section */}
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        '& > *': { flex: { md: 1 } }
      }}>
        <TextField
          fullWidth
          name="name"
          value={filters.name}
          onChange={onFilterChange}
          placeholder="Search by candidate name..."
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        
        <FormControl fullWidth size="small">
          <InputLabel>Education</InputLabel>
          <Select
            name="educationLevel"
            value={filters.educationLevel}
            onChange={onFilterChange}
            label="Education"
          >
            <MenuItem value="">Any Education</MenuItem>
            <MenuItem value="bac">Bac</MenuItem>
            <MenuItem value="bac+2">Bac +2</MenuItem>
            <MenuItem value="bac+3">Bac +3</MenuItem>
            <MenuItem value="bac+5">Bac +5</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl fullWidth size="small">
          <InputLabel>Experience</InputLabel>
          <Select
            name="experienceYears"
            value={filters.experienceYears}
            onChange={onFilterChange}
            label="Experience"
          >
            <MenuItem value="">Any Experience</MenuItem>
            <MenuItem value="0-2">0 - 2 years</MenuItem>
            <MenuItem value="3-5">3 - 5 years</MenuItem>
            <MenuItem value="5+">5+ years</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ApplicationFilters;