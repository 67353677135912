import React from "react";
import { Menu, MenuItem, Divider } from "@mui/material";
import {
  Person as PersonIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";

const ModernProfileMenu = ({
  profileAnchorEl,
  isMenuOpen,
  setProfileAnchorEl,
  navToProfile,
  logOut,
}) => {
  const menuId = "primary-profile-menu";

  return (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",

      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={() => setProfileAnchorEl(null)}
      TransitionProps={{
        timeout: 200,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          mt: 3,
          width: '150px', // Match parent Box width
          background: '#0085FF1A',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          color: "white",
          overflow: "hidden",
          borderRadius: "12px",
          transition: "all 0.3s ease",
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            zIndex: -1,
          },
          "& .MuiMenuItem-root": {
            px: 1.5,
            py: 1.5,
            fontSize: "15px",
            transition: "all 0.3s ease",
            position: "relative",
            overflow: "hidden",
            "&:before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              width: "2px",
              height: "100%",
              backgroundColor: "#0085FF",
              transform: "scaleY(0)",
              transition: "transform 0.3s ease",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 133, 255, 0.1)",
              "&:before": {
                transform: "scaleY(1)",
              },
              "& .MuiSvgIcon-root": {
                transform: "translateX(2px)",
                color: "#0085FF",
                transition: "all 0.3s ease",
              },
            },
          },
          "& .MuiDivider-root": {
            borderColor: "rgba(255, 255, 255, 0.1)",
            margin: "1px 4px",
          },
        },
      }}
    >
      <div
        style={{
          padding: "8px 12px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          background: "rgba(0, 133, 255, 0.1)",
        }}
      >
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginBottom: "2px",
          }}
        >
          Menu
        </div>
        <div
          style={{
            fontSize: "10px",
            opacity: 0.7,
          }}
        >
          Account
        </div>
      </div>

      <MenuItem
        onClick={navToProfile}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          "& .MuiSvgIcon-root": {
            fontSize: 16,
            color: "rgba(255, 255, 255, 0.9)",
            transition: "all 0.3s ease",
          },
        }}
      >
        <PersonIcon />
        <span>Profile</span>
      </MenuItem>

      <Divider />

      <MenuItem
        onClick={logOut}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          "& .MuiSvgIcon-root": {
            fontSize: 16,
            color: "rgba(255, 255, 255, 0.9)",
            transition: "all 0.3s ease",
          },
        }}
      >
        <LogoutIcon />
        <span>Logout</span>
      </MenuItem>
    </Menu>
  );
};

export default ModernProfileMenu;