import React, { useEffect, useState } from "react";
import { Link, NavLink as RouterNavLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useSignalingStore from "views/signalingStore";
import logoImage from "../../assets/img/logoSH2.png";
import ModernProfileMenu from "components/profileMenu/profileMenu";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdNotificationsOutline } from "react-icons/io";
import { TbMessageCircle } from "react-icons/tb";

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

const NavLink = styled(RouterNavLink)({
  color: "#1a1a1a",
  textDecoration: "none",
  padding: "8px 20px",
  marginRight: "16px",
  padding: "24px",
  fontWeight: 500,
  fontSize: "15px",
  position: "relative",
  transition: "all 0.3s ease",
  textShadow: "0 0 5px rgba(37, 99, 235, 0.5), 0 0 10px rgba(37, 99, 235, 0.4)", // Neon effect on hover

  "&:hover": {
    color: "#2563eb",
  },
  "&.active": {
    color: "#2563eb",
    textShadow:"0 0 5px #fff, 0 0 10px #fffa, 0 0 15px #fff",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-2px", // Position below the text
      left: "50%", // Center horizontally
      transform: "translateX(-50%)", // Center alignment
      width: "80%", // Width of the indicator
      height: "2px", // Height of the indicator
      backgroundColor: "#0085FF",
      borderRadius: "2px",
      boxShadow: "0 0 5px #fff, 0 0 10px #fffa, 0 0 15px #fff", // Neon glow effect for the indicator
    },
  },
});


const StyledButton = styled(Button)({
  // marginLeft: '10px',
  borderRadius: "30px",
  padding: "8px 35px",
  fontWeight: 500,
  transition: "all 0.3s ease",
  textTransform: "none", // Prevents uppercase transform
});

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& .MuiIconButton-root": {
    color: "#1a1a1a",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});
const StyledAppBar = styled(AppBar)(({ theme, isVisible }) => ({
  backgroundColor: "#0085FF1A",
  // background: "linear-gradient(to right,#0085FF1A  #0085FF,)",

  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)",
  boxShadow: "none",
  borderRadius: "16px",
  width: "95%",
  position: "fixed",
  left: "50%",
  transform: "translateX(-50%)",
  top: isVisible ? theme.spacing(2) : "-100px", // Hide the navbar when isVisible is false
  transition: "top 0.3s ease", // Smooth transition for visibility changes
  "& .MuiToolbar-root": {
    minHeight: "70px",
    justifyContent: "center",
  },
}));

const NavbarContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "1200px",
});

const authRoutes = [
  {
    path: "/home",
    pro: false,
    icon: "home-icon-class",
    name: "Home",
    layout: "",
  },
  {
    path: "/saved_posts",
    pro: false,
    icon: "blog-icon-class",
    name: "Saved posts",
    layout: "",
  },
  {
    path: "/post_applied",
    pro: false,
    icon: "about-icon-class",
    name: "Posts applied",
    layout: "",
  },
  {
    path: "https://pulse.digital/contact/",
    pro: false,
    icon: "about-icon-class",
    name: "Contact us",
    layout: "",
  },
];

const noAuthRoutes = [
  {
    path: "/home",
    pro: false,
    icon: "home-icon-class",
    name: "Home",
    layout: "",
  },
  {
    path: "/a-propos",
    pro: false,
    icon: "about-icon-class",
    name: "About us",
    layout: "",
  },
  {
    path: "https://pulse.digital/contact/",
    pro: false,
    icon: "about-icon-class",
    name: "Contact us",
    layout: "",
  },
];

const ButtonAppBar = ({
  isAuth,
  onLoginClick,
  onSignInClick,
  addPost,
  setChatComponent,
  hideWhileScrolling = 500,
  isShrinked
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const {
    signalingServer,
    addMessage,
    addNotification,
    notifications,
    notificationMessages,
    incrementNotification,
    clearNotifications,
    fetchUnreadNotifications,
    markNotificationsAsRead,
    markNotificationAsRead,
    unreadNotifications,
    notificationCounts,
    getTotalNotifications,
    messagenotification,
    clearMessageNotifications,
    removeMessageNotification,
    updateNotificationCount,
    setInitialNotificationCounts,
    addMessageNotification,
  } = useSignalingStore();
  const [totalNotifications, setTotalNotifications] = useState(0);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [isVisible, setIsVisible] = useState(true); // State to track navbar visibility

  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If isShrinked is true, hide the navbar immediately
      if (isShrinked && currentScrollY > 800) {
        setIsVisible(false);
        return;
      }

      if (currentScrollY > hideWhileScrolling) {
        // Hide the navbar when scrolling down
        // if (currentScrollY > lastScrollY) {
          setIsVisible(false);
        // } else {
        //   // Show the navbar when scrolling up
        //   setIsVisible(true);
        // }
      } else {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, hideWhileScrolling, isShrinked]);

  useEffect(() => {
    fetchUnreadMessageCounts();
    const cleanupSocket = connectToSocket();

    return () => {
      if (cleanupSocket) cleanupSocket();
    };
  }, []);

  useEffect(() => {
    setTotalNotifications(getTotalNotifications());
  }, [notificationCounts, getTotalNotifications]);

  const fetchUnreadMessageCounts = async () => {
    try {
      const response = await fetch(
        `${apiUrl}messaging/api/unread-message-counts/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread message counts");
      }
      const data = await response.json();
      setInitialNotificationCounts(data);
    } catch (error) {
      console.error("Error fetching unread message counts:", error);
    }
  };

  const connectToSocket = () => {
    const senderId = getUserIdFromAccessToken();
    if (senderId) {
      const signalingServerUrl = `${apiUrlsoket}ws/chat/${senderId}/0/`;
      const socket = new WebSocket(signalingServerUrl);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "chat_message") {
          updateNotificationCount(data.sender_id);
          addMessageNotification({
            id: Date.now(),
            message: data.message,
          });
        }
      };

      return () => {
        socket.close();
      };
    }
  };
  const [isAuth2, setIsAuth2] = useState(isAuth);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const menuId = "primary-search-account-menu";

  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());

  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(profileAnchorEl);
  const isNotificationPopoverOpen = Boolean(notificationAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const navToProfile = () => {
    setProfileAnchorEl(null);
    handleMobileMenuClose();
    navigate("/profile");
  };
  const logOut = () => {
    setProfileAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    if (window.location.pathname === "/home") {
      window.location.reload();
    } else {
      navigate("/home");
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [notifMessage, setNotifMessage] = useState(1);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMenu = (
    <Box
      sx={{
        width: "150px",
      }}
    >
      <ModernProfileMenu
        profileAnchorEl={profileAnchorEl}
        isMenuOpen={isMenuOpen}
        setProfileAnchorEl={setProfileAnchorEl}
        navToProfile={navToProfile}
        logOut={logOut}
      />
    </Box>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={totalNotifications} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge
            badgeContent={notifMessage}
            color="error"
            onClick={() => {
              setNotifMessage(0);
            }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    setIsAuth2(isAuth);
    console.log("auth", isAuth2);
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      fetchUnreadNotifications();
    }
  }, [isAuth, fetchUnreadNotifications]);

  useEffect(() => {
    console.log("Notifications:", notifications);
  }, [notifications]);

  useEffect(() => {
    if (signalingServer) {
      signalingServer.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Received message:", message);

        addMessage(message);

        if (message.type === "notif") {
          addNotification({
            content: message.notif,
            timestamp: new Date().toISOString(),
            link: message.link,
          });
          console.log("Received notification: final", message);
        }
      };
    }
  }, [signalingServer, addMessage, addNotification]);

  const handleNotificationClick = (event, link) => {
    setNotificationAnchorEl(event.currentTarget);

    handleNotificationClose();
    if (link) {
      navigate(link);
    }
  };
  const popoverId = isNotificationPopoverOpen
    ? "notification-popover"
    : undefined;
  const userId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
    markNotificationsAsRead();
  };
  const handleNotificationButtonClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    fetchUnreadNotifications();
  };

  const handleNotificationItemClick = (event, notification) => {
    markNotificationAsRead(notification.id);
    handleNotificationClose(); // Close the popover after clicking
    if (notification.link) {
      navigate(notification.link);
    }
  };

  return (
    <StyledAppBar isVisible={isVisible} elevation={0}>
      <Toolbar>
        <NavbarContent>
           <Box
              // sx={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center", // Add this
              // }}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{ width: "50px", marginRight: "32px" }}
              />
              </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Add this
              flex: 1, // Add this to take up available space
            }}
          >
           
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Add this
                }}
              >
                {(isAuth ? authRoutes : noAuthRoutes).map((route, index) => (
                  <NavLink
                    sx={{ fontFamily: "Poppins", color:'white' }}
                    key={index}
                    to={route.path}
                  >
                    {route.name}
                  </NavLink>
                ))}
              </Box>
            
          </Box>

          {isAuth ? (
            <IconWrapper>
              <IconButton size="large" onClick={() => setChatComponent(true)}>
                <Badge badgeContent={totalNotifications} color="transparent">
                  {/* <MailIcon /> */}
                  <TbMessageCircle style={{ color: 'white', fontSize: '24px' }}  />

                </Badge>
              </IconButton>

              <IconButton size="large" onClick={handleNotificationButtonClick}>
                <Badge
                  badgeContent={
                    Array.isArray(unreadNotifications)
                      ? unreadNotifications.length
                      : 0
                  }
                  color="primary"
                >
                  {/* <NotificationsIcon /> */}
                  <IoMdNotificationsOutline style={{ color: 'white', fontSize: '24px' }} />
                </Badge>
              </IconButton>

              <IconButton onClick={handleProfileMenuOpen}>
                {/* <AccountCircle /> */}
                <RxHamburgerMenu style={{ color: 'white', fontSize: '24px' }} />
              </IconButton>

              {userRole === "r" && (
                <StyledButton
                  variant="contained"
                  onClick={() =>
                    navigate("/talent/dashboard", {
                      state: { recruiter: true },
                    })
                  }
                  sx={{
                    // borderColor: '#fff',
                    backgroundColor: "#2563eb",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1d4ed8",
                    },
                  }}
                >
                  Recruiter Space
                </StyledButton>
              )}
            </IconWrapper>
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              <StyledButton
                variant="outlined"
                onClick={() => navigate("/login", { state: { authMode: "signup" } })}
                sx={{
                  borderColor: "#fff",
                  color: "#2563eb",
                  "&:hover": {
                    borderColor: "#1d4ed8",
                    backgroundColor: "rgba(37, 99, 235, 0.04)",
                  },
                }}
              >
                Sign UP
              </StyledButton>
              <StyledButton
                variant="contained"
                onClick={() => navigate("/login")}
                sx={{
                  borderColor: "#fff",
                  backgroundColor: "#2563eb",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1d4ed8",
                  },
                }}
              >
                Login
              </StyledButton>
            </Box>
          )}
        </NavbarContent>
      </Toolbar>

      {renderMobileMenu}
      {renderMenu}
    </StyledAppBar>
  );
};

export default ButtonAppBar;
