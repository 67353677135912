import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Stack,
  Container,
  useTheme,
  alpha,
  keyframes,
  styled,
} from "@mui/material";
import {
  BookmarkBorderRounded as BookmarkIcon,
  TravelExploreRounded as ExploreIcon,
  ArrowForwardRounded as ArrowForwardIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { Navigate, useNavigate } from "react-router-dom";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: "100vh", // Make the paper take full screen height
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%", // Ensure it takes full width
  background: `linear-gradient(145deg, 
      ${alpha(theme.palette.primary.light, 0.05)} 0%,
      ${alpha(theme.palette.background.paper, 1)} 100%)`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  position: "relative",
  overflow: "hidden",
}));
const IconWrapper = styled(Box)(({ theme }) => ({
  width: 96,
  height: 96,
  borderRadius: "28px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(135deg,
    ${theme.palette.primary.light} 0%,
    ${theme.palette.primary.main} 100%)`,
  boxShadow: `0 12px 24px -8px ${alpha(theme.palette.primary.main, 0.25)}`,
  marginBottom: theme.spacing(3),
  transform: "rotate(-10deg)",
  "& svg": {
    fontSize: 44,
    color: "white",
    transform: "rotate(10deg)",
  },
}));

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "16px",
  textTransform: "none",
  padding: "12px 32px",
  fontSize: "1.1rem",
  fontWeight: 600,
  boxShadow: `0 8px 16px -4px ${alpha(theme.palette.primary.main, 0.2)}`,
  background: `linear-gradient(90deg,
    ${theme.palette.primary.main} 0%,
    ${alpha(theme.palette.primary.dark, 0.9)} 100%)`,
  "&:hover": {
    background: `linear-gradient(90deg,
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.dark} 100%)`,
    boxShadow: `0 12px 20px -4px ${alpha(theme.palette.primary.main, 0.3)}`,
  },
}));

const BackgroundGlow = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  opacity: 0.06,
  pointerEvents: "none",
  background: `radial-gradient(circle at 50% 50%,
    ${theme.palette.primary.main} 0%,
    transparent 70%)`,
  animation: `${float} 6s ease-in-out infinite`,
}));

const NoSavedPosts = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledPaper elevation={0}>
      <BackgroundGlow />
      <Container maxWidth="lg">
        <Stack spacing={4} alignItems="center" textAlign="center">
          <IconWrapper component={motion.div} whileHover={{ scale: 1.05 }}>
            <BookmarkIcon />
          </IconWrapper>

          <Typography
            variant="h3"
            component="h2"
            fontWeight={700}
            sx={{
              background: `linear-gradient(135deg, 
                ${theme.palette.text.primary} 0%, 
                ${alpha(theme.palette.text.primary, 0.7)} 100%)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              marginBottom: 1,
            }}
          >
            No Saved Posts Yet
          </Typography>

          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              textTransform: "none",
              maxWidth: 440,
              fontWeight: 500,
              lineHeight: 1.6,
              opacity: 0.8,
            }}
          >
            Start building your collection of job opportunities. Save posts that
            interest you and come back to them anytime.
          </Typography>

          <StyledButton
            variant="contained"
            size="large"
            component={motion.button}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            startIcon={<ExploreIcon />}
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/')}
          >
            Explore Jobs
          </StyledButton>
        </Stack>
      </Container>
    </StyledPaper>
  );
};

export default NoSavedPosts;
