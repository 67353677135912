import React from 'react';
import { keyframes } from '@emotion/react';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const CardSkeleton = () => (
  <div className="w-full max-w-sm bg-white rounded-lg shadow-md overflow-hidden">
    <div className="h-48 bg-gray-200 animate-pulse" />
    <div className="p-5">
      <div className="h-6 bg-gray-200 rounded w-3/4 mb-4 animate-pulse" />
      <div className="h-4 bg-gray-200 rounded w-full mb-2 animate-pulse" />
      <div className="h-4 bg-gray-200 rounded w-5/6 mb-4 animate-pulse" />
      <div className="flex items-center justify-between">
        <div className="h-8 bg-gray-200 rounded w-1/3 animate-pulse" />
        <div className="flex space-x-2">
          <div className="w-6 h-6 bg-gray-200 rounded-full animate-pulse" />
          <div className="w-6 h-6 bg-gray-200 rounded-full animate-pulse" />
        </div>
      </div>
    </div>
  </div>
);

const SkeletonLoader = ({ count = 6 }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
    {[...Array(count)].map((_, index) => (
      <CardSkeleton key={index} />
    ))}
  </div>
);

export default function SkeletonLoaderDemo() {
  return <SkeletonLoader />;
}